import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import { withScroller } from "../../../hoc/withScroller";
import { FC } from "react";
import { Vehicle } from "../../../types/Vehicle";
import { TimeToLeave } from "@mui/icons-material";
import { getFormatteShortdDate } from "../../../util/helper";
import { LabelHelper } from "../../../util/labelHelper";

export interface Props {
  hasMore: boolean;
  loading: boolean;
  items: Vehicle[];
  onSelect: (vid: number) => void;
  onLoadMore: (nextLimit: number) => void;
}

const VehicleTimelineViewComp: FC<Props> = ({ items, onSelect }) => {
  const getColor = (a: any) => {
    if (!a.recordcnt) {
      return "grey";
    }
    if (a.recordcnt < 2) {
      return "error";
    }
    if (a.recordcnt < 5) {
      return "warning";
    }
    if (a.recordcnt < 10) {
      return "primary";
    }
    return "success";
  };
  return (
    <Timeline>
      {items.map((v: any) => (
        <TimelineItem
          key={v.id}
          sx={{ cursor: "pointer" }}
          onClick={() => onSelect(v.id)}
        >
          <TimelineOppositeContent
            sx={{ py: "12px", px: 2 }}
            align="right"
            variant="body2"
          >
            <Typography variant="h6" component="span">
              <b>{LabelHelper.displayRegNo(v.regNo)}</b>
            </Typography>
            <Typography variant="subtitle2">Year# {v.year}</Typography>
            <Typography>
              {v.recordcnt ? (
                <span>
                  Found <b style={{ color: "blue" }}>{v.recordcnt} </b>
                  {"records"}
                </span>
              ) : (
                "No records"
              )}
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot color={getColor(v)}>
              <TimeToLeave />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>

          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <Typography variant="h6" component="span">
              {v.odo}
              <span style={{ fontSize: "12px" }}> Km|Mi</span>
            </Typography>
            <Typography variant="subtitle2"> {v.ownername}</Typography>
            <Typography variant="subtitle2">
              {getFormatteShortdDate(v.updatedAt)}
            </Typography>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export const VehicleTimelineView = withScroller(VehicleTimelineViewComp);
