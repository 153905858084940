import { Grid, Divider, Typography } from "@mui/material"
import { FormWrapper } from "../../shared/FormWrapper"
import { InputGrid } from "../../shared/InputGrid"
import { GoogleLogin } from "./google/googleLogin"
import { useSocialSignInMutation } from "../../../state/api/user.api"
import { setLocalStorage } from "../../../util/localData"
import { SocialUser } from "../../../types/SocialUser"
import { FaceBookLogin } from "./facebook/facebookLogin"
import { LocalStorageKeys } from "../../../enum/localStorageKeys.enum"

export const SocialLoginContainer = () => {
  const [socialLogin, { isLoading }] = useSocialSignInMutation();

  const checklogin = async (token: string, user: SocialUser) => {
    const result: any = await socialLogin({ token, user });
    if (result.data) {
      setLocalStorage(LocalStorageKeys.country, result.data.country);
      setLocalStorage(LocalStorageKeys.hasAccount, true);
    } else {
      console.log('Social user not found');
    }
  }

  return <FormWrapper>
    <Grid container >
      <InputGrid minWidth={12}>
        <Grid gap={1} container item xs={12}>
          <Grid item xs={12}>
            <FaceBookLogin onLogin={checklogin} />
          </Grid>
          <Grid item xs={12}>
            <GoogleLogin onLogin={checklogin} />
          </Grid>
        </Grid>
      </InputGrid>
    </Grid>
    <br />
    <Divider >
      <Typography variant='caption' margin={1}>Or Login With Your Own Account</Typography>
    </Divider>
  </FormWrapper>
}