import { useNavigate, useParams } from "react-router-dom";
import { useBreadcrumb } from "../../../hooks/useBreadcrumb";
import { useUser } from "../../../hooks/useUser";
import { useVehicleList } from "../../../hooks/useVehicleList";
import { useReadRecordQuery, useUpdateRecordMutation } from "../../../state/api/record.api";
import { useGetVehicleByIdQuery } from "../../../state/api/vehicle.api";
import { ServiceRecord } from "../../../types/ServiceRecord";
import { ErrorComponent } from "../../error/error";
import { LoaderBackdrop } from "../../loader/loaderBackdrop.component";
import { Unauthorized } from "../../unauthorized/unauthorized.component";
import { RecordCreate } from "../create/recordCreate";
import { ListingType } from "../../../enum/vehicleListing.enum";
import { LoaderLinear } from "../../loader/loaderLinear.component";

export const RecordUpdateContainer = () => {
  const { vid, rid } = useParams();
  const { user } = useUser();

  useBreadcrumb([{
    label: 'Home',
    path: `/`
  },
  {
    label: 'Vehicle',
    path: `/vehicle/${vid}`
  },
  {
    label: 'Record',
    path: `/vehicle/${vid}/record/${rid}`
  },
  ]);

  const navigate = useNavigate();
  const { data: vehicle } = useGetVehicleByIdQuery({ id: Number(vid) });

  const { vehicleList, isLoading: loadingVehicleList } = useVehicleList(user?.id, ListingType.ListingForMyOwn);
  const { data: record, isLoading: loadingRecord } = useReadRecordQuery({ id: rid ?? "-1"});
  const [updateVehicle, { isLoading: loadingMutation }] = useUpdateRecordMutation();

  const handleUpdateRecord = async (r: ServiceRecord, imgs: Record<number, Blob | null>) => {
    const result: any = await updateVehicle({ rec: r, imgs });
    if (!result.error && (vid != null)) {
      navigate(`/vehicle/${vid}`);
    } else {
      console.error('unable to navigate');
    }
  };

  if (user == null) {
    return <ErrorComponent  title="Error"  text="User N/A" />;
  }

  if (loadingVehicleList || loadingRecord) {
    return <LoaderBackdrop />
  }

  if (!record) {
    return <div>Record Not Found</div>
  }

  if (!user?.id) {
    return <div>User Not Found</div>
  }

  if (record.UserId !== user.id) {
    return <Unauthorized />
  }

  if(!vehicle){
    return <LoaderLinear />
  }

  return (
    <RecordCreate
      vehicle={vehicle}
      vehicleListOfUser={vehicleList}
      initialState={record}
      onSave={handleUpdateRecord}
      loading={loadingMutation}
      user={user}
    />
  );
};
