import { FC } from "react";
import { Validators } from "../../../util/validators";
import { CustomButton } from "../../shared/CustomButton";
import { AutoInput, TextInput } from "../../shared";
import { useFormState } from "../../../hooks/useFormState";
import { PersonAdd } from '@mui/icons-material';
import { User } from "../../../types/User";
import { Grid } from "@mui/material";
import { FormWrapper } from "../../shared/FormWrapper";
import { InputGrid } from "../../shared/InputGrid";
import { PhoneInput } from "../../shared/PhoneInput";
import { countryListWithCodes } from "../../../util/countryHelper";
import { defaultUser } from "../../../defaultValues/defaultUser";

interface Props {
  title: string;
  loading: boolean;
  onSave: (user: Omit<User, "id">) => void;
}
export const SignUp: FC<Props> = ({ loading, onSave }) => {

  const {state, changeProperty} = useFormState<Omit<User, "id"> & { confirmPassword?: string }>({...defaultUser, confirmPassword: ''});

  const handleSignUp = () => {
    const obj = { ...state };
    delete obj.confirmPassword;
    obj.phone = obj.identifier;
    onSave(obj);
  };

  return (
    <FormWrapper>
      <Grid container >
        <InputGrid minWidth={12}>
          <TextInput
            value={state.name}
            name="name"
            label="Enter Your Name"
            error={!Validators.userName(state.name)}
            onChange={changeProperty}
            disabled={loading}
          />
        </InputGrid>

        <InputGrid minWidth={12}>
          <AutoInput
            value={state.country}
            name="country"
            error={!Validators.country(state.country)}
            label="Select Country"
            options={countryListWithCodes()}
            disabled={loading}
            onChange={changeProperty}
          />
        </InputGrid>

        <InputGrid minWidth={12}>
          <PhoneInput
            country={state.country}
            value={state.identifier}
            name="identifier"
            label="Phone Number"
            error={!Validators.validatePhone(state.identifier, state.country)}
            onChange={changeProperty}
            disabled={loading}
          />
        </InputGrid>

        <InputGrid minWidth={12}>
          <TextInput
            value={state.password}
            error={!Validators.text(state.password)}
            name="password"
            label="Enter Password"
            type="password"
            onChange={changeProperty}
            disabled={loading}
          />
        </InputGrid>

        <InputGrid minWidth={12}>
          <TextInput
            value={state.confirmPassword ?? ''}
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            error={state.password !== state.confirmPassword}
            onChange={changeProperty}
            disabled={loading}
            onEnter={handleSignUp}
          />
        </InputGrid>

        <InputGrid minWidth={12}>
          <CustomButton
            EndIcon={PersonAdd}
            label={"Create User"}
            loading={loading}
            onClick={handleSignUp}
            disabled={loading || !Validators.validUser(state, state.confirmPassword)}
          />
        </InputGrid>
      </Grid>
    </FormWrapper>
  );
};
