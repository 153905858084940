import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TextField } from "@mui/material";
import { FC } from "react";
import { config } from '../../config';
import { MobileDatePicker } from '@mui/x-date-pickers';
interface Props {
  name: string;
  value: Date;
  error: boolean;
  label: string;
  disabled: boolean;
  onChange: (key: string, value: number | string) => void;
  onEnter?: () => void;
}
export const DateInput: FC<Props> = ({
  name,
  value,
  error,
  label,
  onChange,
  disabled,
  onEnter
}) => {

  const handleKeyDown = (e: any) => {
    if(onEnter) {
      if(e.key === 'Enter') {
        onEnter();
      }
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MobileDatePicker
        label={label}
        value={value}
        disabled={disabled}
        onChange={(e: any) => {
          onChange(name, e)
        }}
        renderInput={(params: any) => <TextField {...params} error={error} variant="standard"
          // sx={{ marginTop: 3, height: config.inputHeight }} 
          fullWidth onKeyDown={handleKeyDown} />}
      />
    </LocalizationProvider>
  );
};
