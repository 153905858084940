import { FC, useState } from "react";
import { useUser } from "../../hooks/useUser";
import { ChartDialog } from "./chartDialog";
import { useSearchRecordsQuery } from "../../state/api/record.api";
import { config } from "../../config";
import { ServiceType } from "../../selectOptions/serviceTypes";
import { Vehicle } from "../../types/Vehicle";

interface Props {
    vehicle: Vehicle;
    open: boolean;
    onClose: () => void
}

export const ChartContainer: FC<Props> = ({ open, vehicle, onClose }) => {
    const [ recordTypes, setRecordTypes] = useState<ServiceType[]>([]);

    const { user } = useUser();
    const isOwner = vehicle?.UserId === user?.id;

    const { data, isLoading } = useSearchRecordsQuery({ limit: config.chartFuelRecordsLimit, offset: 0, filter: {VehicleId: vehicle.id, categories: recordTypes}, isOwner });

    return <>
        <ChartDialog isLoading={isLoading} open={open} onRecordTypesChange={setRecordTypes} onClose={() => onClose()} data={data} />
    </>
}