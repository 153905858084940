import { SelectOption } from "../types/SelectOption";
import { useReadAreasQuery } from "../state/api/area.api";
import { Area } from "../types/Area";
import { countryList } from "../util/countries";
import { WithArea } from "../types/WithArea";

export const useAreas = () => {
  const { data } = useReadAreasQuery({});
  const areas = data as Area[] ?? [];

  const allAreas: SelectOption[] = areas?.map(loc => ({
    id: loc.id,
    label: loc.label,
    meta: loc
  }));

  const getCountry = (entity: WithArea) => {
    return countryList.find(c => c.id === entity.country)
  }

  const getProvince = (entity: WithArea) => {
    return getCountry(entity)?.provinceList?.find(p => p.id === entity.province);
  }

  const getDistrict = (entity: WithArea) => {
    return getProvince(entity)?.districts?.find(d => d.id === entity.district);
  }

  const getAreas = (entity: WithArea) => {
    return allAreas.filter(area => area.meta.country === entity.country && area.meta.province === entity.province && area.meta.district === entity.district);
  }

  return { allAreas, getCountry, getProvince, getDistrict, getAreas };
};

