import { Storefront } from "@mui/icons-material";
import { Button, Grid, Link, Typography } from "@mui/material";
import { FC } from "react";

export const PageHeader: FC<{
    header: string,
    subHeader: string,
    small?: boolean,
    rightButton?: {
        label: string;
        link: string;
    }
}> = ({ header, subHeader, small, rightButton }) => {
    return <>
        <Grid container justifyContent="space-between">
            <Typography variant="h2" fontSize={small ? 20 : 40}>{header}</Typography>
            {rightButton && <Link href={rightButton.link}><Button variant="text" startIcon={<Storefront />}>
                {rightButton.label}
            </Button>
            </Link>}
        </Grid>
        <Typography variant="overline">{subHeader}</Typography>
    </>
}