import { FC } from "react"
import { Part } from "../../../types/Part"
import { PartGridCard } from "../view/partGridCard"
import { Grid } from "@mui/material"
import { withScroller } from "../../../hoc/withScroller";
import { GridItemAdd } from "../../shared/addNew/gridItemAdd";
import { PostAdd } from "@mui/icons-material";
import { ItemViewMode } from "../../../enum/itemViewMode.enum";
import { PartGridTable } from "../view/partGridTable";

interface Props {
    viewMode: ItemViewMode;
    onSelect: (part: Part) => void; 
    onAdd?: () => void;
    // for pagination
    hasMore: boolean;
    loading: boolean;
    items: Part[];
    onLoadMore: (nextLimit: number) => void;
}

export const PartsListComp: FC<Props> = ({ items, viewMode, onSelect, onAdd }) => {

    return  <Grid container spacing={1}>
         {onAdd && <GridItemAdd Icon={PostAdd} text="Sell Spare Parts" subText="Spare parts for sale" onAdd={onAdd} />}
        {
            items.map(p => viewMode===ItemViewMode.grid ? <PartGridCard key={p.id} part={p} onSelect={onSelect} /> : <PartGridTable key={p.id} part={p} onSelect={onSelect} />)
        }
    </Grid>
}

export const PartsList = withScroller(PartsListComp);