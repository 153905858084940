import { useParams } from "react-router-dom";
import { RecordCreateContainer } from "./recordCreate.container";

export const RecordCreateRoute = () => {
  // const [searchParams] = useSearchParams();
  const vid = useParams().vid ?? '-1' ;
  // const vid = searchParams.get("vid") ?? "-1";

  return <RecordCreateContainer key={vid} vid={vid} />;
};
