import { useNavigate } from "react-router-dom";
import { useUser } from "../../hooks/useUser";
import { useLazyGetVehiclesWithIdsQuery } from "../../state/api/vehicle.api";
import { Vehicle } from "../../types/Vehicle";
import { PageHeader } from "../shared/PageHeader";
import { useEffect, useState } from "react";
import { Tab, Tabs } from "@mui/material";
import { Part } from "../../types/Part";
import { FavouriteVehicles } from "./favouriteVehicles";
import { FavouriteParts } from "./favouriteParts";
import { getLocalStorage, setLocalStorage } from "../../util/localData";
import { useLazyGetPartsWithIdsQuery } from "../../state/api/parts.api";
import { LocalStorageKeys } from "../../enum/localStorageKeys.enum";
import { useModels } from "../../hooks/useModels";

export const FavouritesContainer = () => {
    const { user } = useUser();
    const models = useModels();
    const navigate = useNavigate();
    const [fetchFavoriteVehicles, vData] = useLazyGetVehiclesWithIdsQuery();
    const [fetchFavoriteParts, pData] = useLazyGetPartsWithIdsQuery();

    const [selectedTab, setSelectedTab] = useState<'vehicles' | 'parts'>(getLocalStorage(LocalStorageKeys.favoritesSelectedTab, 'vehicles'));

    useEffect(() => {
        handleTabChange(undefined, selectedTab);
    }, [])

    const handleTabChange = async (event: any, newValue: 'vehicles' | 'parts') => {
        setLocalStorage(LocalStorageKeys.favoritesSelectedTab, newValue);
        switch(newValue){
            case 'vehicles':
                if(user?.favouriteVehicles && user.favouriteVehicles.length > 0 && !vData.isSuccess) {
                    fetchFavoriteVehicles({ ids: user.favouriteVehicles });
                }
            break;

            case 'parts':
                if(user?.favouriteParts && user.favouriteParts.length > 0 && !pData.isSuccess) {
                    fetchFavoriteParts({ ids: user.favouriteParts });
                }
            break;
        }

        setSelectedTab(newValue);
    }

    const handleSelectVehicle = (v: Vehicle) => {
        navigate(`/vehicle/${v.id}`);
    };

    const handleSelectPart = (p: Part) => {
        navigate(`/sparepart/${p.id}`);
    };

    return <>
        <PageHeader header="Favorite Items" subHeader="Bookmarked Spareparts and Vehicles" />
        <Tabs value={selectedTab} onChange={handleTabChange} centered>
            <Tab label="Favorite Vehicles" value='vehicles'/>
            <Tab label="Favorite Parts" value='parts'/>
        </Tabs>
        {selectedTab === 'vehicles' && vData.isSuccess && <FavouriteVehicles models={models} onSelect={handleSelectVehicle} items={vData.data} /> }
        {selectedTab === 'parts' && pData.isSuccess && <FavouriteParts onSelect={handleSelectPart} items={pData.data} />}
    </>
}