import { Search } from "@mui/icons-material";
import { Grid, TextField } from "@mui/material";
import { FC, useState } from "react";
import { removeSpecialCharactorsAndCapitalize } from "../../../util/helper";
import { CustomButton } from "../../shared/CustomButton";

interface Props {
    searchKey: string;
    onSearch: (key: string) => void;
}
export const SearchInput: FC<Props> = ({ searchKey, onSearch }) => {
    const [keyword, setKeyword] = useState(searchKey);

    const beforeSearch = () => {
        onSearch(removeSpecialCharactorsAndCapitalize(keyword));
    }

    return <Grid container justifyContent="center" alignItems="center">
        <TextField
          size="small"
            id="outlined-controlled"
            label="Reg. Number / Chassis Number"
            value={keyword}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setKeyword(event.target.value);
            }}
            onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                    // Do code here
                    ev.preventDefault();
                    beforeSearch();
                }
            }}
        />

        <CustomButton onClick={beforeSearch} label="Search" EndIcon={Search} fitToContent />
    </Grid>

};