import { FC } from "react";
import { useCreateUserMutation } from "../../../state/api/user.api";
import { User } from "../../../types/User";
import { setLocalStorage } from "../../../util/localData";
import { SignUp } from "./signUp";
import { LocalStorageKeys } from "../../../enum/localStorageKeys.enum";
import { PopupManager } from "../../../util/popupManager";

export const SignUpContainer: FC = () => {
  const [createUser, { isLoading }] = useCreateUserMutation();

  const handleSignUp = async (user: Omit<User, 'id'>) => {
    const result: any = await createUser({ user });
    if (result.data) {
      setLocalStorage(LocalStorageKeys.hasAccount, true);
      setLocalStorage(LocalStorageKeys.country, user.country);
      PopupManager.showSuccessToast('User created successfully');
    } else {
      console.log('user not created');
    }
  };

  return <SignUp title="Create Account" loading={isLoading} onSave={handleSignUp} />;
};
