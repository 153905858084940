import { OwnerTypes } from "../enum/ownerTypes.enum";
import { ListingType } from "../enum/vehicleListing.enum";
import { useSearchVehiclesQuery } from "../state/api/vehicle.api";
import { SelectOption } from "../types/SelectOption";
import { Vehicle } from "../types/Vehicle";
import { LabelHelper } from "../util/labelHelper";

export const useVehicleList = (userId: number | undefined, listing: ListingType) => {
  const { data, isLoading } = useSearchVehiclesQuery({keywords: '', ownerType: OwnerTypes.My, filter: {UserId: userId}, limit: undefined, offset: undefined});
  const vehicleList: SelectOption[] = data ? data.filter((l: Vehicle) => l.listing===listing).map((v: Vehicle) => {
    // check is this unique value
    let itemsWithSameRegNo = 0;
    data.forEach((v2: Vehicle) => {
      if (v.regNo === v2.regNo) {
        itemsWithSameRegNo++;
      }
    });

    return {
      id: v.id,
      label: itemsWithSameRegNo === 1 ? `${LabelHelper.displayRegNo(v.regNo)}` : `${LabelHelper.displayRegNo(v.regNo)}(${v.id ?? 'NoId'})`,
    };
  }): [];
  return {vehicleList, isLoading};
};
