import { Divider, ListItem, ListItemAvatar, ListItemText } from "@mui/material"
import { FC, ReactNode } from "react";

interface Props {
    name: string;
    value: string | number | undefined;
    icon: ReactNode;
}
export const Detail: FC<Props> = ({ name, value, icon }) => {
    return (
        <>
            <ListItem>
                <ListItemAvatar>
                    {icon}
                </ListItemAvatar>
                <ListItemText primary={value === '' ? '- - - - - -' : value} secondary={name} />
            </ListItem>
            <Divider />
        </>);
}