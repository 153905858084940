import * as React from 'react';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { ManageSearch, CarRepair, Sell } from '@mui/icons-material';
import { StepIconProps } from '@mui/material/StepIcon';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { Grid } from '@mui/material';
// import './demo.css';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const Animated1 = styled('div')(() => ({
  // background: 'red',
  animationName: 'animation1',
  animationDuration: '1.5s',
  // animationDelay: '-0s',
  animationIterationCount: 1,
  animationFillMode: 'forwards'
}));
const Animated2 = styled('div')(() => ({
  // background: 'red',
  animationName: 'animation2',
  animationDuration: '1.5s',
  // animationDelay: '-1s',
  animationIterationCount: 1,
  animationFillMode: 'forwards'
}));
const Animated3 = styled('div')(() => ({
  // background: 'red',
  animationName: 'animation3',
  animationDuration: '1.5s',
  // animationDelay: '-2s',
  animationIterationCount: 1,
  animationFillMode: 'forwards'
}));

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: Record<string, React.ReactElement> = {
    1: <Animated1><CarRepair /></Animated1>,
    2: <Animated2><Sell /></Animated2>,
    3: <Animated3><ManageSearch /></Animated3>
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}


export const Demo = () => {
  return (
    <Grid container sx={{ width: '100%', margin: 'auto' }} justifyContent="center" spacing={4}>
      <Stepper alternativeLabel activeStep={4} connector={<ColorlibConnector />}>
        <Step><StepLabel StepIconComponent={ColorlibStepIcon}><Animated1>Service Records</Animated1></StepLabel></Step>
        <Step><StepLabel StepIconComponent={ColorlibStepIcon}><Animated2>Buy & Sell</Animated2></StepLabel></Step>
        <Step><StepLabel StepIconComponent={ColorlibStepIcon}><Animated3>Vehicle History</Animated3></StepLabel></Step>
      </Stepper>
    </Grid>
  );
}