import { FC } from "react";
import { EntityEditor } from "./entityEditor"
import { useCreatebrandMutation, useReadbrandsQuery, useUpdatebrandMutation } from "../../../state/api/brand.api";
import { Brand } from "../../../types/Brand";
import { Button } from "@mui/material";
import { useUser } from "../../../hooks/useUser";
import { useFormState } from "../../../hooks/useFormState";
import { InputGrid } from "../../shared/InputGrid";
import { TextInput } from "../../shared";

export const BrandsContainer: FC = () => {
    const { user } = useUser();
    const { data } = useReadbrandsQuery({});
    const brands = data as Brand[];

    const {state, changeProperty} = useFormState({
        brand: '',
        UserId: user?.id
    });

    const [createBrand, { isLoading: isLoadingCreate }] = useCreatebrandMutation();
    const [updateBrand, { isLoading: isLoadingUpdate }] = useUpdatebrandMutation();

    const handleSave = (obj: any) => {
        updateBrand(obj)
    }

    const handleAddNew = () => {
        createBrand(state);
    }

    return <table style={{ width: '100%' }}>
        <tbody>
            {
                brands?.map(m => <tr key={m.id}>
                    <EntityEditor
                        editableProps={['brand']}
                        nonEditableProps={['id']}
                        entity={m}
                        onSave={handleSave}
                    ></EntityEditor>
                </tr>)
            }

            <tr>
                <td>
                    <InputGrid minWidth={6}>
                        <TextInput name="brand" value={state.brand} label="Brand" disabled={false} onChange={changeProperty} />
                    </InputGrid>
                    <Button variant="outlined" onClick={handleAddNew}>Add New</Button>
                </td>
            </tr>
        </tbody>
    </table>;
}