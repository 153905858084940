import { SelectOption } from "../types/SelectOption";
import { useReadbrandsQuery } from "../state/api/brand.api";
import { Brand } from "../types/Brand";

export const useBrands = () => {
  const { data } = useReadbrandsQuery({});
  const brands = data as Brand[];
  const casted: SelectOption[] = brands ? brands.map(t => ({
    id: t.id,
    label: t.brand
  })) : [];

  return casted;
};
