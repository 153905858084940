import { FC } from 'react';
import { useAreas } from '../../hooks/useArea';
import { Grid, Typography } from '@mui/material';
import { Vehicle } from '../../types/Vehicle';
import { Part } from '../../types/Part';
import { LabelHelper } from '../../util/labelHelper';
import { LocationOn } from '@mui/icons-material';
interface Props {
    state: Vehicle | Part;
}
export const LocationView: FC<Props> = ({ state }) => {

    const { allAreas } = useAreas();

    return <Grid container alignItems="center" margin={1}>
        <LocationOn color='info' />
        <Typography variant='caption'>
            {LabelHelper.getProvinceLabel(state.country, state.province)},
            {LabelHelper.getDistrictLabel(state.country, state.province, state.district)},
            {LabelHelper.getAreaLabel(state.area, allAreas, state.extraProps)}</Typography>
    </Grid >
}