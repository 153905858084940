import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import { FC } from "react";

interface Props {
  onAdd: () => void;
}
export const TimelineAdd: FC<Props> = ({ onAdd }) => {
  return (
    <TimelineItem sx={{ cursor: "pointer" }} onClick={() => onAdd()}>
      <TimelineOppositeContent
        sx={{ m: "auto 0" }}
        align="right"
        variant="body2"
        color="text.secondary"
      >
        Today
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineConnector />
        <TimelineDot>
          <AddIcon fontSize="large" />
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent sx={{ py: "12px", px: 2 }}>
        <Typography variant="h6" component="span">
          Add Service Record
        </Typography>
        <Typography>Click here to add a new record</Typography>
      </TimelineContent>
    </TimelineItem>
  );
};
