import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import axios from "axios";
import { Pagination } from "../../types/Pagination";
import { User } from "../../types/User";
import { clearAllCookies, resetCacheStamp, setCacheStamp } from "../../util/cacheManager";
import { encryptProps, handleNetworkResponse } from "../../util/helper";
import { SocialUser } from "../../types/SocialUser";
import { CacheStoreKey } from "../../enum/cacheStore.enum";
import { clearLocalStorage } from "../../util/localData";
import { NetworkResponse } from "../../types/NetworkResponse";
import { generateImagesArrString, syncImages } from "../../services/firebase.service";
import { ImageFolder, SubImageFolder } from "../../enum/imageCategoryEnums";
import { UserImageType } from "../../enum/user.type";

// Define a service using a base URL and expected endpoints
export const userApi = createApi({
  reducerPath: "userApi",
  tagTypes: ["User"],
  baseQuery: fetchBaseQuery({ baseUrl: "" }),
  endpoints: (build) => ({
    createUser: build.mutation({
      queryFn: async (args: { user: Omit<User, "id"> }) => {
        clearAllCookies();
        resetCacheStamp(CacheStoreKey.userDBStamp);
        args.user.identifier = args.user.identifier.toUpperCase();
        const response = await axios.post(`/user/signUp`, args.user);
        return handleNetworkResponse(response, null);
      },
      invalidatesTags: ["User"],
    }),

    signIn: build.mutation({
      queryFn: async (args: { identifier: string; password: string }) => {
        clearAllCookies();
        resetCacheStamp(CacheStoreKey.userDBStamp);
        const newArgs = { ...args };
        newArgs.identifier = newArgs.identifier.toUpperCase();
        const response = await axios.post(`/user/signIn`, newArgs);
        return handleNetworkResponse(response, null);
      },
      invalidatesTags: ["User"],
    }),

    socialSignIn: build.mutation({
      queryFn: async (args: { token: string, user: SocialUser }) => {
        clearAllCookies();
        resetCacheStamp(CacheStoreKey.userDBStamp);
        const response = await axios.post(`/user/socialSignIn`, args);
        return handleNetworkResponse(response, null);
      },
      invalidatesTags: ["User"],
    }),

    // Related to forgot password
    sendReset: build.mutation<NetworkResponse, string>({
      queryFn: async (identifier) => {
        const response = await axios.post(`/user/sendReset`, { identifier });
        resetCacheStamp(CacheStoreKey.userDBStamp);
        return handleNetworkResponse(response, null);
      },
      invalidatesTags: ["User"],
    }),

    // Related to forgot password
    verifyReset: build.mutation<NetworkResponse, { identifier: string; code: string }>({
      queryFn: async (args) => {
        const response = await axios.post(`/user/verifyReset`, args);
        resetCacheStamp(CacheStoreKey.userDBStamp);
        return handleNetworkResponse(response, null);
      },
      invalidatesTags: ["User"],
    }),

    changePassword: build.mutation({
      queryFn: async (args: { identifier: string; password: string }) => {
        // clearAllCookies();
        resetCacheStamp(CacheStoreKey.userDBStamp);
        const response = await axios.post(
          `/user/changePassword`,
          encryptProps(args)
        );
        return handleNetworkResponse(response, null);
      },
      invalidatesTags: ["User"],
    }),

    updateUser: build.mutation({
      queryFn: async (args: {
        user: Partial<User>,
        imgs?: Record<number, Blob | null>
      }) => {
        if (args.imgs && args.user.id) {
          args.user.images = generateImagesArrString(args.user.id, args.user.images, args.imgs, ImageFolder.User, args.user.id, [SubImageFolder.ProfileImages, UserImageType.BusinessCover]);
          await syncImages(args.user.id, ImageFolder.User, args.user.id, args.imgs, [SubImageFolder.ProfileImages, UserImageType.BusinessCover]);
        }
        resetCacheStamp(CacheStoreKey.userDBStamp);
        const response = await axios.post(`/user/update`, args.user);
        return handleNetworkResponse(response, null);
      },
      invalidatesTags: ["User"],
    }),

    logout: build.mutation({
      queryFn: async (arg: {}) => {
        resetCacheStamp(CacheStoreKey.userDBStamp);
        clearAllCookies();
        clearLocalStorage();
        const response = await axios.post(`/user/logout`);
        return handleNetworkResponse(response, null);
      },
      invalidatesTags: ["User"],
    }),

    getLoggedInUser: build.query({
      queryFn: async (args: { cached: boolean }) => {
        const response = await axios.get<User>(setCacheStamp(`/user/loggedInUser?cached=${args.cached}`, CacheStoreKey.userDBStamp));
        const res = handleNetworkResponse(response, null, true);
        return res;
      },
      providesTags: ["User"],
    }),

    getUserInfo: build.query({
      queryFn: async (args: { id: number }) => {
        if (args.id === -1) {
          return {
            data: null
          }
        }
        const response = await axios.get<User>(`/user/userInfo/${args.id}`);
        return handleNetworkResponse(response, null);
      },
      providesTags: ["User"],
    }),

    allUsers: build.query({
      queryFn: async (args: Pagination) => {
        const response = await axios.get(`/user/all?offset=${args.offset}&limit=${args.limit}`);
        return handleNetworkResponse(response, null);
      },
      providesTags: ["User"],
    }),
  }),
});

export const {
  useUpdateUserMutation,
  useSignInMutation,
  useCreateUserMutation,
  useLogoutMutation,
  useAllUsersQuery,
  useGetLoggedInUserQuery,
  useLazyGetLoggedInUserQuery,
  useSendResetMutation,
  useVerifyResetMutation,
  useChangePasswordMutation,
  useSocialSignInMutation,
  useGetUserInfoQuery,
  useLazyGetUserInfoQuery
} = userApi;
