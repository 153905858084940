import { FC } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useBreadcrumb } from "../../../hooks/useBreadcrumb";
import { useUser } from "../../../hooks/useUser";
import { useCreateVehicleMutation } from "../../../state/api/vehicle.api";
import { Vehicle } from "../../../types/Vehicle";
import { ErrorComponent } from "../../error/error";
import { VehicleCreate } from "./vehicleCreate";
import { ListingType } from "../../../enum/vehicleListing.enum";
import { PopupManager } from "../../../util/popupManager";

export const VehicleCreateContainer: FC<{
  vehicleListingFor: ListingType;
  title: string;
  subTitle: string;
}> = ({ vehicleListingFor, title, subTitle }) => {
  const { user } = useUser();
  const [createVehicle, { isLoading: loadingCreate }] = useCreateVehicleMutation();

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const stepNumber = Number(searchParams.get("step"));
  const step = isNaN(stepNumber) ? 0 : stepNumber;

  useBreadcrumb([{
    label: 'Home',
    path: ``
  }]);

  if (!user?.id) {
    return <ErrorComponent title="Error" text="User N/A" />;
  }

  const handleSaveVehicle = async (veh: Vehicle, imgs: Record<number, Blob | null>) => {
    const result: any = await createVehicle({ veh, imgs });
    if (!result.error) { 
      if (vehicleListingFor === ListingType.ListingForMyOwn) {
        navigate(`/vehicle/myOwn`);
      } else {
        navigate(`/vehicle/mySales`);
      }
    } else {
      // TODO: Handle else part
      PopupManager.showErrorToast(result.error.toString());
    }
  };

  if (vehicleListingFor === ListingType.ListingForMyOwn) {
    title += ' your own';
    // subTitle += ' Manage maintanance reacords of your vehicles';
  } else {
    title += ' sale';
    // subTitle += ' Manage maintanance reacords of your vehicles';
  }


  return (
    <VehicleCreate
      initialState={{ listing: vehicleListingFor }}
      activeStepId={step}
      onSave={handleSaveVehicle}
      loading={loadingCreate}
      user={user}
      title={title}
      subTitle={subTitle}
    />
  );
};
