import { RecordVisibility } from "../enum/recordVisibility.enum";
import { serviceTypes } from "../selectOptions/serviceTypes";
import { ServiceRecord } from "../types/ServiceRecord";
import { User } from "../types/User";

export const defaultServiceRecord = (user: User): ServiceRecord => ({
    id: 0,
    date: new Date(),
    categories: [serviceTypes[0].id],
    odo: 0,
    desc: "",
    VehicleId: -1,
    UserId: user.id,
    images: '{}',
    extraProps: '',
    visibility: RecordVisibility.public,
    createdAt: new Date(),
    updatedAt: new Date(),

    // is_a: EntityType.record
})