import { useState } from "react";
import { config } from "../../../config";
import { usePaginatedData } from "../../../hooks/usePaginatedData";
import { useSearchRecordsQuery } from "../../../state/api/record.api";
import { RecordsAdmin } from "./records.admin"
import { useNavigate } from "react-router-dom";

export const RecordsAdminContainer = () => {
    // Pagination starts
    const [query, setQuery] = useState({
        limit: config.pageSize,
        offset: 0,
        filter: {},
        isOwner: false
    });
    const { data, isFetching } = useSearchRecordsQuery(query);
    const { mergedData, handleLoadMore } = usePaginatedData(data, setQuery);
    // pagination ends

    const navigate = useNavigate();

    const handleView = (vid: number, recordId: number, isVehicle: boolean) => {
        if (isVehicle) {
            navigate(`/vehicle/${vid}`);
        } else {
            navigate(`/vehicle/${vid}/record/${recordId}`);
        }
    }

    return (
        <RecordsAdmin
            hasMore={data?.length > 0}
            loading={isFetching}
            items={mergedData}
            onView={handleView}
            onLoadMore={handleLoadMore}
        />
    );
}