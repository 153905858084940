import { useNavigate, useParams } from "react-router-dom";
import { useDeletePartMutation, useReadPartQuery } from "../../../state/api/parts.api";
import { PartView } from "./partView"
import { parseEntityId, hasUpdateAccess } from "../../../util/helper";
import { Part } from "../../../types/Part";
import { useUser } from "../../../hooks/useUser";
import { useUpdateUserMutation } from "../../../state/api/user.api";
import { PartActions } from "./partActions";
import { PartMoreDetailView } from "./partMoreDetailView";
import { FC } from "react";
import { PopupManager } from "../../../util/popupManager";

export const PartViewContainer: FC = () => {

  const { pid } = useParams();
  const { user } = useUser();
  const { data : part, isLoading } = useReadPartQuery({ id: parseEntityId(pid) });
  const [deletePart, { isLoading: loadingDelete }] = useDeletePartMutation();
  const [updateUser, { isLoading: loadingUserUpdate, data: updatedUser }] = useUpdateUserMutation();
  const navigate = useNavigate();

  const favoriteList =  (updatedUser ?? user)?.favouriteParts;
  const isFavourite = part && favoriteList ? favoriteList.includes(part.id) : false;

  const handleFavouriteChange = async (fav: boolean) => {
    if (user && part) {
      const newUser = { ...user };
      if (fav) {
        if (!newUser.favouriteParts) {
          newUser.favouriteParts = [];
        }
        if (!newUser.favouriteParts.includes(part.id)) {
          newUser.favouriteParts = [...newUser.favouriteParts, part.id];
        }
      } else {
        newUser.favouriteParts = newUser.favouriteParts.filter(f => f !== part.id)
      }
      await updateUser({ user: newUser });
    }
  }

  const handleOnEdit = (p: Part) => {
    navigate(`update`);
  };

  const handleOnDelete = async (p: Part) => {
    PopupManager.showConfirmBox('Are You Sure ?', 'This listing will be deleted permanently. Do you want to continue?', 'boolean').then(async () => {
      PopupManager.showSuccessToast('Spare Part is deleting...');
      if (p.id) {
        const result: any = await deletePart({ id: p.id });
        if (result.error) {
          PopupManager.showErrorToast('Spare Part deletion failed');
        } else {
          PopupManager.showSuccessToast('Spare Part deleted');
          navigate(`/`);
        }
      };
    });
  }

  if (!part) {
    return <div>Loading...</div>
  }

  return <>
    <PartView loadingFavorite={loadingUserUpdate} part={part} showFavourite={user !== null} isFavourite={isFavourite} onFavouriteChange={handleFavouriteChange} />
    {
      hasUpdateAccess(part, user) &&
      <PartActions p={part} onEdit={handleOnEdit} onDelete={handleOnDelete} />
    }
    <PartMoreDetailView part={part} />
  </>

}