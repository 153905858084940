import { Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { UsersAdminContainer } from "./users/users.admin.container";
import { BrandAdditionsContainer } from "./brandsAdditions/brandsAdditions.container";
import { ModelAdditionsContainer } from "./modelAdditions/modelAdditions.container";
import { RecordsAdminContainer } from "./records/records.admin.container";
import { VehiclesAdminRoute } from "./vehicles/vehicles.admin.route";
import { BrandsContainer } from "./entityEditor/brands.container";
import { ModelsContainer } from "./entityEditor/models.container";
import { getLocalStorage, setLocalStorage } from "../../util/localData";
import { LocalStorageKeys } from "../../enum/localStorageKeys.enum";
import { AreasContainer } from "./entityEditor/areas.container";
import { AreaAdditionsContainer } from "./areaAdditions/areaAdditions.container";

const RenderTabs = {
    "Users": <UsersAdminContainer />,
    "Vehicles": <VehiclesAdminRoute />,
    "Records": <RecordsAdminContainer />,
    "New Areas": <AreaAdditionsContainer />,
    "New Brands": <BrandAdditionsContainer />,
    "New Models": <ModelAdditionsContainer />,
    "All Areas": <AreasContainer />,
    "All Brands": <BrandsContainer />,
    "All Models": <ModelsContainer />,

};

export const Admin = () => {
    const [selectedTab, setSelectedTab] = useState<keyof typeof RenderTabs>(getLocalStorage(LocalStorageKeys.adminSelectedTab, "Records"));

    const handleChange = (event: React.SyntheticEvent, newValue: keyof typeof RenderTabs) => {
        setLocalStorage(LocalStorageKeys.adminSelectedTab, newValue);
        setSelectedTab(newValue);
    };


    return <><Tabs
        value={selectedTab}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        aria-label="scrollable force tabs example"
    >
        {Object.keys(RenderTabs).map(t => <Tab key={t} label={t} value={t} />)}
    </Tabs>
        {
            RenderTabs[selectedTab]
        }
    </>
}