import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { FC } from "react";
import { ImageView } from "../../shared/ImageView";
import Grid from "@mui/material/Grid";
import { Divider } from "@mui/material";
import { Part } from "../../../types/Part";
import { GridItemShare } from "../../share/gridItemShare";
import { partsTypes } from "../../../selectOptions/partsTypes";
import { getColorCodeForText, getFirstChar } from "../../../util/helper";
import { LabelHelper } from "../../../util/labelHelper";
import { useBrands } from "../../../hooks/useBrands";
import { useModels } from "../../../hooks/useModels";
import { LocationView } from "../../shared/LocationView";
interface Props {
  part: Part;
  onSelect?: (p: Part) => void;
}
export const PartGridCard: FC<Props> = ({ part, onSelect }) => {

  const brands = useBrands();
  const models = useModels();

  const categoryLables = LabelHelper.convertPartCategoryLabels(partsTypes, part.categories, []);
  // const displayCatLabels = categoryLables.length === 0 ? 'All Vehicle Parts' : categoryLables.length > 5 ? `${categoryLables.splice(0, 3).toString()}...` : categoryLables;

  return (
    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ cursor: "pointer" }} onClick={() => { onSelect?.(part) }}>
      <div>
        <Grid>
          <CardHeader
            avatar={
              <Avatar onClick={() => onSelect?.(part)} sx={{ bgcolor: getColorCodeForText(LabelHelper.getBrandLabelEntity(part, brands)) }} aria-label="recipe">
                {`${getFirstChar(LabelHelper.getBrandLabelEntity(part, brands))}`}
              </Avatar>
            }
            title={part.title}
            subheader={`${LabelHelper.getBrandLabelEntity(part, brands)} ${LabelHelper.getModelLabelEntity(part, models)}`}
          />
        </Grid>


        <div>
          <ImageView entity={part} singleImageId={0}/>
          <Grid>
            <Typography variant="body2">
              {part.desc}
            </Typography>

            <Grid display="flex" justifyContent='space-between' marginTop={1}>
              <Grid item>
                <LocationView state={part} />
              </Grid>
              <Grid item>
                <GridItemShare item={part} />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
      <Divider variant="middle" />
    </Grid>
  );
};
