import { useEffect } from "react";
import { useSelector } from "react-redux";
import { updateBreadcrumb } from "../state/slice/appSlice";
import { RootState, useAppDispatch } from "../state/store";
import { BreadcrumbNode } from "../types/BreadcrumbNode";

export const useBreadcrumb = (bcs: BreadcrumbNode[]) => {
  const dispatch = useAppDispatch();
  const vehicleViewUrl = useSelector((state: RootState) => state.app.latestVehicleVieWuRL);

  useEffect(() => {
    const changedBcs = bcs.map( bc => {
      const b = {...bc};
      if(b.path.includes('vehicle')) {
        b.path = vehicleViewUrl;
      }
      return b;
    })
    dispatch(
      updateBreadcrumb(changedBcs)
    );
    return () => {
      dispatch(
        updateBreadcrumb([])
      );
    }
  }, [])
};
