import * as React from 'react';
import { CheckCircle } from '@mui/icons-material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import { Vehicle } from '../../../types/Vehicle';
import { useFormState } from '../../../hooks/useFormState';
import { fuelTypeOptions } from '../../../selectOptions/fuelTypes';
import { transmissionTypes } from '../../../selectOptions/transmissionTypes';
import { vehicleTypes } from '../../../selectOptions/vehicleTypes';
import { sanitizeVehicle, setKeywordsForVehicle } from './vehicleCreate.util';
import { appendQueryParam, parseExtraProps } from '../../../util/helper';
import { Validators } from '../../../util/validators';
import { NumberInput, TextInput } from '../../shared';
import { SelectInput } from '../../shared/SelectInput';
import { ImageInput } from '../../shared/ImageInput';
import { CustomButton } from '../../shared/CustomButton';
import { LoaderLinear } from '../../loader/loaderLinear.component';
import { FormWrapper } from '../../shared/FormWrapper';
import { DateInput } from '../../shared/DateInput';
import { User } from '../../../types/User';
import { Grid } from '@mui/material';
import { config } from '../../../config';
import { InputGrid } from '../../shared/InputGrid';
import { PageHeader } from '../../shared/PageHeader';
import { defaultVehicle } from '../../../defaultValues/defaultVehicle';
import { PhoneInput } from '../../shared/PhoneInput';
import { ListingType } from '../../../enum/vehicleListing.enum';
import { LocationInput } from '../../shared/LocationInput';
import { ImageFolder } from '../../../enum/imageCategoryEnums';
import { FuelType } from '../../../enum/fuelType.enum';
import { useModels } from '../../../hooks/useModels';
import { useMemo } from 'react';
import { useBrands } from '../../../hooks/useBrands';
interface Props {
    activeStepId: number;
    user: User;
    loading: boolean;
    initialState?: Partial<Vehicle>;
    title: string;
    subTitle: string;
    onSave: (veh: Vehicle, images: Record<number, Blob | null>) => Promise<void>;
}
export const VehicleCreate: React.FC<Props> = ({ activeStepId, user, loading, initialState, title, subTitle, onSave }) => {

    const models = useModels();
    const brands = useBrands();

    const [activeStep, setActiveStep] = React.useState(activeStepId);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(-1);
    };

    const handleStep = (step: number) => () => {
        setActiveStep(step);
    };

    const [images, setImages] = React.useState<Record<number, Blob | null>>({});

    const onImageChange = (img: Blob | null, index: number) => {
        setImages(prev => {
            const next = { ...prev };
            next[index] = img;
            return next;
        });
    };
    const { state, changeProperty, changeBulk, changeWithExtraProperty } = useFormState<Vehicle>({
        ...defaultVehicle(user),
        ...initialState,
    });

    const imagesObj = JSON.parse(state.images);

    const handleSave = async () => {
        handleReset();
        await onSave(setKeywordsForVehicle(sanitizeVehicle(state), models, brands), images);
    }

    const imageCount = state.listing === ListingType.ListingForSale ? config.vehicleSaleImageCount : config.vehicleMaintananceImageCount;
    const powerLabel = state.fuel === FuelType.Electric ? 'Motor Power' : 'Cylinder Capacity';

    const steps = [
        {
            active: false,
            error: null,
            label: 'Basic Details',
            render: () => <Grid container>

                <InputGrid minWidth={6}>
                    <TextInput
                        suggestions={{
                            initDispVal: parseExtraProps(state.extraProps).brand ?? '',
                            options: brands,
                        }}
                        value={state.brand}
                        name="brand"
                        label="Brand"
                        error={!Validators.brand(state, brands)}
                        isRequired
                        disabled={loading}
                        onChange={changeWithExtraProperty}
                    />
                </InputGrid>

                <InputGrid minWidth={6}>
                    <SelectInput
                        name="type"
                        label="Vehicle Type"
                        options={vehicleTypes}
                        disabled={loading}
                        error={!Validators.vehicleType(state.type)}
                        onChange={changeProperty}
                        value={state.type}
                    />
                </InputGrid>

                <InputGrid minWidth={6}>
                    <TextInput
                        suggestions={{
                            initDispVal: parseExtraProps(state.extraProps).model ?? '',
                            options: filteredModels,
                        }}
                        value={state.model}
                        name="model"
                        label="Model"
                        error={!Validators.model(state, models)}
                        isRequired
                        disabled={loading || !Validators.brand(state, brands)} // model needs to be disabled until a valid brand is selected
                        onChange={changeWithExtraProperty}
                    />
                </InputGrid>

                <InputGrid minWidth={6}>
                    <NumberInput
                        value={state.year}
                        name="year"
                        label="Year of Manufacture"
                        isRequired
                        disabled={loading}
                        error={!Validators.year(state.year)}
                        onChange={changeProperty}
                        onEnter={handleNext}
                    />
                </InputGrid>
            </Grid>
        },
        {
            active: false,
            error: null,
            label: 'Specfications',
            render: () => <Grid container>
                <InputGrid>
                    <SelectInput
                        value={state.fuel}
                        name="fuel"
                        label="Fuel Type"
                        error={!Validators.fuelType(state.fuel)}
                        options={fuelTypeOptions}
                        disabled={loading}
                        onChange={changeProperty}
                    />
                </InputGrid>
                <InputGrid>
                    <NumberInput
                        value={state.cc}
                        name="cc"
                        label={powerLabel}
                        error={!Validators.cc(state.cc)}
                        disabled={loading}
                        onChange={changeProperty}
                    />
                </InputGrid>
                <InputGrid>
                    <SelectInput
                        value={state.gear}
                        name="gear"
                        label="Transmission"
                        error={!Validators.gear(state.gear)}
                        options={transmissionTypes}
                        disabled={loading}
                        onChange={changeProperty}
                    />
                </InputGrid>

                <InputGrid>
                    <NumberInput
                        value={state.odo}
                        name="odo"
                        label="ODO meter"
                        error={!Validators.odo(state.odo)}
                        disabled={loading}
                        onChange={changeProperty}
                    />
                </InputGrid>


                {state.listing === ListingType.ListingForSale && <InputGrid minWidth={12}>
                    <TextInput
                        value={state.desc ?? ''}
                        name="desc"
                        label="Description"
                        error={!Validators.description(state.desc)}
                        disabled={loading}
                        multiline
                        onChange={changeProperty}
                    />
                </InputGrid>
                }
            </Grid>
        },
        {
            active: true,
            error: null,
            label: state.listing === ListingType.ListingForMyOwn ? 'Registration Info.' : 'Advertisement Info.',
            render: state.listing === ListingType.ListingForMyOwn ? () =>
                <Grid container>
                    <InputGrid minWidth={4}>
                        <TextInput
                            value={state.regNo}
                            name="regNo"
                            label="Registration No"
                            error={!Validators.regNo(state.regNo)}
                            isRequired
                            disabled={loading}
                            onChange={changeProperty}
                        />
                    </InputGrid>
                    <InputGrid minWidth={4}>
                        <TextInput
                            value={state.chassis}
                            name="chassis"
                            label="Chassis No / VIN"
                            error={!Validators.chassis(state.chassis)}
                            disabled={loading}
                            onChange={changeProperty}
                        />
                    </InputGrid>
                    <InputGrid  >
                        <DateInput
                            value={state.date}
                            name="date"
                            label="Date of Purchase"
                            disabled={loading}
                            error={!Validators.datePast(state.date)}
                            onChange={changeProperty}
                            onEnter={handleNext}
                        />
                    </InputGrid>
                </Grid> : () => <Grid container>

                    <InputGrid minWidth={6}>
                        <PhoneInput
                            country={state.country}
                            value={state.phone}
                            name="phone"
                            label="Phone No."
                            error={!Validators.validatePhone(state.phone, state.country)}
                            onChange={changeProperty}
                            disabled={loading}
                        />
                    </InputGrid>

                    <InputGrid>
                        <NumberInput
                            value={state.price}
                            name="price"
                            label="Price"
                            error={!Validators.numberPositiveOrZero(state.price)}
                            isRequired
                            disabled={loading}
                            onChange={changeProperty}
                        />
                    </InputGrid>
                </Grid>
        },
        {
            active: false,
            error: null,
            label: 'Other',
            render: () => <>
                <Grid container>
                    {
                        Array.from(Array(imageCount)).map((_, i) => {
                            return <InputGrid key={i} minWidth={6}>
                                <ImageInput
                                    index={i}
                                    disabled={loading}
                                    defaultImageUrl={appendQueryParam(imagesObj[i], 'updatedAt', state.updatedAt.toString()) ?? config.defaultVehicleImageUrl}
                                    onImageSelected={onImageChange}
                                /></InputGrid>;
                        })

                    }
                </Grid>
                <Grid>

                    <InputGrid minWidth={12}>
                        <LocationInput
                            state={state}
                            country={user.country}
                            disabled={loading}
                            changeBulk={changeBulk}
                            changeWithExtraProperty={changeWithExtraProperty}
                        />
                    </InputGrid>
                    {state.listing === ListingType.ListingForMyOwn &&
                        <InputGrid>
                            <NumberInput
                                value={state.fuelPrice}
                                name="fuelPrice"
                                label="Fuel Price / Liter"
                                error={!Validators.fuelPrice(state.fuelPrice)}
                                disabled={loading}
                                onChange={changeProperty}
                                onEnter={handleNext}
                            />
                        </InputGrid>
                    }
                </Grid>
            </>
        },
    ];

    const filteredModels = useMemo(() => {
        return models.filter(t => t.meta?.brand === state.brand);
    }, [state.brand, models, brands]);

    // console.log('vehicle', vehicleState)

    return (
        <>
            {loading && <LoaderLinear />}
            <PageHeader header={title} subHeader={subTitle} />
            <FormWrapper>
                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((step, index) => (
                        <Step key={step.label}>
                            <StepLabel sx={{ cursor: 'pointer' }} error={!Validators.validVehicle(state, brands, models, index)} onClick={handleStep(index)}> {step.label}</StepLabel>

                            <StepContent TransitionProps={{ unmountOnExit: false }}>
                                {step.render?.()}
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>

                <Grid container justifyContent="center">
                    <CustomButton
                        EndIcon={CheckCircle}
                        label={`Save Vehicle`}
                        disabled={loading || !Validators.validVehicle(state, brands, models)}
                        onClick={handleSave}
                        loading={loading}
                    />
                </Grid>
            </FormWrapper>
        </>
    );
}