import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { FC } from "react";
import { Vehicle } from "../../../types/Vehicle";
import { Header } from "../header/header";
import { ImageView } from "../../shared/ImageView";
import Grid from "@mui/material/Grid";
import { Divider } from "@mui/material";
import { GridItemShare } from "../../share/gridItemShare";
import { FuelType } from "../../../enum/fuelType.enum";
import { ListingType } from "../../../enum/vehicleListing.enum";
import { getColorCodeForText, getFirstChar, getFormattedDate } from "../../../util/helper";
import { LabelHelper } from "../../../util/labelHelper";
import { SelectOption } from "../../../types/SelectOption";
import { useBrands } from "../../../hooks/useBrands";
import { LocationView } from "../../shared/LocationView";
interface Props {
  vehicle: Vehicle;
  models: SelectOption[];
  onSelect?: (v: Vehicle) => void;
  onUpdate?: (v: Vehicle) => void;
  onDelete?: (v: Vehicle) => void;
}
export const VehicleGridCard: FC<Props> = ({ vehicle, models, onSelect }) => {

  const fuelLabel = vehicle.fuel === FuelType.Electric ? 'KWH Price' : 'Fuel Price';
  const brands = useBrands();

  return (
    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ cursor: "pointer" }} onClick={() => { onSelect?.(vehicle) }}>
      <div>
        <Grid>
          <CardHeader
            avatar={
              <Avatar onClick={() => onSelect?.(vehicle)} sx={{ bgcolor: getColorCodeForText(LabelHelper.getBrandLabelEntity(vehicle, brands)) }} aria-label="recipe">
                {getFirstChar(LabelHelper.getBrandLabelEntity(vehicle, brands))}
              </Avatar>
            }
            title={`${LabelHelper.getBrandLabelEntity(vehicle, brands)} ${vehicle.listing === ListingType.ListingForSale ? LabelHelper.getModelLabelEntity(vehicle, models) : LabelHelper.displayRegNo(vehicle.regNo)}`}
            subheader={vehicle.listing === ListingType.ListingForSale ? getFormattedDate(vehicle.createdAt) : getFormattedDate(vehicle.date)}
          />
        </Grid>

        <div>
          <ImageView entity={vehicle} />
          <Grid marginLeft={2}>
            <Header vehicle={vehicle} />

            <span color="text.secondary">
              <b>
                {`${LabelHelper.getFuelTypeLabel(vehicle.fuel)} ${vehicle.cc}cc (${LabelHelper.getVehicleTypeLabel(vehicle.type)})`}
              </b>
            </span>

            <Grid display="flex" justifyContent='space-between'>
              <Grid item>
                {vehicle.listing === ListingType.ListingForSale &&
                  <Typography variant="body2" color="warn">
                    Price: {LabelHelper.displayPrice(vehicle.price)}
                  </Typography>}

                <LocationView state={vehicle} />
              </Grid>
              <Grid item>
                <GridItemShare item={vehicle} />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
      <Divider variant="middle" />
    </Grid>
  );
};
