import { useState } from "react";
import { config } from "../../../config";
import { usePaginatedData } from "../../../hooks/usePaginatedData";
import { useAllUsersQuery } from "../../../state/api/user.api";
import { User } from "../../../types/User";
import { UsersAdmin } from "./users.admin"
import { forcedSignInUser } from "../../../util/helper";

export const UsersAdminContainer = () => {
    
    // Pagination starts
    const [query, setQuery] = useState({
        limit: config.pageSize,
        offset: 0,
    });
    const { data, isFetching } = useAllUsersQuery(query);
    const { mergedData, handleLoadMore } = usePaginatedData(data, setQuery);
    // pagination ends

    const handleLogin = (user: User) => {
        forcedSignInUser(user);
    }

    return (
        <UsersAdmin
            hasMore={data?.length > 0}
            loading={isFetching}
            items={mergedData}
            onLoadMore={handleLoadMore}
            onLogin={handleLogin}
        />
    );
}