import { dispatch, RootState } from "./state/store";
import { PopupContainer } from "./components/popup/popup.container";
import { TopBarContainer } from "./components/topbar/topbar.container";
import { Router } from "./Router";
import { BreadcrumbContainer } from "./components/breadcrumb/breadcrumbs.container";
import { Box, Container, Typography } from "@mui/material";
import { FC, useEffect } from "react";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useLazyGetLoggedInUserQuery } from "./state/api/user.api";
import { onAppReady } from "./state/slice/appSlice";
import { config } from "./config";
import { PWA } from "./components/pwa/pwa";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { ErrorComponent } from "./components/error/error";
import { ConfirmBox } from "./components/shared/ConfirmBox";
import { getCacheStamp, resetAllCacheStamps } from "./util/cacheManager";
import { CacheStoreKey } from "./enum/cacheStore.enum";
import { useUser } from "./hooks/useUser";

const darkTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: 'rgb(138,35,135)'
    }
    // mode: 'dark',
  },
});

export const App: FC = () => {

  const { user, isForced } = useUser();
  const [fetchUser] = useLazyGetLoggedInUserQuery();

  const networkError = useSelector((state: RootState) => state.app.networkError);

  const userRequest = async () => {

    // User is fetched from the cache. If user lost the phone he will change the Password. Then accessToken will be changed witht he latest Login.
    // So old user will not be able to do any write acctions
    const cachedUserResult = await fetchUser({ cached: true });
    if (cachedUserResult.error) {
      console.error(cachedUserResult.error);
    }
    dispatch(onAppReady(true));

    const freshUserResult = await fetchUser({ cached: false });
    if (freshUserResult.error) {
      console.error(freshUserResult.error);
    } else {
      const serverUser = freshUserResult.data;
      if (serverUser?.cacheStamp === getCacheStamp(CacheStoreKey.userDBStamp)) {
        console.log('Same device login');
      } else {
        resetAllCacheStamps();
        // showConfirmBox('New device detected!', 'You have to restart the application to get the latest data').then(res => {
        //   resetAllCacheStamps();
        //   // cache reset done.
        // })
      }
    }
  }

  useEffect(() => {
    userRequest();
  }, []);

  if (networkError) {
    return <ErrorComponent
      title="Unable to communicate with the server"
      text="Please try again in few minutes or contact odocert@gmail.com" />
  }

  return (
    <>
      <PWA />
      <ThemeProvider theme={darkTheme}>
        <div className="App">
          <PopupContainer />
          <>
            <TopBarContainer />
            <ConfirmBox />

            {(user?.id === config.adminUserId) && <Link to="/admin"><Typography variant="caption" color="red">Admin User</Typography></Link>}

            {isForced && <Typography variant="caption" marginLeft={4} color="red">Forced logged user</Typography>}
            <BreadcrumbContainer />
            <Container maxWidth="xl">
              <Router />
              <Box sx={{ height: '100px', width: '100px', display: { md: 'none' } }} />
            </Container>
          </>
        </div>
      </ThemeProvider>
    </>
  );
}
