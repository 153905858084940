import { vehicleTypes } from "../selectOptions/vehicleTypes";
import { INode } from "../types/INode";

export const setSelectedNodes = (nodes: INode[], selectedNodeIds: string[]) => {
    for (const node of nodes) {
        node.isSelected = selectedNodeIds.includes(node.value);

        if (node.children && node.children.length > 0) {
            setSelectedNodes(node.children, selectedNodeIds);
        }
    }
    return nodes;
}

export const filterNodes = (nodes: INode[], selectedVehicleTypeIds: Array<(number | undefined)>, selectedVehicleGroupIds: Array<(number | undefined)>) => {

    return nodes.map(node => {

        if (node.specificVehicles) {
            let selectedValues: Array<number | undefined>;
            switch (node.specificVehicles.identfier) {
                case 'GROUP':
                    selectedValues = selectedVehicleGroupIds;
                    break;

                case 'ID':
                    selectedValues = selectedVehicleTypeIds;
                    break;
            }

            if (node.specificVehicles.condition === 'ENABLED') {
                node.isHidden = selectedValues.length > 0 ? selectedValues.reduce((acc, selectedValue) => acc && !node.specificVehicles?.items.includes(selectedValue ?? -1), true) : false;
            } else if (node.specificVehicles.condition === 'DISABLED') {
                node.isHidden = selectedValues.length > 0 ? selectedValues.reduce((acc, selectedValue) => acc && !!node.specificVehicles?.items.includes(selectedValue ?? -1), true) : false;
            }
        }

        if (node.children) {
            node.children = filterNodes(node.children, selectedVehicleTypeIds, selectedVehicleGroupIds);
        }

        return node;
    });
}

export const generateNodeArrayFromCategories = (partNodesArr: INode[], selectedVehiclesForPart: number[], categoriesOfPart: string[]) => {
    let filteredPartTypesNew: INode[] = partNodesArr;
    const selectedVehicleTypes = vehicleTypes.filter(t => selectedVehiclesForPart.includes(t.id));
    if (selectedVehicleTypes) {
        filteredPartTypesNew = filterNodes(filteredPartTypesNew, selectedVehicleTypes.map(ob => ob.id), selectedVehicleTypes.map(ob => ob.group) ?? 0);
    }
    return setSelectedNodes(filteredPartTypesNew, categoriesOfPart);
}