import { FormControl, InputLabel, ListSubheader, MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { SelectOption } from "../../types/SelectOption";

interface Props {
  name: string;
  value: number | undefined;
  error?: boolean;
  size?: "small";
  variant?: "standard" | "outlined" | "filled";
  label: string;
  disabled: boolean;
  options: SelectOption[];
  onChange: (key: string, value: number | string) => void;
}

export const SelectInput: FC<Props> = ({
  name,
  value = 0,
  error,
  label,
  disabled,
  variant,
  size,
  options,
  onChange,
}) => {
  if (options.length === 0) {
    return <div>No vehicles available</div>
  }
  const selectedValue = options.find((ele) => ele.id === value) ?? options[0];

  const groups: any = {};

  const menuItems = () => {
    const renderItems: any = [];
    options.forEach(({ id, Icon, label, group, subHeader }) => {
      if (subHeader) { 
        renderItems.push(<ListSubheader key={`${id} ${group}`}>{subHeader}</ListSubheader>)
      } 
      renderItems.push(<MenuItem key={id} value={id}><Stack direction="row"><div style={{height: '0px'}}
        >{Icon}</div><Typography sx={{ marginLeft: Icon ? 1 : 0 }}>{label}</Typography></Stack></MenuItem>);
    });
    // options.forEach(({ id, Icon, label, group }) => {
    //   if (!groups[group ?? '']) {
    //     groups[group ?? ''] = true;
    //     renderItems.push(<ListSubheader key={`${id} ${group}`}>{vehicleTypeGroups[group ?? 0]}</ListSubheader>)
    //     renderItems.push(<MenuItem key={id} value={id}><Stack direction="row"><div style={{height: '0px'}}>{Icon}</div><Typography sx={{ marginLeft: Icon ? 1 : 0 }}>{label}</Typography></Stack></MenuItem>)
    //   } else {
    //     renderItems.push(<MenuItem key={id} value={id}><Stack direction="row"><div style={{height: '0px'}}
    //     >{Icon}</div><Typography sx={{ marginLeft: Icon ? 1 : 0 }}>{label}</Typography></Stack></MenuItem>);
    //   }
    // });
    return renderItems;
  }

  return (
    <FormControl fullWidth>
      <InputLabel size={size ?? "normal"} variant={variant ?? "standard"}>{label}</InputLabel>
      <Select
        size={size ?? "medium"}
        disabled={disabled}
        variant={variant ?? "standard"}
        value={`${selectedValue.id}`}
        label={label}
        error={error}
        onChange={(e: SelectChangeEvent) => onChange(name, e.target.value)}
      >
        {menuItems()}
      </Select>
    </FormControl>
  );
};
