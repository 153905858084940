import { FC, useEffect } from 'react';
import Tour, { ReactourStep } from 'reactour'
import { config } from '../../config';

interface Props {
  steps: ReactourStep[]
  onClose: () => void;
  onViewStep: (step: number) => void;
}
export const Intro: FC<Props> = ({ steps, onClose, onViewStep }) => {

  useEffect(() => {
    onViewStep(0);
  }, []);

  if(!config.showIntro) {
    return <></>;
  }

  return <Tour
    steps={steps}
    getCurrentStep={onViewStep}
    isOpen={true}
    onRequestClose={onClose}
  />;
}