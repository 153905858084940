import { ToggleButton, Tooltip } from '@mui/material';
import { FilterAlt } from '@mui/icons-material';
import { FC, useState } from 'react';
import { RecordFilterSelector } from './recordFilterSelector';
import { RecordsFilterType } from '../../../types/filters/RecordsFilterType';

interface Props {
    filter: RecordsFilterType;
    onFilterChange: (filter: RecordsFilterType) => void;
}

export const RecordFilter: FC<Props> = ({ filter, onFilterChange }) => {
    const [open, setOpen] = useState(false);

    const handleClickListItem = () => {
        setOpen(true);
    };

    const handleClose = (filter: RecordsFilterType) => {
        setOpen(false);
        if (filter != null) {
            onFilterChange(filter);
        }
    };

    return (
        <>
            <ToggleButton className='filter-icon' value="Full" onClick={handleClickListItem}>
                <Tooltip title="Filter records">
                    <FilterAlt />
                </Tooltip>
            </ToggleButton>
            <RecordFilterSelector id={'1'} keepMounted={false} filter={filter} open={open} onClose={handleClose} />
        </>
    );
}
