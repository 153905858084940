import { SelectOption } from "../types/SelectOption";

import bikescooter from "../assets/vehicleTypes/bikescooter.png";
import bikemotorcycle from "../assets/vehicleTypes/bikemotorcycle.png";
import bikesport from "../assets/vehicleTypes/bikesport.png";
import biketuk from "../assets/vehicleTypes/biketuk.png";

import carsedan from "../assets/vehicleTypes/carsedan.png";
import carmicro from "../assets/vehicleTypes/carmicro.png";
import carcrossover from "../assets/vehicleTypes/carcrossover.png";
import carcoupe from "../assets/vehicleTypes/carcoupe.png";
import carsport from "../assets/vehicleTypes/carsport.png";
import carroadster from "../assets/vehicleTypes/carroadster.png";
import carcabriolet from "../assets/vehicleTypes/carcabriolet.png";
import carlimousine from "../assets/vehicleTypes/carlimousine.png";
import carhatchback from "../assets/vehicleTypes/carhatchback.png";
import carwagon from "../assets/vehicleTypes/carwagon.png";

import suvoffroad from "../assets/vehicleTypes/suvoffroad.png";
import suvluxuary from "../assets/vehicleTypes/suvluxuary.png";
import suvpickup from "../assets/vehicleTypes/suvpickup.png";

import truck from "../assets/vehicleTypes/truck.png";
import truckmini from "../assets/vehicleTypes/truckmini.png";
import truckbed from "../assets/vehicleTypes/truckbed.png";
import truckbedmini from "../assets/vehicleTypes/truckbedmini.png";
import trucktow from "../assets/vehicleTypes/trucktow.png";
import truckboom from "../assets/vehicleTypes/truckboom.png";
import trucktank from "../assets/vehicleTypes/trucktank.png";
import truckcrew from "../assets/vehicleTypes/truckcrew.png";
import truckdump from "../assets/vehicleTypes/truckdump.png";

import vancargo from "../assets/vehicleTypes/vancargo.png";
import vanmini from "../assets/vehicleTypes/vanmini.png";
import vanpassenger from "../assets/vehicleTypes/vanpassenger.png";

import busheavy from "../assets/vehicleTypes/busheavy.png";
import bus from "../assets/vehicleTypes/bus.png";

export const DEFAULT_SELECTED_VEHICLE_TYPE = 207;

export const vehicleTypeGroups: Record<number, string> = {
  0: 'N/A',
  1: 'Bikes',
  2: 'Cars',
  3: 'SUV',
  4: 'Trucks',
  5: 'Vans',
  6: 'Buses'
}

export const vehicleTypes: SelectOption[] = [
  { label: "Scooter", id: 100, Icon: <img style={{ height: '30px' }} src={bikescooter} />, group: 1, subHeader: 'Bikes' },
  { label: "Motorcycle", id: 101, Icon: <img style={{ height: '30px' }} src={bikemotorcycle} />, group: 1 },
  { label: "Sport Bike", id: 102, Icon: <img style={{ height: '30px' }} src={bikesport} />, group: 1 },
  { label: "Tuk Tuk", id: 103, Icon: <img style={{ height: '30px' }} src={biketuk} />, group: 1 },

  // { label: "Micro Car", id: 200, Icon: <img style={{ height: '30px' }} src={carmicro} />, group: 2, subHeader: 'Cars'  },
  { label: "Hatchback", id: 201, Icon: <img style={{ height: '30px' }} src={carhatchback} />, group: 2, subHeader: 'Cars'  },
  { label: "Station Wagon", id: 202, Icon: <img style={{ height: '30px' }} src={carwagon} />, group: 2 },
  // { label: "Cabriolet", id: 203, Icon: <img style={{ height: '30px' }} src={carcabriolet} />, group: 2 },
  // { label: "Roadster", id: 204, Icon: <img style={{ height: '30px' }} src={carroadster} />, group: 2 },
  // { label: "Coupe", id: 206, Icon: <img style={{ height: '30px' }} src={carsedan} />, group: 2 },
  { label: "Sedan", id: DEFAULT_SELECTED_VEHICLE_TYPE, Icon: <img style={{ height: '30px' }} src={carcoupe} />, group: 2 },
  // { label: "Sport Car", id: 208, Icon: <img style={{ height: '30px' }} src={carsport} />, group: 2 },
  { label: "Crossover", id: 209, Icon: <img style={{ height: '30px' }} src={carcrossover} />, group: 2 },
  // { label: "Limousine", id: 210, Icon: <img style={{ height: '30px' }} src={carlimousine} />, group: 2 },

  { label: "SUV", id: 300, Icon: <img style={{ height: '30px' }} src={suvluxuary} />, group: 3 , subHeader: 'SUV'}, // Luxuary SUV
  // { label: "Offroad SUV", id: 301, Icon: <img style={{ height: '30px' }} src={suvoffroad} />, group: 3 },
  { label: "Pickup", id: 302, Icon: <img style={{ height: '30px' }} src={suvpickup} />, group: 3 },

  { label: "Mini Van", id: 400, Icon: <img style={{ height: '30px' }} src={vanmini} />, group: 5, subHeader: 'Vans' },
  { label: "Passenger Van", id: 401, Icon: <img style={{ height: '30px' }} src={vanpassenger} />, group: 5 },
  // { label: "Cargo Van", id: 402, Icon: <img style={{ height: '30px' }} src={vancargo} />, group: 5 },

  { label: "Bus", id: 500, Icon: <img style={{ height: '30px' }} src={bus} />, group: 6, subHeader: 'Buses'  },
  { label: "Heavy Bus", id: 501, Icon: <img style={{ height: '30px' }} src={busheavy} />, group: 6 },

  { label: "Mini Truck", id: 600, Icon: <img style={{ height: '30px' }} src={truckbedmini} />, group: 4, subHeader: 'Trucks' },
  { label: "Truck", id: 601, Icon: <img style={{ height: '30px' }} src={truckbed} />, group: 4},
  { label: "Dump Truck", id: 602, Icon: <img style={{ height: '30px' }} src={truckdump} />, group: 4 },
  // { label: "Bed Truck", id: 603, Icon: <img style={{ height: '30px' }} src={truckbed} />, group: 4 },
  // { label: "Bed Mini Truck", id: 604, Icon: <img style={{ height: '30px' }} src={truckbedmini} />, group: 4 },
  { label: "Tow Truck", id: 605, Icon: <img style={{ height: '30px' }} src={trucktow} />, group: 4 },
  { label: "Boom Truck", id: 606, Icon: <img style={{ height: '30px' }} src={truckboom} />, group: 4 },
  { label: "Crew Truck", id: 607, Icon: <img style={{ height: '30px' }} src={truckcrew} />, group: 4 },
  { label: "Tank Truck", id: 608, Icon: <img style={{ height: '30px' }} src={trucktank} />, group: 4 },
];

export const vehicleTypesWithAny: SelectOption[] = [{ label: "Any", id: -1 }, ...vehicleTypes];