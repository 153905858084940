import { SelectOption } from "../../../types/SelectOption";
import { Vehicle } from "../../../types/Vehicle";
import { LabelHelper } from "../../../util/labelHelper";

export const sanitizeVehicle = (v: Vehicle) => {

  v.regNo = v.regNo?.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
  v.chassis = v.chassis?.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
  return v;
};

export const setKeywordsForVehicle = (v: Vehicle, models: SelectOption[], brands: SelectOption[]) => {
  const fullStr: string = v.desc + ' ' +
    v.regNo?.toString() + ' ' + // special uppercase
    v.chassis?.toString() + ' ' +
    v.cc?.toString() + ' ' +
    v.year.toString() + ' ' +
    LabelHelper.getBrandLabelEntity(v, brands) + ' ' +
    LabelHelper.getFuelTypeLabel(v.fuel) + ' ' +
    LabelHelper.getTransmissionTypeLabel(v.gear) + ' ' +
    LabelHelper.getVehicleTypeLabel(v.type) + ' ' +
    LabelHelper.getModelLabelEntity(v, models);

  // all the keywords are splitted together here
  v.keywords = fullStr.toLocaleLowerCase()
    .replaceAll('N/A', '')
    .replaceAll('null', '')
    .replaceAll('undefined', '')
    .split(' ').filter(word => word.length > 1);
  // console.log('keywords', v.keywords);
  return v;
}
