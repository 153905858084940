import * as React from 'react';
import { Button, ButtonGroup, Grid } from '@mui/material';
import { Part } from '../../../types/Part';
import { PopupManager } from '../../../util/popupManager';

interface Props {
    p: Part;
    onEdit: (p: Part) => void;
    onDelete: (p: Part) => void;
}
export const PartActions: React.FC<Props> = ({ p, onEdit, onDelete }) => {

    const handleDelete = () => {
        onDelete(p);
    };

    return (
        <Grid container justifyContent="end" margin={1}>
            <Grid item >
                <ButtonGroup variant="outlined" aria-label="text button group" size="small">
                    <Button onClick={() => onEdit(p)}>Edit</Button>
                    <Button color="error" onClick={handleDelete}>Delete</Button>
                </ButtonGroup>
            </Grid>
        </Grid>
    );
}
