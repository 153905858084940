import { Grid } from "@mui/material"
import { ImageView } from "../../shared/ImageView"
import { FC } from "react"
import { Part } from "../../../types/Part"
import { PartHeader } from "../header/partHeader"
import { EntityViewHeader } from "../../shared/EntityViewHeader"
import { EntityType } from "../../../types/EntityType"
import { LabelHelper } from "../../../util/labelHelper"
import { useBrands } from "../../../hooks/useBrands"
import { useModels } from "../../../hooks/useModels"

interface Props {
  part: Part;
  loadingFavorite: boolean;
  showFavourite: boolean;
  isFavourite: boolean;
  onFavouriteChange: (isFavourite: boolean) => void;
}
export const PartView: FC<Props> = ({ part, showFavourite, isFavourite, loadingFavorite, onFavouriteChange }) => {

  const brands = useBrands();
  const models = useModels();

  return <>
    <Grid container marginTop={2}>
      <EntityViewHeader
        entityType={EntityType.part}
        title={part.title}
        subTitle={`${LabelHelper.getBrandLabelEntity(part, brands)} ${LabelHelper.getModelLabelEntity(part, models)}`}
        isFavourite={isFavourite}
        loadingFavorite={loadingFavorite}
        showFavourite={showFavourite}
        entity={part}
        onFavoriteChange={onFavouriteChange}
      />
    </Grid>
    <Grid item xs={12}>
      <PartHeader entity={part} />
    </Grid>
    <Grid container spacing={1} justifyContent="center">
      <Grid item xs={12} md={6}>
        <ImageView entity={part} />
      </Grid> 
    </Grid>
  </>
}