import { Stack, Chip } from "@mui/material"
import { Fitbit, HistoryToggleOff, Speed } from "@mui/icons-material";
import { Vehicle } from "../../../types/Vehicle";
import { FC } from "react";
import { LabelHelper } from "../../../util/labelHelper";

interface Props {
    vehicle: Vehicle;
}
export const Header: FC<Props> = ({ vehicle }) => {
    return (
        <Stack sx={{ margin: 1 }} justifyContent="center" direction="row" spacing={1}>
            <Chip
                icon={<HistoryToggleOff />}
                label={vehicle.year}
                color="warning"
                style={{ fontWeight: 'bold' }}
            />
            <Chip
                icon={<Speed />}
                label={`${vehicle.odo} km`}
                color="success"
                style={{ fontWeight: 'bold' }}
            />
            <Chip
                icon={<Fitbit />}
                label={LabelHelper.getTransmissionTypeLabel(vehicle.gear)}
                color="info"
                style={{ fontWeight: 'bold' }}
            />

        </Stack>);
}