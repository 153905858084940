import Typography from "@mui/material/Typography";
import {
  Grid,
  Box,
  CardHeader,
  Button
} from "@mui/material";
import { CSSProperties, FC } from "react";
import { AddCircle } from "@mui/icons-material";

interface Props {
  Icon: FC<{ style: CSSProperties }>;
  text: string;
  subText: string;
  onAdd: () => void;
}
export const GridItemAdd: FC<Props> = ({ Icon, text, subText, onAdd }) => {
  return (
    <Grid item xs={12} justifyContent="start">
      <Button variant="text" startIcon={<AddCircle />} onClick={onAdd}>
        {text}
      </Button>
      {/* <Box sx={{ margin: 0, cursor: 'pointer' }} onClick={() => onAdd()}> */}
      {/* If we remove these dots CardHeader will be hidden and AddNewRecod button is dragged to top */}
      {/* <div style={{border: 'solid', borderRadius: '4px' , borderColor: 'grey'}}> */}
      {/* <CardHeader sx={{color: 'transparent'}} title={"."} subheader={" "} color="transperant" /> */}

      {/* <Grid container> */}
      {/* <Grid item margin={"auto"}>
            <Icon style={{ fontSize: "30px", margin: 'auto', display: 'block' }}/>
         
            <Typography align="center" variant="button">{text}</Typography> */}
      {/* <Typography align="center" variant="subtitle1">{subText}</Typography> */}
      {/* </Grid> */}
      {/* </Grid> */}
      {/* </div> */}
      {/* </Box> */}
    </Grid>
  );
};
