import { FC, useState } from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { Page } from '../../types/Page';
import { SidePanel } from './sidePanel';
import { MonetizationOn, Sell } from '@mui/icons-material';
import { moreMenu } from '../../hooks/useNavigationMenu';

interface Props {
  showPanel: boolean;
  onVisibilityChange: (isVisible: boolean) => void
  pages: Page[];
  selectedPage: number;
  onClick: (path: string) => void;
}
export const BottomBar: FC<Props> = ({pages, selectedPage, showPanel, onVisibilityChange, onClick}) => {
  const [, setValue] = useState(0);

  return (
    <Box sx={{position: 'fixed', bottom: '0px',  width: '100%', zIndex:1, display: { md: "none" }}}>
      <BottomNavigation
        showLabels
        value={selectedPage}  
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
          
        {
          pages.map(p => <BottomNavigationAction label={p.label} icon={p.Icon} key={p.label} onClick={() => onClick(p.path)} />)
        }

        <BottomNavigationAction label={moreMenu} icon={<Sell />} onClick={() => onVisibilityChange(true)} />
        
      </BottomNavigation>
    </Box>
  );
}