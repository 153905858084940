// import { updateBreadcrumb } from "../../state/slice/appSlice";
import { useNavigate } from "react-router-dom";
import {  useAppSelector } from "../../state/store";
import { BreadcrumbNode } from "../../types/BreadcrumbNode";
import { Breadcrumbs } from "./breadcrumbs";

export const BreadcrumbContainer = () => {
  // const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const nodes = useAppSelector((state) => state.app.breadcrumbs);

  const navigateBack = (node: BreadcrumbNode) => {
    // dispatch(updateBreadcrumb(node));
    navigate(node.path);
  };

  return <Breadcrumbs nodes={nodes} onSelectNode={navigateBack} />;
};
