import { Grid, Typography } from "@mui/material";
import { FC } from "react";


interface Props {
    desc: string;
}
export const DescriptionView: FC<Props> = ({ desc }) => {

    if (desc?.length > 1) {
        return <Grid item xs={12}><Typography sx={{backgroundColor: '#efefef'}} padding={1} whiteSpace="pre-wrap" variant="body2" marginTop={1}>{desc}</Typography></Grid>;
    }
    return <></>;
}