import { FC } from "react"
import { Grid } from "@mui/material"
import { Part } from "../../types/Part";
import { PartGridCard } from "../parts/view/partGridCard";

interface Props {
    items: Part[] | null;
    onSelect: (p: Part) => void;
}
export const FavouriteParts: FC<Props> = ({ items, onSelect }) => {

    return <Grid
        container
        spacing={1}
    >
        {items?.map((p) => (
            <PartGridCard
                key={`${p.id}${p.createdAt.toString()}`}
                part={p}
                onSelect={onSelect}
            />
        ))}
    </Grid>
}