import { Dispatch, SetStateAction, useState } from "react";

export const usePaginatedData = (data: any[], setQuery: Dispatch<SetStateAction<any & {
  limit: number;
  offset: number;
}>>) => {
  const [prevReceivedData, setPreReceivedvData] = useState<any[]>([]);
  const [mergedData, setMergedData] = useState<any[]>([]);

  const handleLoadMore = (nextLimit: number) => {
    setQuery((prev: any) => {
      const next = { ...prev, offset: nextLimit };
      return next;
    });
  };

  const resetData = () => {
    setMergedData([]);
  }

  const isNewDataReceived =
    data?.length > 0 &&
    (prevReceivedData.length === 0
      ? true
      : prevReceivedData[0].id !== data[0].id);

  if (isNewDataReceived) {
    const allData = [...mergedData, ...data];
    setMergedData(allData);
    setPreReceivedvData(data);
  }

  return {mergedData, handleLoadMore, resetData};
};
