import { FC } from "react"
import { Vehicle } from "../../types/Vehicle"
import { Grid } from "@mui/material"
import { VehicleGridCard } from "../vehicle/view/vehicleGridCard"
import { SelectOption } from "../../types/SelectOption";

interface Props {
    items: Vehicle[] | null;
    models: SelectOption[]
    onSelect: (v: Vehicle) => void;
}
export const FavouriteVehicles: FC<Props> = ({ items, models, onSelect }) => {

    return <Grid
        container
        spacing={1}
    >
        {items?.map((v) => (
            <VehicleGridCard
                models={models}
                key={`${v.id}${v.createdAt.toString()}`}
                vehicle={v}
                onSelect={onSelect}
            />
        ))}
    </Grid>
}