import { FC, useEffect, useState } from "react";
import { ReactourStep } from "reactour";
import { config } from "../../config";
import { getLocalStorageAny, setLocalStorageAny } from "../../util/localData";
import { Intro } from "./Intro";
import { LocalStorageKeys } from "../../enum/localStorageKeys.enum";
import { useUser } from "../../hooks/useUser";


interface Props {
    storageKey: LocalStorageKeys;
    steps: ReactourStep[];
}
export const IntroContainer: FC<Props> = ({steps, storageKey}) => {

    const user = useUser()
    const [isClosed, setClosed] = useState(false);
    const [stepsToShow, setStepsToShow] = useState<ReactourStep[]>([]);

    useEffect(() => {
        if (user) {
            setTimeout(() => {
                const steps2 = unseenSteps(steps);
                setStepsToShow(steps2);
            }, config.introBeginTimer);
        }
    }, [user]);

    const unseenSteps = (steps: ReactourStep[]) => {
        const unseen =  steps.filter(st => {
            const seen = getLocalStorageAny(`${storageKey}${st.selector}`);
            return seen === null;
        });
        return unseen;
    }

    const handleClose = () => {
        setClosed(true);
    }

    const handleViewStep = (idx: number) => {
        const st = stepsToShow[idx];
        setLocalStorageAny(`${storageKey}${st.selector}`, true);
    }

    if(stepsToShow.length === 0 || isClosed) {
        return <></>;
    }

    return <Intro onViewStep={handleViewStep} steps={stepsToShow} onClose={handleClose} />;
}