import { FC } from "react";
import { SignUpContainer } from "./signUp/signUp.container";
import { LoginUIMode } from "../../enum/login.ui.mode";
import { Grid, Link } from "@mui/material";
import { SignInContainer } from "./signIn/signIn.container";
import { ForgotPasswordContainer } from "./forgotPassword/forgotPassword.container";
interface Props {
  mode: LoginUIMode;
  onModeChange: (mode: LoginUIMode) => void;
}
export const Login: FC<Props> = ({ onModeChange, mode }) => {
  const handleModeChange = (mode: LoginUIMode) => {
    onModeChange(mode);
  };

  const renderContent = (mode: LoginUIMode) => {
    switch (mode) {
      case LoginUIMode.SIGN_IN:
        return <SignInContainer />;
      case LoginUIMode.SIGN_UP:
        return <SignUpContainer />;
      case LoginUIMode.FORGOT_PASSWORD:
        return <ForgotPasswordContainer />;
      default:
        return <></>;
    }
  };

  return <>
  { renderContent(mode) }
  <Grid>
    {mode !== LoginUIMode.FORGOT_PASSWORD && <Grid item xs margin={1}>
      <Link
        sx={{ textDecoration: 'none' }}      
        href="#"
        variant="body2"
        onClick={() => handleModeChange(LoginUIMode.FORGOT_PASSWORD)}
      >
        Forgot password?
      </Link>
    </Grid>}
    <Grid item>
      {mode === LoginUIMode.SIGN_IN ? (
        <Link
          sx={{ textDecoration: 'none' }}
          href="#"
          variant="body2"
          onClick={() => handleModeChange(LoginUIMode.SIGN_UP)}
        >
          {"Create Own Account"}
        </Link>
      ) : (
        <Link
          sx={{ textDecoration: 'none' }}
          href="#"
          variant="body2"
          onClick={() => handleModeChange(LoginUIMode.SIGN_IN)}
        >
          {"Already have an account? Sign In"}
        </Link>
      )}
    </Grid>
  </Grid>
  </>
};