import { FuelType } from "../enum/fuelType.enum";
import { SelectOption } from "../types/SelectOption";

export const fuelTypeOptions: SelectOption[] = [
  { label: "Petrol", id: FuelType.Petrol },
  { label: "Disel", id: FuelType.Disel },
  { label: "Electric", id: FuelType.Electric },
  { label: "Hybrid", id: FuelType.Hybrid },
];

export const fuelTypesWithAny: SelectOption[] = [
  { label: "Any", id: -1 }, ...fuelTypeOptions
]