export enum SearchParams {
    vehicleParams = 'vehicleParams',
    vehicleListing = 'vehicleListing',
    recordViewMode = 'recordViewMode',
    recordsFetch = 'recordsFetch',
    recordFilters = 'recordFilters',
    vehicleViewMode = 'vehicleViewMode',
    fromAddRecord = 'fromAddRecord',

    vehicleFilterObj = 'vehicleFilterObj',
    vehicleFilterKeywords = 'vehicleFilterKeywords',
    vehicleFilterEnabled = 'vehicleFilterEnabled',
   
    partsFilterObj = 'partsFilterObj',
    partsFilterKeywords = 'partsFilterKeywords',
    partsFilterEnabled = 'partsFilterEnabled',
}