export const enum ImageSources {
  firebase = 1,
}

export enum RootFolder {
  Users = "users",
  Config = "config"
}

export enum ImageFolder {
  Vehicle = "vehicles",
  Parts = "parts",
  User = "user"
}

export enum SubImageFolder {
  Records = "records",
  ProfileImages = "profile"
}