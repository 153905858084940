import { Button, Grid } from "@mui/material"
import { FC, useState } from "react"
import { Part } from "../../../types/Part"
import { partsTypes } from "../../../selectOptions/partsTypes"
import { TreeSelect } from "../../treeView/TreeView"
import { INode } from "../../../types/INode"
import { ExpandCircleDown, ExpandLess } from "@mui/icons-material"
import { generateNodeArrayFromCategories } from "../../../util/nodeHelper"
import { DescriptionView } from "../../shared/DescriptionView"
import { LocationView } from "../../shared/LocationView"

interface Props {
  part: Part;
}
export const PartMoreDetailView: FC<Props> = ({ part }) => {
  const filteredPartTypesNew: INode[] = generateNodeArrayFromCategories(partsTypes, part.vehicleTypes, part.categories);
  const [showMore, setShowMore] = useState(false);

  return <>
    <DescriptionView desc={part.desc} />
    <Grid item xs={12}>
      <LocationView state={part} />
    </Grid>

    <Grid container justifyContent="end">
      <Button variant="text" endIcon={showMore ? <ExpandLess /> : <ExpandCircleDown />} onClick={() => setShowMore(!showMore)}>
        {showMore ? 'Hide categories' : 'Show available categories...'}
      </Button>
    </Grid>

    {
      showMore && <TreeSelect disabled allExpanded userNodeArr={filteredPartTypesNew} isSingleSelection={false} />
    }
  </>
}