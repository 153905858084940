import { Stack, Chip } from "@mui/material"
import { Fitbit } from "@mui/icons-material";
import { FC } from "react";
import { Part } from "../../../types/Part";
import { LabelHelper } from "../../../util/labelHelper";
import { useBrands } from "../../../hooks/useBrands";
import { useModels } from "../../../hooks/useModels";

interface Props {
    entity: Part;
}
export const PartHeader: FC<Props> = ({ entity }) => {
    const brands = useBrands();
    const models = useModels();

    return (
        <Stack sx={{ margin: 1 }} justifyContent="center" direction="row" spacing={1}>
            <Chip
                icon={<Fitbit />}
                label={LabelHelper.getBrandLabelEntity(entity, brands)}
                color="primary"
                style={{fontWeight: 'bold'}}
            />
            <Chip
                icon={<Fitbit />}
                label={LabelHelper.getModelLabelEntity(entity, models)}
                color="success"
                style={{fontWeight: 'bold'}}
            />
            {/* <Chip
                icon={<HistoryToggleOff />}
                label={part.year}
                color="warning"
                style={{fontWeight: 'bold'}}
            />
                <Chip
                icon={<Settings />}
                label={getTransmissionTypeLabel(part.gear)}
                color="success"
                style={{fontWeight: 'bold'}}
                // style={ vehicle.model.length > 10 ? {fontSize: '10px'} : {}}
            /> */}
        </Stack>);
}