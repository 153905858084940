import { PopupType } from "../enum/popup.type";
import { updateConfirmBox, showPopup } from "../state/slice/appSlice";
import { dispatch, subscribe, getState } from "../state/store";
import { ConfirmBoxResult } from "../state/appState";

export class PopupManager {

  public static showErrorToast = (text: string): void => {
    dispatch(
      showPopup({
        type: PopupType.error,
        message: text,
      })
    );
  };

  public static showSuccessToast = (text: string): void => {
    dispatch(
      showPopup({
        type: PopupType.success,
        message: text,
      })
    );
  };
  
  public static showInfoToast = (text: string): void => {
    dispatch(
      showPopup({
        type: PopupType.info,
        message: text,
      })
    );
  };

  public static showConfirmBox = (header: string, text: string, inputType: 'string' | 'number' | 'boolean', notes?: string): Promise<ConfirmBoxResult> => {
    dispatch(
      // Set the new values here
      updateConfirmBox({
        isOpen: true,
        header,
        text,
        inputType,
        notes
      })
    );

    return new Promise((resolve, reject) => {
      const unsubscribeFunc = subscribe(() => {
        const appState = getState();
        const result = appState.app.confirmBox.result;
        if (appState.app.confirmBox.isOpen && result.confirmed !== null) {
          dispatch(
            // Set the empty values here
            updateConfirmBox({
              isOpen: false,
              header: '',
              text: '',
              notes: '',
              inputType: undefined,
              result: {
                confirmed: null,
                data: null
              }
            })
          );
          unsubscribeFunc();
          resolve(result);
          // if (result.confirmed) {
          //   resolve(result.data);
          // } else {
          //   reject(result.data);
          // }
        }
      });
    });
  };
}
