import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { FC, useState } from "react";
import { Vehicle } from "../../../types/Vehicle";
import { forcedSignInUser, parseExtraProps } from "../../../util/helper";
import { CustomButton } from "../../shared/CustomButton";
import { TextInput } from "../../shared";
import { useBrands } from "../../../hooks/useBrands";
import { useModels } from "../../../hooks/useModels";
import { Part } from "../../../types/Part";
import { Edit, Save } from '@mui/icons-material';
import { LabelHelper } from "../../../util/labelHelper";
import { PopupManager } from "../../../util/popupManager";

interface Props {
    isVehicle: boolean;
    items: Array<Vehicle | Part>;
    loading: boolean;
    viewEntity: (v: Vehicle | Part, isVehicle: boolean) => void;
    addModel: (isVehicle: boolean, item: Vehicle | Part, modelId: number | undefined, modelString: string | undefined) => void;
}

export const ModelAdditions: FC<Props> = ({ items, isVehicle, addModel, viewEntity }) => {

    const [editNo, setEditNo] = useState(-1);

    const [model, setModel] = useState('');

    const brands = useBrands();
    const models = useModels();

    const [state, setState] = useState<{
        modelId: number | undefined,
        modelString: string | undefined
    }>({
        modelId: undefined,
        modelString: undefined,
    })

    const enableEdit = (item: Vehicle | Part) => {

        if (item.brand === -1) {
            PopupManager.showErrorToast('Brand is temporary added');
            return;
        }

        const model = parseExtraProps(item.extraProps).model;
        if (item.id === editNo) {
            setEditNo(-1);
            addModel(isVehicle, item, state.modelId, state.modelString);

        } else {
            setModel(model);
            setEditNo(item.id);
            setState({
                modelId: undefined,
                modelString: parseExtraProps(item.extraProps).model
            });
        }
    }

    const handleOnChange = (key: string, value: string | number, fromSuggestions?: boolean) => {
        // Do not change the order below
        if (fromSuggestions) {
            setState({
                modelId: Number(value),
                modelString: undefined,
            });
        } else {
            setState({
                modelId: undefined,
                modelString: `${value}`,
            });
        }
    }

    const handleForceUserLogin = (row: Vehicle | Part) => {
        viewEntity(row, isVehicle);
        forcedSignInUser(row.User);
    }

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Brand</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Model</TableCell>
                        <TableCell>User</TableCell>
                        <TableCell>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items?.map((row) => (
                        <TableRow key={row.id} sx={{ background: row.brand === -1 ? 'orange' : 'white' }}>
                            <TableCell>{row.id}</TableCell>
                            <TableCell>{LabelHelper.getBrandLabelEntity(row, brands)}</TableCell>
                            <TableCell>{isVehicle ? LabelHelper.getVehicleTypeLabel((row as Vehicle).type) : (row as Part).vehicleTypes.map(c => <div key={c}>{LabelHelper.getVehicleTypeLabel(c)}</div>)}</TableCell>
                            <TableCell>
                                {editNo === row.id ? <TextInput
                                    name={"model"}
                                    suggestions={{
                                        initDispVal: parseExtraProps(row.extraProps).model ?? '',
                                        options: models,
                                        // onSelect: handleOnSelect
                                    }}
                                    value={model}
                                    label={'Model'}
                                    onChange={handleOnChange}
                                />
                                    :
                                    <div>{LabelHelper.getModelLabelEntity(row, models)}</div>
                                }
                            </TableCell>
                            <TableCell>
                                <Button variant="text" onClick={() => handleForceUserLogin(row)}>{`${row.User?.name}(${row.UserId})`}</Button>
                            </TableCell>
                            <TableCell>
                                <CustomButton EndIcon={editNo === row.id ? Save : Edit} size="small" label={editNo === row.id ? "Save" : "Edit"} onClick={() => enableEdit(row)} />
                                {/* <CustomButton EndIcon={Visibility} size="small" label="View" onClick={() => viewEntity(row, isVehicle)} /> */}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}