import { Typography } from "@mui/material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export const Unauthorized = () => {
    return (
        <div>
            <ErrorOutlineIcon color="error" sx={{ fontSize: "70px" }} />
            <Typography variant="h4">
                {`Sorry, You don't have access.`}
            </Typography>
        </div>);
}