import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { SelectOption } from '../../types/SelectOption';
import { Grid } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            maxHeight: 'calc(100vh - 200px)',
            width: 250,
        },
    },
};

function getStyles(id: number, selectedValues: readonly number[], theme: Theme) {
    return {
        fontWeight:
            selectedValues.includes(id) ? theme.typography.fontWeightBold
                : theme.typography.fontWeightLight,
    };
}

interface Props {
    name: string;
    error?: boolean;
    onChange: (key: string, value: number | string | number[]) => void;
    label: string;
    options: SelectOption[];
    value: number[] | undefined;
}
export const MultipleSelectChip: React.FC<Props> = ({ error, label, options, value = [], name, onChange }) => {
    const theme = useTheme();
    const [selectedValues, setSectedValues] = React.useState<number[]>(value);

    const itemMap: Record<number, SelectOption> = {};
    options.forEach(i => {
        itemMap[i.id] = i
    })

    const handleChange = (event: SelectChangeEvent<typeof selectedValues>) => {
        const {
            target: { value },
        } = event;

        // On autofill we get a stringified value.
        const val = typeof value === 'string' ? value.split(',').map(t => Number(t)) : value;
        setSectedValues(val);
        onChange(name, val);
    };

    return (
        <div style={{ width: '100%' }}>
            <InputLabel id="demo-multiple-chip-label">{label}</InputLabel>
            <FormControl variant="standard" sx={{ width: '100%' }}>
                {/* <InputLabel id="demo-multiple-chip-label">{label}</InputLabel> */}
                <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    error = {error}
                    value={selectedValues}
                    onChange={handleChange}
                    input={<OutlinedInput placeholder='asdasda' id="select-multiple-chip" />}
                    // input={<TextField id="standard-basic" label="Standard" variant="standard" />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value: number) => (
                                <Chip key={value} label={itemMap[value].label} />
                            ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    {options.map((i) => (

                        <MenuItem
                            key={i.id}
                            value={i.id}
                            style={getStyles(i.id, selectedValues, theme)}
                        >
                            <Grid display="grid" container gridTemplateColumns={'1fr 1fr'}>
                                {i.Icon && <Grid item>
                                    {i.Icon}
                                </Grid>}
                                <Grid item>
                                    {i.label}
                                </Grid>
                            </Grid>

                            {/* { <ListItemIcon>
                                 <Cloud fontSize="small" /> 
                                {i.Icon}
                            </ListItemIcon>
                            <ListItemText><Typography>{i.label}</Typography></ListItemText>  } */}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}
