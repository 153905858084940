import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { config } from "../../../config";
import { SearchParams } from "../../../enum/searchParams.enum";
import { useBreadcrumb } from "../../../hooks/useBreadcrumb";
import { useSearchParamsState } from "../../../hooks/useSearchParamsState";
import { useUser } from "../../../hooks/useUser";
import {
  useGetVehicleByIdQuery,
  useUpdateVehicleMutation,
} from "../../../state/api/vehicle.api";
import { Vehicle } from "../../../types/Vehicle";
import { parseEntityId } from "../../../util/helper";
import { ErrorComponent } from "../../error/error";
import { LoaderBackdrop } from "../../loader/loaderBackdrop.component";
import { Unauthorized } from "../../unauthorized/unauthorized.component";
import { VehicleCreate } from "../create/vehicleCreate";
import { FC } from "react";

export const VehicleUpdateContainer: FC<{
  title: string;
  subTitle: string;
}> = ({ title, subTitle }) => {
  const { vid } = useParams();
  const { user } = useUser();
  const { data: vehicle, isLoading: loadingVehicles } = useGetVehicleByIdQuery({ id: parseEntityId(vid) });
  const { searchParamValue: fromAddRecord } = useSearchParamsState<boolean>(SearchParams.fromAddRecord, false);
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const stepNumber = Number(searchParams.get("step"));
  const step = isNaN(stepNumber) ? 0 : stepNumber;

  useBreadcrumb([{
    label: 'Home',
    path: `/`
  },
  {
    label: 'Vehicle',
    path: `/vehicle/${vid}`
  }]);

  const [updateVehicle, { isLoading: loadingUpdate }] = useUpdateVehicleMutation();

  const handleUpdateVehicle = async (veh: Vehicle, imgs: Record<number, Blob | null>) => {
    const result: any = await updateVehicle({ veh, imgs });
    if (result.data) {
      if (fromAddRecord) {
        navigate(`/vehicle/${veh.id}/record/create`);
      } else {
        navigate(`/vehicle/${veh.id}`);
      }
    } else {
      console.log('Vehicle update failed');
    }
    // if (!result.error) {
    //   if (fromAddRecord) {
    //     navigate(`/vehicle/${veh.id}/record/create`);
    //   } else {
    //     navigate(`/vehicle/${veh.id}`);
    //   }
    // }
  };

  if (!user?.id) {
    return <ErrorComponent title="Error" text="User N/A" />;
  }

  if (loadingVehicles) {
    return <LoaderBackdrop />
  }

  if (!vehicle) {
    return <div>Vehicle Not Found</div>
  }

  if (user.id !== config.adminUserId && vehicle.UserId !== user.id) {
    return <Unauthorized />
  }

  return (
    <VehicleCreate
      activeStepId={step}
      initialState={vehicle}
      onSave={handleUpdateVehicle}
      loading={loadingUpdate}
      user={user}
      title={title}
      subTitle={subTitle}
    />
  )
};
