import { useFormState } from '../../../hooks/useFormState';
import { vehicleTypes, vehicleTypesWithAny } from '../../../selectOptions/vehicleTypes';
import { AutoInput, NumberInput, TextInput } from '../../shared';
import { SelectInput } from '../../shared/SelectInput';
import { CheckCircle, Cancel } from '@mui/icons-material';
import { CustomButton } from '../../shared/CustomButton';
import { FC, useMemo, useState } from 'react';
import { PARTS_FILTER_DEFAULT_VALUES, hiddenFilterParams } from '../../../util/constants';
import { getActiveFilters } from '../../../util/helper';
import { InputGrid } from '../../shared/InputGrid';
import { PartsFilterType } from '../../../types/filters/PartsFilterType';
import { TreeSelect } from '../../treeView/TreeView';
import { INode } from '../../../types/INode';
import { partsTypes } from '../../../selectOptions/partsTypes';
import { Chip, Dialog, DialogActions, DialogContent, FormGroup, Grid, Stack, Typography } from '@mui/material';
import { filterNodes, setSelectedNodes } from '../../../util/nodeHelper';
import { useBrands } from '../../../hooks/useBrands';
import { SelectOption } from '../../../types/SelectOption';
import { useModels } from '../../../hooks/useModels';
import { LabelHelper } from '../../../util/labelHelper';
import { useAreas } from '../../../hooks/useArea';
import { useUser } from '../../../hooks/useUser';

interface Props {
    open: boolean;
    enabled: boolean;
    loading: boolean;
    initialState: Partial<PartsFilterType>;
    onFilterChange: (props: PartsFilterType) => void;
    onClose: () => void;
}
export const PartsFilter: FC<Props> = ({ open, initialState, enabled, onFilterChange, onClose, loading }) => {
   
    const { allAreas, getCountry, getProvince, getAreas } = useAreas();
    const user = useUser();
    const brands = useBrands();
    const models = useModels();

    const {state, changeProperty} = useFormState<PartsFilterType>({
        ...PARTS_FILTER_DEFAULT_VALUES,
        ...initialState,
    });

    const filteredModels = useMemo(() => {
        return models.filter(t => t.meta?.brand === state.brand);
    }, [state.brand, models, brands]);

    const brandsWithAny: SelectOption[] = [  { id: -1, label: "Any" }, ...brands]
    const modelsWithAny: SelectOption[] = [  { id: -1, label: "Any" }, ...filteredModels];

    const [selectedVehicleGroup, setSelectedVehicleGroup] = useState(-1);
    const [partNodesArr, setPartNodesArr] = useState<INode[]>(partsTypes);

    const activeFilters = useMemo(() => {
        return getActiveFilters<PartsFilterType>(state, PARTS_FILTER_DEFAULT_VALUES);
    }, [state])

    const handleChange = (key: string, value: string | number) => {
        changeProperty(key, value);
    }

    const handleVehicleTypeChange = (key: string, value: string | number) => {
        const selectedVehicleType = vehicleTypes.find(type => type.id === value);
        if (selectedVehicleType) {
            setSelectedVehicleGroup(selectedVehicleType.group ?? 0);
        }
        changeProperty(key, value);
    }

    const handleCancel = () => {
        onClose();

    };

    const handleOk = () => {
        onFilterChange(state);
        onClose();
    };

    const handleDelteFilterChip = (key: keyof PartsFilterType) => {
        const newValue = changeProperty(key, PARTS_FILTER_DEFAULT_VALUES[key] ?? '');
        onFilterChange(newValue);
    }

    const handleCategorySeletionChange = (e: INode[], values: string[]) => {
        changeProperty('category', values[0]);
    }

    // /////////////////////////////////////////////////////////////////////
    let filteredPartTypesNew: INode[] = partNodesArr;
    // const selectedVehicleTypes = vehicleTypes.filter(t => partState.vehicleTypes.includes(t.id));
    if (state.vehicleType !== PARTS_FILTER_DEFAULT_VALUES.vehicleType) {
        filteredPartTypesNew = filterNodes(partNodesArr, [state.vehicleType], [selectedVehicleGroup]);
    }
    filteredPartTypesNew = setSelectedNodes(filteredPartTypesNew, [state.category]);
    // /////////////////////////////////////////////////////////////////////

    return (
        <>
            <Grid container display="flex" alignItems="center" justifyContent="end">
                {activeFilters.length > 0 && enabled && <Stack direction="row" flexWrap="wrap">
                    {activeFilters.filter(({key}) => !hiddenFilterParams.includes(key)).map(f => <Chip sx={{margin: '2px'}} key={f.key}
                     label={`${LabelHelper.displayFilterLabel(f.key, f.value, brands, models, allAreas, user.user.country, state.province, state.district)}`} onDelete={() => handleDelteFilterChip(f.key)} size="small" variant="outlined" />)}
                </Stack>}
            </Grid>

            <Dialog
                sx={{ '& .MuiDialog-paper': {} }}
                maxWidth="md"
                open={open}
            >
                <Typography variant="subtitle1" margin={1} marginLeft={3}><b>Filter Spare Parts</b></Typography>
                <DialogContent dividers>
                    <FormGroup>
                        <Grid container>

                            <InputGrid>
                                <SelectInput
                                    name="vehicleType"
                                    label="Vehicle Type"
                                    options={vehicleTypesWithAny}
                                    disabled={loading}
                                    // error={!Validators.vehicleType(partState.type)}
                                    onChange={handleVehicleTypeChange}
                                    value={state.vehicleType}
                                />
                            </InputGrid>

                            <InputGrid>
                                <AutoInput
                                    value={state.brand}
                                    // error={!Validators.brand(partState.brand)}
                                    name="brand"
                                    label="Manufacturer"
                                    options={brandsWithAny}
                                    disabled={loading}
                                    onChange={handleChange}
                                />
                            </InputGrid>

                            <InputGrid>
                                <AutoInput
                                    value={state.model}
                                    name="model"
                                    label="Model"
                                    options={modelsWithAny}
                                    disabled={loading}
                                    onChange={handleChange}
                                />
                            </InputGrid>

                            <InputGrid minWidth={12}>
                                <TreeSelect setNodesArr={setPartNodesArr} isSingleSelection={true} userNodeArr={filteredPartTypesNew} onSelectionChange={handleCategorySeletionChange} />
                            </InputGrid>

                            <InputGrid>
                                <NumberInput
                                    value={state.year}
                                    name="year"
                                    label="Year"
                                    isRequired
                                    disabled={loading}
                                    // error={!Validators.year(partState.yearMin)}
                                    onChange={changeProperty}
                                />
                            </InputGrid>
                            {/* <InputGrid>
                                <NumberInput
                                    value={partState.yearMax}
                                    name="yearMax"
                                    label="Year Max"
                                    isRequired
                                    disabled={loading}
                                    // error={!Validators.year(partState.yearMax)}
                                    onChange={changeProperty}
                                />
                            </InputGrid> */}

                            <InputGrid>
                                <NumberInput
                                    value={state.priceMin}
                                    name="priceMin"
                                    label="Min Price"
                                    // error={!Validators.odo(partState.odoMin)}
                                    disabled={loading}
                                    onChange={changeProperty}
                                />
                            </InputGrid>

                            <InputGrid>
                                <NumberInput
                                    value={state.priceMax}
                                    name="priceMax"
                                    label="Max Price"
                                    // error={!Validators.odo(partState.odoMax)}
                                    disabled={loading}
                                    onChange={changeProperty}
                                />
                            </InputGrid>

                            <InputGrid minWidth={4}>
                                <AutoInput
                                    name={'province'}
                                    value={state.province}
                                    label={'Select Province'}
                                    disabled={false}
                                    options={getCountry(state)?.provinceList ?? []}
                                    onChange={changeProperty}
                                />
                            </InputGrid>
                            <InputGrid minWidth={4}>
                                <AutoInput
                                    name={'district'}
                                    value={state.district}
                                    label={'Select District'}
                                    disabled={false}
                                    options={getProvince(state)?.districts ?? []}
                                    onChange={changeProperty}
                                />
                            </InputGrid>
                            <InputGrid minWidth={4}>
                                <AutoInput
                                    name={'area'}
                                    value={state.area}
                                    label={'Select Area'}
                                    disabled={false}
                                    options={getAreas(state) ?? []}
                                    onChange={changeProperty}
                                />
                            </InputGrid>
                        </Grid>
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <CustomButton EndIcon={Cancel} onClick={handleCancel} label="Cancel" />
                    <CustomButton EndIcon={CheckCircle} onClick={handleOk} label="Apply" />
                </DialogActions>
            </Dialog>

        </>
    );
}