import { Grid, Typography } from "@mui/material";
import { FC } from "react";
import { ErrorOutline, Refresh } from '@mui/icons-material';
import { CustomButton } from "../shared/CustomButton";
import { clearAllCookies } from "../../util/cacheManager";

interface Props {
    title: string;
    text: string
}
export const ErrorComponent: FC<Props> = ({ title, text }) => {

    const reload = () => {
        clearAllCookies();
        window.location.reload();
    }

    return <Grid padding={5}>
        <Typography variant="h1" align="center"><ErrorOutline color="error" fontSize="large" /></Typography>
        <Typography variant="h5" align="center">{title}</Typography>
        <Typography variant="subtitle1" color="gray" align="center">{text}</Typography>
        <Typography align="center">
            <CustomButton label="Reload" float="center" EndIcon={Refresh} onClick={reload} />
        </Typography>
    </Grid>
}