import { MonetizationOn } from "@mui/icons-material"
import { Chip, Grid, Tooltip } from "@mui/material"
import { FC } from "react"

export const PriceView: FC<{ price: number | undefined }> = ({ price }) => {

    return <Grid item>
        <Tooltip title={`Call the seller`}>
            <Chip label={`${price ?? 'Negotiable'}/=`} color="warning" variant="outlined" onDelete={() => console.log('')} deleteIcon={<MonetizationOn />} />
        </Tooltip>
    </Grid>;
}