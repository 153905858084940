import { useLocation } from "react-router-dom";
import { Commute, SettingsSuggest, GridGoldenratio, TravelExplore, CarRepair, DirectionsCarFilled } from "@mui/icons-material";
import { Page } from "../types/Page";
import { useEffect, useState } from "react";
import { useUser } from "./useUser";

export const moreMenu = 'My Shop';
export const useNavigationMenu = () => {

  const { user } = useUser();
  const location = useLocation();

  const pages: Page[] = [
    { label: "My Cars", path: "/vehicle/myOwn", Icon: <GridGoldenratio /> },
    { label: "Buy/Sell", path: `/vehicle/market`, Icon: <Commute /> },
    { label: "Parts", path: `/sparepart/market`, Icon:<SettingsSuggest /> },
    { label: "History", path: `/vehicle/search`, Icon:<TravelExplore /> },
  ];

  const salesPages: Page[] = [
    { label: "Vehicles", path: `/vehicle/mySales`, Icon: <DirectionsCarFilled /> },
    { label: "Spare Parts", path: `/sparepart/mySales`, Icon: <CarRepair /> },
  ];

  const [selectedPage, setSelectedPage] = useState<number>(-1);
  useEffect(() => {
    // console.log('Location changed', location);
    const i = pages.findIndex(p => p.path === location.pathname);
    setSelectedPage(i);
  }, [location]);
  
  return { user, pages, salesPages, selectedPage};
};
