import { INode } from "../types/INode";

export const partsTypes: INode[] = [
  {
    label: 'Engine/Power',
    value: 'mechanical',
    // specificVehicles: {
    //   condition: "DISABLED",
    //   identfier: "ID",
    //   items: [100, 101, 102],
    // },
    children: [
      {
        label: 'Engine',
        value: 'engine',
        children: [
          {
            label: 'Complete Engine',
            value: 'engineAll',
          },
          {
            label: 'Gasket',
            value: 'gasket',
          },
          {
            label: 'Spark Plugs',
            value: 'sparkPlug',
          },
          {
            label: 'Fuel Injectors',
            value: 'fuelInjector',
          },
          {
            label: 'Ignition Coils',
            value: 'ignitionCoil',
          },
          {
            label: 'Turbo / Super charger',
            value: 'turbo',
          },
          {
            label: 'Starter Motor',
            value: 'startMotor',
          },
          {
            label: 'Alternator',
            value: 'alternator',
          },
          {
            label: 'ECU Computer box',
            value: 'ecu',
          },
          {
            label: 'Timing Belt',
            value: 'tBelt',
          }
        ]
      },

      {
        label: 'Battery',
        value: 'battery',
        children: [
          {
            label: 'Hybrid Battery',
            value: 'hybridBattery',
          },
          {
            label: 'Normal Battery',
            value: 'normalBattery',
          },
          {
            label: 'Electric Battery',
            value: 'electricBattery',
          }
        ]
      },
      {
        label: 'Engine Cooling',
        value: 'engineCooling',
        children: [
          {
            label: 'Coolant Liquid',
            value: 'coolantLiquid',
          },
          {
            label: 'Radiator',
            value: 'radiator',
          },
          {
            label: 'Water Pump',
            value: 'waterPump',
          },
        ]
      },

      {
        label: 'Air Conditioner',
        value: 'ac',
        children: [
          {
            label: 'Cooler/Evaporator',
            value: 'acCooler',
          },
          {
            label: 'Expansion Valve',
            value: 'acExpansionValve',
          },
          {
            label: 'Condensor',
            value: 'acCondensor',
          },
          {
            label: 'Compressor',
            value: 'acCompressor',
          },
          {
            label: 'Control Switches',
            value: 'acControlSwitches',
          },
          {
            label: 'Filter',
            value: 'acFilter',
          },
          {
            label: 'Thermostat Sensor',
            value: 'acThermostatSensor',
          },
        ]
      },

      {
        label: 'Filters',
        value: 'filter',
        children: [
          {
            label: 'Oil Filter',
            value: 'oilFilter',
          },
          {
            label: 'Air Filter',
            value: 'airFilter',
          },
          {
            label: 'Fuel Filter',
            value: 'fuelFilter',
          }
        ]
      },

      {
        label: 'Transmission',
        value: 'transmission',
        children: [
          {
            // take this to top level
            label: 'Automatic Gear',
            value: 'autoGear',
            children: [
              {
                label: 'Auto Gear Fluid',
                value: 'autoGearBoxFluid'
              },
              {
                label: 'Auto GearBox',
                value: 'autoGearBox'
              },
            ]
          },
          {
            label: 'Manual Gear',
            value: 'manualGear',
            children: [
              {
                label: 'Manual Gear Fluid',
                value: 'manualGearBoxFluid'
              },
              {
                label: 'Manual GearBox',
                value: 'manualGearBox'
              },
            ]
          },
          {
            label: 'Clutch Pump',
            value: 'clutchPump',
          },
          {
            label: 'Clutch Plate',
            value: 'clutchPlate',
          },
          {
            label: 'Pressure Plate',
            value: 'pressurePlate',
          },
          {
            label: 'CV Joints',
            value: 'cvJoints',
            children: [
              {
                label: 'CV Joint',
                value: 'cvJoint'
              },
              {
                label: 'Boots',
                value: 'cvJointBoot'
              }
            ]
          },
          {
            label: 'Differential',
            value: 'differential',
          },

        ]
      },

  

      {
        label: 'Breaks',
        value: 'breaks',
        children: [
          {
            label: 'Break Pads/Liners',
            value: 'breakPads',
            children: [
              {
                label: 'Break Pads - Front',
                value: 'breakPadsFront',
              },
              {
                label: 'Break Pads - Rear',
                value: 'breakPadsRear',
              }
            ]
          },
          {
            label: 'Disks / Drums',
            value: 'disk',
          },
          {
            label: 'Booster Pump',
            value: 'breakBoosterPump',
          },
          {
            label: 'Cylinders',
            value: 'breakCylinder',
          },
          {
            label: 'Fluid',
            value: 'breakFluid',
          },
        ]
      },


      {
        label: 'Suspension',
        value: 'suspension',
        children: [
          {
            label: 'Ball Joints',
            value: 'ballJoint'
          },
          {
            label: 'Lower / Upper arms',
            value: 'lowerUpperArm'
          },
          {
            label: 'Shock Absorber',
            value: 'shockAbsorber'
          },
          {
            label: 'Steering Rack / Box',
            value: 'steeringRack'
          },
          {
            label: 'Coil Springs / Spring leaves',
            value: 'spring'
          },

        ]
      },

      {
        label: 'Tyres and Wheels',
        value: 'wheels',
        children: [
          {
            label: 'Tyres',
            value: 'tyre'
          },
          {
            label: 'Wheels',
            value: 'wheel'
          },
          {
            label: 'Rim Cups',
            value: 'rimcup'
          },
          {
            label: 'Bolts',
            value: 'bolt'
          },
        ]
      },

      {
        label: 'Hoses(Fuel, Air, Oil, Water)',
        value: 'hose',

        children: [
          {
            label: 'Fuel Hose',
            value: 'fuelHose',
          },
          {
            label: 'Air Cleaner Hose',
            value: 'airHose',
          },
          {
            label: 'Oil Hose',
            value: 'oilHose',
          },
          {
            label: 'Water Hose',
            value: 'waterHose',
          },
        ]
      }
    ]
  },
  {
    label: 'Body Parts',
    value: 'exterior',

    children: [{
      label: 'Front',
      value: 'front',
      children: [
        {
          label: 'Front Doors',
          value: 'frontDoor'
        },
        {
          label: 'Lights',
          value: 'frontLights',
          children: [
            {
              label: 'Head Lights',
              value: 'headLight',
            },
            {
              label: 'Fog Lights',
              value: 'fogLight',
            },
            {
              label: 'Signal Lights',
              value: 'signalLight',
            },
            {
              label: 'Parking Lights',
              value: 'parkingLight',
            },
            {
              label: 'Daytime Running Lights',
              value: 'daytimeLight',
            },
          ]
        },
        {
          label: 'Bumpers',
          value: 'frontBumper'
        },
        {
          label: 'Bonnet / Engine hood',
          value: 'bonnet',
        },
        {
          label: 'Body Cut Panels',
          value: 'frontBodyPanel',
        },
        {
          label: 'Windshield',
          value: 'frontWindsheild',
        },
        {
          label: 'Front Grill',
          value: 'frontGrill',
        },
      ]
    },
    {
      label: 'Rear / Back',
      value: 'rear',
      children: [
        {
          label: 'Rear Doors',
          value: 'rearDoor'
        },
        {
          label: 'Lights',
          value: 'rearLights',
          children: [
            {
              label: 'Signal Lights',
              value: 'rearSignalLight',
            },
            {
              label: 'Tail Lights',
              value: 'tailLight',
            },
          ]
        }
        ,
        {
          label: 'Bumpers',
          value: 'rearBumper',
        },
        {
          label: 'Spoiler',
          value: 'rearSpoiler',
        },
        {
          label: 'Dicky / Trunk',
          value: 'dicky',
        },
        {
          label: 'Body Cut Panels',
          value: 'rearBodyPanel',
        },
        {
          label: 'Windshield',
          value: 'rearWindsheild',
        },
      ]
    },
    {
      label: 'Top',
      value: 'top',
      children: [
        {
          label: 'Roof Panel',
          value: 'roofPanel',
        },
        {
          label: 'Sun Roof / Moon Roof',
          value: 'sunRoof',
        },
      ]
    },
    {
      label: 'Sides(Left/Right)',
      value: 'side',
      children: [
        {
          label: 'Doors',
          value: 'doors',
          children: [
            {
              label: 'Door',
              value: 'door',
            },
            {
              label: 'Door Visors',
              value: 'doorVisor',
            },
            {
              label: 'Door Handles',
              value: 'doorHandle',
            },
          ]
        },
        {
          label: 'Fenders',
          value: 'fender',
        },
        {
          label: 'Mud Flaps',
          value: 'mudFlap',
        },
        {
          label: 'Bodykits',
          value: 'bodykit',
        },
        {
          label: 'Mirrors',
          value: 'mirror',
        },
        {
          label: 'Body Cut Panels',
          value: 'sideBodyPanel',
        },

      ]
    },
    ]
  },
  {
    label: 'Interior Parts',
    value: 'interior',
    specificVehicles: {
      condition: "DISABLED",
      identfier: "ID",
      items: [100, 101, 102],
    },
    children: [
      {
        label: 'Audio / Entertainment',
        value: 'audio',
        children: [
          {
            label: 'Player',
            value: 'player'
          },
          {
            label: 'TV / Screen / Display',
            value: 'display'
          },
          {
            label: 'Speakers',
            value: 'speaker'
          },
          {
            label: 'Subwoofer',
            value: 'subwoofer'
          },
        ]
      },
      {
        label: 'Dashboard',
        value: 'dashboard'
      },
      {
        label: 'Meter Cluster',
        value: 'meterCluster'
      },
      {
        label: 'Steering Column',
        value: 'steeringColumn'
      },
      {
        label: 'Seats',
        value: 'seat'
      },
      {
        label: 'Seat Covers',
        value: 'seatCover'
      },
      {
        label: 'Grab Handles',
        value: 'grabHandls'
      },
      {
        label: 'Sun Visors',
        value: 'sunVisor'
      },
    ]
  }
];



const map: Record<string, boolean> = {};
const iterateChildren = (node: INode) => {

  if(map[node.value]){
    alert('Node ID Error');
    console.error('Node ID Error', node, map);
  } else {
    map[node.value] = true;
    if(node.children){
      node.children.forEach(c => {
        iterateChildren(c)
      })
    }
  }
}

partsTypes.forEach(part => {
  iterateChildren(part);
  console.warn('Part Types checked')
})