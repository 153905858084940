import { Avatar, Box, Divider, Drawer, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { FC } from 'react';
import { useNavigationMenu } from '../../hooks/useNavigationMenu';
import { Image } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';


export const SidePanel: FC<{ showPanel: boolean; onVisibilityChange: (isVisible: boolean) => void }> = ({ showPanel, onVisibilityChange }) => {

  const { salesPages } = useNavigationMenu();
  const navigate = useNavigate();

  const toggleDrawer = (open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      onVisibilityChange(open);
    };

  const handleClickItem = (path: string) => {
    navigate(path);
  }

  return <>
    <Drawer
      anchor={'right'}
      open={showPanel}
      onClose={() => onVisibilityChange(false)}
    >
      <Box
        sx={{ width: 250 }}
        padding={1}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >

        <Divider >  <Typography variant='caption' margin={1}>Listings Added By You</Typography> </Divider>


        <List>

          {salesPages.map(({ label, path, Icon }, index) => (
            <div key={path}>
              {index === 0 && <Divider />}
              <ListItem disablePadding onClick={() => handleClickItem(path)} sx={{margin: '5px', cursor: 'pointer'}}>
                <ListItemAvatar>
                  <Avatar>
                    {Icon}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={label} />
              </ListItem>
              <Divider />
            </div>

          ))}
        </List>
      </Box>
    </Drawer>
  </>
}