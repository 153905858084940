import { Button } from "@mui/material";
import { FC, useState } from "react"
import { useFormState } from "../../../hooks/useFormState";
import { LabelHelper } from "../../../util/labelHelper";
import { useBrands } from "../../../hooks/useBrands";
import { useModels } from "../../../hooks/useModels";
import { TextInput } from "../../shared";

export const EntityEditor: FC<{
    entity: any;
    editableProps: string[];
    nonEditableProps: string[]
    onSave: (entity: any) => void;
}> = ({ entity, editableProps, nonEditableProps, onSave }) => {

    const {state, changeProperty} = useFormState(entity);
    const [enable, setEnable] = useState(false);
    const properties = [...nonEditableProps, ...editableProps];
    const brands = useBrands();
    const models = useModels();
    // const { allAreas } = useAreas();

    const handleClick = () => {
        if (enable) {
            setEnable(false);
            onSave(state);
        } else {
            setEnable(true);
        }
    }

    return <>
        {
            properties.map(key => {
                if (nonEditableProps.includes(key)) {
                    if (key === 'brand') {
                        return <td key={key}>{LabelHelper.getBrandLabelEntity(entity, brands)}</td>
                    } else if (key === 'model') {
                        return <td key={key}>{LabelHelper.getModelLabelEntity(entity, models)}</td>
                    } else if (key === 'country') {
                        return <td key={key}>{LabelHelper.getCountryLabel(entity.country)}</td>
                    } else if (key === 'province') {
                        return <td key={key}>{LabelHelper.getProvinceLabel(entity.country, entity.province)}</td>
                    } else if (key === 'district') {
                        return <td key={key}>{LabelHelper.getDistrictLabel(entity.country, entity.province, entity.district)}</td>
                    } else {
                        return <td key={key}>{entity[key] as string}</td>
                    }
                } else if (editableProps.includes(key)) {
                    return <td key={key}>
                        <TextInput name={key} value={state[key]} label={key} disabled={!enable} onChange={changeProperty} />
                    </td>
                } else {
                    return null;
                }
            })
        }
        <td><Button onClick={handleClick}>{enable ? 'Save' : 'Edit'}</Button></td>
    </>
}