import { TextField } from "@mui/material";
import { FC } from "react";
import { config } from "../../config";

interface Props {
  name: string;
  value: number | undefined;
  label: string;
  disabled: boolean;
  isRequired?: boolean;
  error?: boolean;
  onChange: (key: string, value: number | string) => void;
  onEnter?: () => void;
}
export const NumberInput: FC<Props> = ({
  name,
  value = '',
  label,
  error,
  disabled,
  isRequired,
  onChange,
  onEnter
}) => {

  const handleKeyDown = (e: any) => {
    if(onEnter) {
      if(e.key === 'Enter') {
        onEnter();
      }
    }
  }

  return (
    <TextField
      value={value === undefined ? '' : value}
      type="number"
      required = {isRequired}
      fullWidth
      label={label}
      autoComplete="off"
      error={error}
      disabled={disabled}
      variant="standard"
      // sx={{ marginTop: 3, height: config.inputHeight, minWidth: config.inputMinWidth}}
      onChange={(e) => onChange(name, e.target.value)}
      onKeyDown={handleKeyDown}
    />
  );
};
