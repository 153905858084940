import { Dialog, DialogContent, FormControlLabel, Checkbox, DialogActions, Button, FormGroup, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { serviceTypes } from "../../../selectOptions/serviceTypes";
import { RecordsFilterType } from "../../../types/filters/RecordsFilterType";

export interface ConfirmationDialogRawProps {
    id: string;
    keepMounted: boolean;
    filter: RecordsFilterType;
    open: boolean;
    onClose: (filter: RecordsFilterType) => void;
}

export const RecordFilterSelector = (props: ConfirmationDialogRawProps) => {
    const { onClose, filter, open, ...other } = props;
    const radioGroupRef = useRef<HTMLElement>(null);
    const [selectedOptions, setSelectedOptions] = useState<Record<number, boolean>>(generateMap(filter.categories));

    const handleChange = (id: number) => {
        const selectedItem = selectedOptions[id];
        setSelectedOptions((prev) => {
            const next = { ...prev };
            next[id] = !selectedItem;
            return next;
        });
    }

    const handleEntering = () => {
        if (radioGroupRef.current != null) {
            radioGroupRef.current.focus();
        }
    };

    const handleCancel = () => {
        onClose(filter);
    };

    const handleOk = () => {
        const values = Object.entries(selectedOptions).filter(e => e[1]).map((e) => Number(e[0]));
        const newFilter = {...filter};
        newFilter.categories = values;
        
        onClose(newFilter);
    };

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
            maxWidth="xs"
            TransitionProps={{ onEntering: handleEntering }}
            open={open}
            {...other}
        >
            <Typography variant="h5" margin={1} marginLeft={3}>Filter service records</Typography>
            <DialogContent dividers>
                <FormGroup>
                    {serviceTypes.map((option) => (
                        <FormControlLabel
                            value={option}
                            key={option.label}
                            label={option.label}
                            control={
                                <Checkbox checked={selectedOptions[option.id] ?? false} onChange={() => handleChange(option.id)} name="gilad" />
                            }
                        />
                    ))}
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel}>
                    Cancel
                </Button>
                <Button onClick={handleOk}>OK</Button>
            </DialogActions>
        </Dialog>
    );
}

const generateMap = (values: number[]) => {
    const map: Record<number, boolean> = {};
    values.forEach(v => {
        map[v] = true;
    })
    return map;
}