import { PhoneEnabled } from "@mui/icons-material"
import { Chip, Grid, Tooltip } from "@mui/material"
import { FC } from "react"

export const PhoneDial: FC<{phone: string | undefined}> = ({phone}) => {
    return <Grid item>
      <Tooltip title={`Call the seller`}>
        <a href={`tel:${phone}`} style={{textDecoration: "none", cursor: 'pointer'}}>
          <Chip label={phone && phone !== '' ? phone : 'N/A'} color="primary" variant="outlined" onDelete={() => console.log('')} deleteIcon={<PhoneEnabled />} />
        </a>
      </Tooltip>
  </Grid>;
}