import { Grid, List, ListItem, ListItemButton, ListItemText, TextField } from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";
import { config } from "../../config";
import { SelectOption } from "../../types/SelectOption";

export interface TextInputProps {
  name: string;

  suggestions?: {
    initDispVal: string;
    options: SelectOption[];
  },

  value: string | number | undefined;
  label: string | undefined;
  align?: 'left' | 'center' | 'right'
  paddingRight?: number;
  error?: boolean;
  isRequired?: boolean;
  type?: string;
  multiline?: boolean;
  disabled?: boolean;

  onChange: (key: string, value: number | string, fromSuggestions?: boolean) => void;
  onEnter?: () => void;
}
export const TextInput: FC<TextInputProps> = ({
  name,
  suggestions,
  value = '',
  error,
  label,
  isRequired,
  onChange,
  disabled,
  multiline,
  type,
  paddingRight = 0,
  align = 'left',
  onEnter
}) => {

  const [showSuggestions, setShowSuggestions] = useState(false);
  const [disVal, setDisVal] = useState('');
  const [placeHolder, setPlaceHolder] = useState(label);

  const [currentSuggestions, setCurrentSuggestions] = useState<SelectOption[] | undefined>([]);

  useEffect(() => {
    updateState(value, false);
  }, [value]);

  useEffect(() => {
    setCurrentSuggestions(suggestions?.options);
    if (JSON.stringify(currentSuggestions) !== JSON.stringify(suggestions?.options)) {
      updateState(value, true);
    }
  }, [suggestions?.options]);

  const updateState = (currentValue: string | number | undefined, informParent: boolean) => {

    if (suggestions) {
      if (suggestions.options) {
        const valFromOptions = suggestions.options.find(o => {
          const numVal = Number(currentValue);
          if (isNaN(numVal)) {
            return o.label.toLowerCase() === `${currentValue}`.toLowerCase();
          } else {
            return o.id === numVal;
          }
        });
        if (valFromOptions) {
          setDisVal(valFromOptions.label);
          setPlaceHolder(label);
          if (informParent) {
            onChange(name, valFromOptions.id, true);
          }
        } else {
          if (suggestions.initDispVal) {
            setDisVal(suggestions.initDispVal);
          } else {
            setPlaceHolder(`${label}(New)`);
          }
          // setDisVal(Number(suggestions.initDispVal) === -1 ? '' : suggestions.initDispVal);

          if (informParent) {
            const val = currentValue === -1 ? suggestions.initDispVal : '';
            // const val = currentValue === -1 ? '' : (currentValue ?? '');
            onChange(name, val, false);
          }
        }
      } else {
        setDisVal(currentValue === -1 ? '' : `${currentValue}`);
        if (informParent) {
          onChange(name, currentValue ?? '', false);
        }
      }
    } else {
      setDisVal(`${currentValue}`);
      if (informParent) {
        onChange(name, currentValue ?? '', false);
      }
    }
  }

  const handleKeyUp = (e: any) => {
    if (onEnter) {
      if (e.key === 'Enter') {
        onEnter();
      }
    } else {
      processInput(e.target.value);
    }
  }

  const processInput = (inputVal: string) => {
    const valFromOptions = suggestions?.options.find(o => o.label.toLowerCase() === inputVal.toLowerCase());
    if (valFromOptions) {
      onChange(name, valFromOptions.id, true);
      setDisVal(valFromOptions.label);
      setPlaceHolder(label);
    } else {
      onChange(name, inputVal);
      setDisVal(inputVal);
      setPlaceHolder(`${label} (New)`);
    }

    if (filteredSuggestions?.length !== 0) {
      setShowSuggestions(true);
    }
  }

  const handleFocusChange = (showSuggestions: boolean, e: any) => {
    setTimeout(() => {
      setShowSuggestions(showSuggestions);
    }, 500);
    processInput(e.target.value)
  }

  const handleSelectSuggestion = (key: string, val: string | number, label: string) => {
    setDisVal(label);
    setPlaceHolder(label);
    onChange(name, val, true);
    // suggestions?.onSelect?.(key, val);
  }

  const handleInputChange = (key: string, val: string) => {
    setDisVal(val);
    processInput(val)
  }

  const filteredSuggestions = useMemo(() => {
    const filt = suggestions?.options.filter(opt => opt.label.toLowerCase().includes(`${disVal}`.toLowerCase())).slice(0, 10);
    return filt;
  }, [suggestions?.options, disVal]);

  return (
    <Grid>
      <TextField
        onFocus={(e) => handleFocusChange(true, e)}
        onBlur={(e) => handleFocusChange(false, e)}
        value={disVal}
        onChange={(e) => handleInputChange(name, e.target.value)}
        onKeyUp={handleKeyUp}
        required={isRequired}
        fullWidth
        type={type}
        multiline={multiline}
        rows={multiline ? 4 : 1}
        label={placeHolder}
        autoComplete="off"
        error={error}
        disabled={disabled}
        variant="standard"
        InputProps={{
          sx: {
            "& input": {
              textAlign: align
            },
            paddingRight
          },
        }}
        inputProps={{ maxLength: multiline ? config.multilineTextLimit : config.inputTextLimit }}
      />
      {showSuggestions &&
        <List sx={{
          maxHeight: '20vh', overflow: 'auto',
          background: 'white'
        }}>
          {
            filteredSuggestions?.map(item => <ListItem key={item.id} disablePadding>
              <ListItemButton onClick={() => handleSelectSuggestion(name, item.id, item.label)}>
                <ListItemText primary={item.label} />
              </ListItemButton>
            </ListItem>)
          }
        </List>}
    </Grid>
  );
};
