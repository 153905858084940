import { Grid, Typography } from "@mui/material"
import { Demo } from "../demo/demo"

export const Welcome = () => {
    return <>
        <Grid container >
            <Grid item xs={12} margin={1} justifyContent="center">
                <Typography variant="subtitle1" align="center">Everything About Your Vehicle</Typography>
                <Typography variant="h2" align="center">
                    <b>
                        <i>OdoCert</i>
                    </b>
                </Typography>
                <Demo />
            </Grid>
        </Grid>
    </>
}