import { LocalStorageKeys } from "../enum/localStorageKeys.enum";
import { useGetLoggedInUserQuery } from "../state/api/user.api";
import { User } from "../types/User";
import { getLocalStorage } from "../util/localData";

export const useUser = () => {

  const forcedUser = getLocalStorage(LocalStorageKeys.forcedLoggedInUser, null);
  if(forcedUser){
    const userObj = JSON.parse(forcedUser);
    return { user: userObj as User, isForced: true };
  }

  const { data } = useGetLoggedInUserQuery({cached: true});
  const user: User = data as User;
  return { user, isForced: false };
};
