import { Chip, Grid } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { GetApp, Close } from '@mui/icons-material';
import { config } from "../../config";

export const PWA = () => {
    const [show, setShow] = useState(false);
    const deferredPrompt = useRef<any>();

    const init = () => {
        if ('serviceWorker' in navigator) {
            // window.addEventListener('load', function () {
            navigator.serviceWorker.register('/sw.js').then(function (registration) {
                // Registration was successful
                console.log('ServiceWorker registration successful with scope: ', registration.scope);
            }, function (err) {
                // registration failed :(
                console.log('ServiceWorker registration failed: ', err);
            });
            // });
        }


        //   var div = document.querySelector('.add-to');
        //   var button = document.querySelector('.add-to-btn');
        //   div.style.display = 'none';

        console.log('beforeinstallprompt');
        window.addEventListener('beforeinstallprompt', (e) => {
            // Prevent Chrome 67 and earlier from automatically showing the prompt
            e.preventDefault();
            // Stash the event so it can be triggered later.
            deferredPrompt.current = e;
            // div.style.display = 'block';

            setTimeout(() => {
                setShow(true);
            }, config.pwaNotificationDelay)
            
        });

        self.addEventListener('fetch', function (event) { });

    }

    const handleInstall = () => {
        // hide our user interface that shows our A2HS button
        //  div.style.display = 'none';
        setShow(false);
        // Show the prompt
        deferredPrompt.current.prompt();
        // Wait for the user to respond to the prompt
        deferredPrompt.current.userChoice
            .then((choiceResult: any) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the A2HS prompt');
                } else {
                    console.log('User dismissed the A2HS prompt');
                }
                deferredPrompt.current = null;
            });
    }

    const handleClose = () => {
        setShow(false);
    }

    useEffect(() => {
        init();
    }, [])

    return <>
        {show && <Grid  container style={{ background: '#84317d', width: '100vw' }} padding={1} justifyContent="end">

        <Chip sx={{ color: 'white', marginRight: '10px' }}
                label="Dismiss"
                onClick={handleClose}
                onDelete={handleClose}
                deleteIcon={<Close style={{color: 'white'}} />}
                variant="outlined"
            />
            <Chip sx={{ color: 'white', marginRight: '20px' }}
                label="Install App"
                onClick={handleInstall}
                onDelete={handleInstall}
                deleteIcon={<GetApp style={{color: 'white'}} />}
                variant="outlined"
            />
            
            
            </Grid>}
    </>
}

