import { SearchParams } from "../../../enum/searchParams.enum";
import { FC } from "react";
import { VehicleCreateContainer } from "./vehicleCreate.container";
import { useSearchParamsState } from "../../../hooks/useSearchParamsState";
import { ListingType } from "../../../enum/vehicleListing.enum";
export const VehicleCreateRoute: FC<{
  title: string;
  subTitle: string;
}> = ({title, subTitle}) => {

  const { searchParamValue: vehicleListing } = useSearchParamsState<ListingType>(SearchParams.vehicleListing, ListingType.ListingForSale);
  // this is needed when change the filters we have to reset all the hooks and states to change params like offset and other stuff
  const key = `${vehicleListing}`;
  
  return <VehicleCreateContainer title={title} subTitle={subTitle} key={key} vehicleListingFor={Number(vehicleListing)} />;
};