export enum LocalStorageKeys {
    forcedLoggedInUser = 'forcedLoggedInUser',

    hasAccount = 'hasAccount',
    country = 'country',

    favoritesSelectedTab = 'favoritesSelectedTab',
    adminSelectedTab = 'adminSelectedTab',

    // intro related keys
    introHomeShownCount = 'introHomeShownCount:',
    introRecordShownCount = 'introRecordShownCount:',

    // Store
    lastNavigation = 'lastNavigation',

    // Filter
    mySalesPartsFilter = 'mySalesPartsFilter',
    marketPartsFilter = 'marketPartsFilter',

    mySalesVehiclesFilter = 'mySalesVehiclesFilter',
    myAccountVehiclesFilter = 'myAccountVehiclesFilter',
    marketVehiclesFilter = 'marketVehiclesFilter',

    shopVehiclesFilter = 'shopVehiclesFilter',
    shopPartsFilter = 'shopPartsFilter',
}