import { useUser } from "../../hooks/useUser";
import { toggleSidePanel } from "../../state/slice/appSlice";
import { useAppDispatch } from "../../state/store";
import { Home } from "./home";

export const HomeContainer = () => {
    const { user } = useUser();
    const dispatch = useAppDispatch();
    
    // const showSidePanel = useAppSelector((state) => state.app.showSidePanel);

    const handleToggleSidePanel = (visibility: boolean) => {
        dispatch(
          toggleSidePanel(visibility)
        );
      }

    return <>
        <Home user={user} handleToggleSidePanel={handleToggleSidePanel}/>
    </>
}