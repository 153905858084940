import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { RootState, useAppDispatch, useAppSelector } from '../../state/store';
import { updateConfirmBox } from '../../state/slice/appSlice';
import { Typography } from '@mui/material';

export const ConfirmBox: React.FC = () => {

    const { isOpen, header, text, result, inputType, notes } = useAppSelector((state: RootState) => state.app.confirmBox);
    const dispatch = useAppDispatch()


    const handleOk = () => {
        dispatch(updateConfirmBox({
            result: {
                confirmed: true,
                data: null
            }
        }));
    };

    const handleCancel = () => {
        dispatch(updateConfirmBox({
            result: {
                confirmed: false,
                data: null
            }
        }));
    };

    return (
        <div>
            <Dialog open={isOpen} onClose={handleCancel}>
                <DialogTitle>{header}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {text}
                    </DialogContentText>
                    <Typography variant='caption'>
                        {notes}
                    </Typography>
                    {/* {inputType === 'string' && <TextField
                        autoFocus
                        margin="dense"
                        id="input"
                        label=""
                        type="text"
                        fullWidth
                        variant="standard"
                    />} */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button onClick={handleOk}>OK</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}