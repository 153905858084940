import Dialog from '@mui/material/Dialog';
import { FC, useState } from 'react';
import { ServiceRecord } from '../../types/ServiceRecord';
import { LineChart } from './lineChart';
import { ServiceType } from '../../selectOptions/serviceTypes';
import { SelectInput } from '../shared/SelectInput';
import { SelectOption } from '../../types/SelectOption';
import { Grid } from '@mui/material';

interface Props {
    isLoading: boolean;
    open: boolean;
    onClose: () => void;
    data: ServiceRecord[];
    onRecordTypesChange: (types: ServiceType[]) => void;
}

export const ChartDialog: FC<Props> = ({ open, onClose, data, isLoading, onRecordTypesChange }) => {
    const [selectedServiceType, setSelectedServiceType] = useState(-1);
    const serviceTypeOptions: SelectOption[] = [
        {
            id: ServiceType.FuelTopUp,
            label: 'Fuel Records'
        },
        {
            id: -1,
            label: 'All Services'
        }
    ]
    if (isLoading) {
        return <div>Loading...</div>
    }

    const handleRecordTypeChange = (key: string, value: string | number) => {
        const selectedTypeNum = Number(value);

        if (!isNaN(selectedTypeNum)) {
            setSelectedServiceType(selectedTypeNum)
            onRecordTypesChange(value === ServiceType.FuelTopUp ? [ServiceType.FuelTopUp] : []);
        }
    }

    return (
        <Dialog fullWidth maxWidth='lg' onClose={onClose} open={open} sx={{ overflow: 'hidden' }}>
            <Grid container>
                <Grid item width='100%'>
                    <Grid container justifyContent="end">
                        <Grid item xs={6} sm={4} marginRight={4} marginTop={1}>
                            <SelectInput
                                name="display"
                                label="Display"
                                // variant='outlined'
                                size='small'
                                options={serviceTypeOptions}
                                disabled={false}
                                onChange={handleRecordTypeChange}
                                value={selectedServiceType}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item width='100%'>
                    <LineChart data={data} />
                </Grid>
            </Grid>
        </Dialog>
    );
}
