export const DEFAULT_SELECTED_MANUFACTURER = -1;

// export const brands2: SelectOption[] = [
//   // { id: -1, label: "Other" },
//   { id: 1, label: "SNVI" },
//   { id: 2, label: "Zanella" },
//   { id: 3, label: "Koller" },
//   { id: 4, label: "Anasagasti" },
//   { id: 5, label: "AutoLatina" },
//   { id: 6, label: "Autoar" },
//   { id: 7, label: "Crespi" },
//   { id: 8, label: "Eniak" },
//   { id: 9, label: "Hispano-Argentina" },
//   { id: 10, label: "IAVA" },
//   { id: 11, label: "IAME" },
//   { id: 12, label: "IKA" },
//   { id: 13, label: "Sevel Argentina" },
//   { id: 14, label: "SIAM Di Tella" },
//   { id: 15, label: "ErAZ" },
//   { id: 16, label: "ACE EV Group" },
//   { id: 17, label: "Borland Racing Developments" },
//   { id: 18, label: "Bolwell" },
//   { id: 19, label: "Brabham Automotive" },
//   { id: 20, label: "Ford Australia" },
//   { id: 21, label: "General Motors Special Vehicles (GMSV)" },
//   { id: 22, label: "Elfin Sports Cars" },
//   { id: 23, label: "Ford Performance Vehicles (FPV)" },
//   { id: 24, label: "Giocattolo" },
//   { id: 25, label: "Holden" },
//   { id: 26, label: "KTM" },
//   { id: 27, label: "Magna Steyr" },
//   { id: 28, label: "Puch" },
//   { id: 29, label: "Tushek &amp; Spigel" },
//   { id: 30, label: "Austro-Daimler" },
//   { id: 31, label: "Austro-Tatra" },
//   { id: 32, label: "Denzel" },
//   { id: 33, label: "Felber" },
//   { id: 34, label: "Libelle" },
//   { id: 35, label: "Lohner–Porsche" },
//   { id: 36, label: "Möve" },
//   { id: 37, label: "ÖAF" },
//   { id: 38, label: "Steyr" },
//   { id: 39, label: "Steyr-Daimler-Puch" },
//   { id: 40, label: "AzSamand" },
//   { id: 41, label: "Ganja Auto Plant" },
//   { id: 42, label: "Nakhchivan Automobile Plant" },
//   { id: 43, label: "BMTF" },
//   { id: 44, label: "BelAZ" },
//   { id: 45, label: "BelGee" },
//   { id: 46, label: "MAZ" },
//   { id: 47, label: "MoAZ" },
//   { id: 48, label: "MZKT" },
//   { id: 49, label: "Neman" },
//   { id: 50, label: "Gillet" },
//   { id: 51, label: "Van Hool" },
//   { id: 52, label: "Auto-Mixte" },
//   { id: 53, label: "FN" },
//   { id: 54, label: "Imperia" },
//   { id: 55, label: "Métallurgique" },
//   { id: 56, label: "Minerva" },
//   { id: 57, label: "Nagant" },
//   { id: 58, label: "Pieper" },
//   { id: 59, label: "Agrale" },
//   { id: 60, label: "Comil" },
//   { id: 61, label: "Marcopolo" },
//   { id: 62, label: "Mascarello" },
//   { id: 63, label: "Neobus" },
//   { id: 64, label: "TAC" },
//   { id: 65, label: "Brasinca" },
//   { id: 66, label: "Busscar" },
//   { id: 67, label: "Dacon" },
//   { id: 68, label: "Engesa" },
//   { id: 69, label: "FNM" },
//   { id: 70, label: "Farus" },
//   { id: 71, label: "Gurgel" },
//   { id: 72, label: "HTT" },
//   { id: 73, label: "Hofstetter" },
//   { id: 74, label: "JPX" },
//   { id: 75, label: "Lobini" },
//   { id: 76, label: "Obvio!" },
//   { id: 77, label: "Puma" },
//   { id: 78, label: "Romi" },
//   { id: 79, label: "Santa Matilde" },
//   { id: 80, label: "Troller" },
//   { id: 81, label: "Vemag" },
//   { id: 82, label: "Litex" },
//   { id: 83, label: "SIN" },
//   { id: 84, label: "Bulgaralpine" },
//   { id: 85, label: "Bulgarrenault" },
//   { id: 86, label: "Moskvych" },
//   { id: 87, label: "Pirin-Fiat" },
//   { id: 88, label: "Sofia" },
//   { id: 89, label: "Bombardier" },
//   { id: 90, label: "Campagna" },
//   { id: 91, label: "Felino Corporation" },
//   { id: 92, label: "Intermeccanica" },
//   { id: 93, label: "INKAS" },
//   { id: 94, label: "New Flyer" },
//   { id: 95, label: "Nova Bus" },
//   { id: 96, label: "Prevost" },
//   { id: 97, label: "Terradyne" },
//   { id: 98, label: "Acadian" },
//   { id: 99, label: "Asüna" },
//   { id: 100, label: "Beaumont" },
//   { id: 101, label: "Bricklin" },
//   { id: 102, label: "Brooks" },
//   { id: 103, label: "Can-Am" },
//   { id: 104, label: "Dennis" },
//   { id: 105, label: "Derby" },
//   { id: 106, label: "Dupont" },
//   { id: 107, label: "Dynasty" },
//   { id: 108, label: "Envoy" },
//   { id: 109, label: "Frontenac" },
//   { id: 110, label: "Gray-Dort" },
//   { id: 111, label: "Hayes Trucks" },
//   { id: 112, label: "Manic" },
//   { id: 113, label: "McLaughlin" },
//   { id: 114, label: "Meteor" },
//   { id: 115, label: "Monarch" },
//   { id: 116, label: "Orion" },
//   { id: 117, label: "Pacific Trucks" },
//   { id: 118, label: "Passport" },
//   { id: 119, label: "Redpath" },
//   { id: 120, label: "Russell" },
//   { id: 121, label: "Studebaker" },
//   { id: 122, label: "ZENN" },
//   { id: 123, label: "Arcfox" },
//   { id: 124, label: "Aion" },
//   { id: 125, label: "Apex Motors" },
//   { id: 126, label: "BAIC" },
//   { id: 127, label: "Baojun" },
//   { id: 128, label: "BAW" },
//   { id: 129, label: "Bestune" },
//   { id: 130, label: "Brilliance" },
//   { id: 131, label: "BYD" },
//   { id: 132, label: "Changan" },
//   { id: 133, label: "Changhe" },
//   { id: 134, label: "Chery" },
//   { id: 135, label: "CNHTC" },
//   { id: 136, label: "Dongfeng" },
//   { id: 137, label: "Englon" },
//   { id: 138, label: "EuAuto" },
//   { id: 139, label: "Everus" },
//   { id: 140, label: "FAW" },
//   { id: 141, label: "Hongqi" },
//   { id: 142, label: "Foton" },
//   { id: 143, label: "GAC Fiat" },
//   { id: 144, label: "Geely" },
//   { id: 145, label: "Gonow" },
//   { id: 146, label: "Great Wall" },
//   { id: 147, label: "Hafei" },
//   { id: 148, label: "Haima" },
//   { id: 149, label: "Hanteng" },
//   { id: 150, label: "Hawtai" },
//   { id: 151, label: "Haval" },
//   { id: 152, label: "Heibao" },
//   { id: 153, label: "Higer" },
//   { id: 154, label: "JAC Motors" },
//   { id: 155, label: "Jiangling" },
//   { id: 156, label: "Jinbei" },
//   { id: 157, label: "Jonway" },
//   { id: 158, label: "Karry" },
//   { id: 159, label: "Landwind" },
//   { id: 160, label: "Leahead" },
//   { id: 161, label: "Lifan" },
//   { id: 162, label: "Li Auto" },
//   { id: 163, label: "Luxgen" },
//   { id: 164, label: "Nio" },
//   { id: 165, label: "Qoros" },
//   { id: 166, label: "Roewe" },
//   { id: 167, label: "SAIC" },
//   { id: 168, label: "Sehol" },
//   { id: 169, label: "Shacman" },
//   { id: 170, label: "Soueast" },
//   { id: 171, label: "Trumpchi" },
//   { id: 172, label: "Wuling" },
//   { id: 173, label: "Voyah" },
//   { id: 174, label: "Xpeng" },
//   { id: 175, label: "Yudo Auto" },
//   { id: 176, label: "Yutong" },
//   { id: 177, label: "Zhongtong Bus" },
//   { id: 178, label: "Zinoro" },
//   { id: 179, label: "Zotye" },
//   { id: 180, label: "ZX Auto" },
//   { id: 181, label: "Emgrand" },
//   { id: 182, label: "Dadi Auto" },
//   { id: 183, label: "Venucia" },
//   { id: 184, label: "DOK-ING" },
//   { id: 185, label: "Rimac" },
//   { id: 186, label: "Škoda" },
//   { id: 187, label: "Tatra" },
//   { id: 188, label: "Avia" },
//   { id: 189, label: "Kaipan" },
//   { id: 190, label: "MTX" },
//   { id: 191, label: "Praga" },
//   { id: 192, label: "Aero" },
//   { id: 193, label: "Jawa" },
//   { id: 194, label: "LIAZ" },
//   { id: 195, label: "Velorex" },
//   { id: 196, label: "Walter Fiat" },
//   { id: 197, label: "Zenvo" },
//   { id: 198, label: "Marathon Motors Engineering" },
//   { id: 199, label: "Holland Car" },
//   { id: 200, label: "Valmet Automotive" },
//   { id: 201, label: "Electric Raceabout" },
//   { id: 202, label: "Toroidion" },
//   { id: 203, label: "Sisu Auto" },
//   { id: 204, label: "Aixam" },
//   { id: 205, label: "Alpine" },
//   { id: 206, label: "Auverland" },
//   { id: 207, label: "Bolloré" },
//   { id: 208, label: "Bugatti" },
//   { id: 209, label: "Citroën" },
//   { id: 210, label: "Exagon" },
//   { id: 211, label: "Hommell" },
//   { id: 212, label: "Ligier" },
//   { id: 213, label: "Microcar" },
//   { id: 214, label: "Méga" },
//   { id: 215, label: "Peugeot" },
//   { id: 216, label: "PGO" },
//   { id: 217, label: "Renault" },
//   { id: 218, label: "Venturi" },
//   { id: 219, label: "Aérocarène" },
//   { id: 220, label: "Bellanger" },
//   { id: 221, label: "Berliet" },
//   { id: 222, label: "Chenard-Walcker" },
//   { id: 223, label: "Decauville" },
//   { id: 224, label: "De Dion-Bouton" },
//   { id: 225, label: "Delage" },
//   { id: 226, label: "Delahaye" },
//   { id: 227, label: "Delaunay-Belleville" },
//   { id: 228, label: "Facel Vega" },
//   { id: 229, label: "Helicron" },
//   { id: 230, label: "Lorraine-Dietrich" },
//   { id: 231, label: "Matra" },
//   { id: 232, label: "Mors" },
//   { id: 233, label: "Panhard" },
//   { id: 234, label: "Rochet-Schneider" },
//   { id: 235, label: "Simca" },
//   { id: 236, label: "Talbot" },
//   { id: 237, label: "Talbot-Lago" },
//   { id: 238, label: "Alpina" },
//   { id: 239, label: "Apollo" },
//   { id: 240, label: "Artega" },
//   { id: 241, label: "Audi" },
//   { id: 242, label: "Bitter" },
//   { id: 243, label: "BMW" },
//   { id: 244, label: "Daimler Truck" },
//   { id: 245, label: "Fuso" },
//   { id: 246, label: "Isdera" },
//   { id: 247, label: "Lotec" },
//   { id: 248, label: "MAN" },
//   { id: 249, label: "Mercedes-Benz" },
//   { id: 250, label: "Multicar" },
//   { id: 251, label: "Neoplan" },
//   { id: 252, label: "Opel" },
//   { id: 253, label: "Ruf" },
//   { id: 254, label: "Porsche" },
//   { id: 255, label: "Setra" },
//   { id: 256, label: "Smart" },
//   { id: 257, label: "Volkswagen" },
//   { id: 258, label: "Wiesmann" },
//   { id: 259, label: "Adler" },
//   { id: 260, label: "AGA" },
//   { id: 261, label: "Auto Union" },
//   { id: 262, label: "AWS" },
//   { id: 263, label: "AWZ" },
//   { id: 264, label: "Barkas" },
//   { id: 265, label: "Borgward" },
//   { id: 266, label: "Brennabor" },
//   { id: 267, label: "Büssing" },
//   { id: 268, label: "Dauer" },
//   { id: 269, label: "DKW" },
//   { id: 270, label: "Glas" },
//   { id: 271, label: "Goliath" },
//   { id: 272, label: "Gumpert" },
//   { id: 273, label: "Hanomag" },
//   { id: 274, label: "Hansa" },
//   { id: 275, label: "Heinkel" },
//   { id: 276, label: "Horch" },
//   { id: 277, label: "Karmann" },
//   { id: 278, label: "Lloyd" },
//   { id: 279, label: "Maybach" },
//   { id: 280, label: "Melkus" },
//   { id: 281, label: "Messerschmitt" },
//   { id: 282, label: "NAG" },
//   { id: 283, label: "NSU" },
//   { id: 284, label: "Robur" },
//   { id: 285, label: "Simson" },
//   { id: 286, label: "Stoewer" },
//   { id: 287, label: "Tempo" },
//   { id: 288, label: "Trabant" },
//   { id: 289, label: "Wanderer" },
//   { id: 290, label: "Wartburg" },
//   { id: 291, label: "Zundapp" },
//   { id: 292, label: "Kantanka" },
//   { id: 293, label: "ELVO" },
//   { id: 294, label: "Korres" },
//   { id: 295, label: "Namco" },
//   { id: 296, label: "Replicar Hellas" },
//   { id: 297, label: "Sfakianakis" },
//   { id: 298, label: "Tangalakis-Temax" },
//   { id: 299, label: "Alta" },
//   { id: 300, label: "Attica" },
//   { id: 301, label: "Autokinitoviomihania Ellados" },
//   { id: 302, label: "Automeccanica" },
//   { id: 303, label: "Balkania" },
//   { id: 304, label: "BET" },
//   { id: 305, label: "Biamax" },
//   { id: 306, label: "BIOMAN" },
//   { id: 307, label: "DIM" },
//   { id: 308, label: "MAVA-Renault" },
//   { id: 309, label: "MEBEA" },
//   { id: 310, label: "Neorion" },
//   { id: 311, label: "Pan-Car" },
//   { id: 312, label: "Theologou" },
//   { id: 313, label: "Ajanta Group" },
//   { id: 314, label: "Ashok Leyland" },
//   { id: 315, label: "Asia MotorWorks" },
//   { id: 316, label: "Atul Auto" },
//   { id: 317, label: "AVANI" },
//   { id: 318, label: "Bajaj" },
//   { id: 319, label: "Eicher" },
//   { id: 320, label: "Force" },
//   { id: 321, label: "Hindustan" },
//   { id: 322, label: "Hradyesh" },
//   { id: 323, label: "ICML" },
//   { id: 324, label: "KAL" },
//   { id: 325, label: "Mahindra" },
//   { id: 326, label: "Maruti Suzuki" },
//   { id: 327, label: "Omega Seiki Mobility" },
//   { id: 328, label: "Tara International" },
//   { id: 329, label: "Tata" },
//   { id: 330, label: "TVS" },
//   { id: 331, label: "Chinkara" },
//   { id: 332, label: "Hero" },
//   { id: 333, label: "Ideal Jawa" },
//   { id: 334, label: "Kinetic Engineering Limited" },
//   { id: 335, label: "Lohia Machinery" },
//   { id: 336, label: "Mopeds India Limited" },
//   { id: 337, label: "Premier" },
//   { id: 338, label: "Sipani" },
//   { id: 339, label: "Standard" },
//   { id: 340, label: "Swaraj Mazda" },
//   { id: 341, label: "TMC" },
//   { id: 342, label: "Alesbury" },
//   { id: 343, label: "Crosslé" },
//   { id: 344, label: "Shamrock (car)" },
//   { id: 345, label: "Astra International" },
//   { id: 346, label: "Indomobil Group" },
//   { id: 347, label: "Esemka" },
//   { id: 348, label: "Pindad" },
//   { id: 349, label: "Timor" },
//   { id: 350, label: "Bahman" },
//   { id: 351, label: "Iran Khodro" },
//   { id: 352, label: "Kish Khodro" },
//   { id: 353, label: "MVM" },
//   { id: 354, label: "Pars Khodro" },
//   { id: 355, label: "SAIPA" },
//   { id: 356, label: "Autocars" },
//   { id: 357, label: "Carmor" },
//   { id: 358, label: "Plasan" },
//   { id: 359, label: "AIL" },
//   { id: 360, label: "Abarth" },
//   { id: 361, label: "Alfa Romeo" },
//   { id: 362, label: "Bremach" },
//   { id: 363, label: "Casalini" },
//   { id: 364, label: "Covini" },
//   { id: 365, label: "CTS" },
//   { id: 366, label: "DR Motor" },
//   { id: 367, label: "Ducati" },
//   { id: 368, label: "Ferrari" },
//   { id: 369, label: "Fiat" },
//   { id: 370, label: "Iso" },
//   { id: 371, label: "Italdesign" },
//   { id: 372, label: "Iveco" },
//   { id: 373, label: "Lancia" },
//   { id: 374, label: "Lamborghini" },
//   { id: 375, label: "Maserati" },
//   { id: 376, label: "Mazzanti" },
//   { id: 377, label: "Minardi" },
//   { id: 378, label: "Pagani" },
//   { id: 379, label: "Piaggio" },
//   { id: 380, label: "Pininfarina" },
//   { id: 381, label: "Vespa" },
//   { id: 382, label: "Zagato" },
//   { id: 383, label: "APIS" },
//   { id: 384, label: "Ansaldo" },
//   { id: 385, label: "Aquila" },
//   { id: 386, label: "A.S.A." },
//   { id: 387, label: "A.T.S." },
//   { id: 388, label: "Autobianchi" },
//   { id: 389, label: "Aurea" },
//   { id: 390, label: "Bandini" },
//   { id: 391, label: "Bertone" },
//   { id: 392, label: "Bizzarrini" },
//   { id: 393, label: "Ceirano" },
//   { id: 394, label: "Chiribiri" },
//   { id: 395, label: "Cisitalia" },
//   { id: 396, label: "Cizeta" },
//   { id: 397, label: "De Tomaso" },
//   { id: 398, label: "Diatto" },
//   { id: 399, label: "FCA" },
//   { id: 400, label: "F.L.A.G." },
//   { id: 401, label: "Fornasari" },
//   { id: 402, label: "Innocenti" },
//   { id: 403, label: "Iso Milano" },
//   { id: 404, label: "Isotta Fraschini" },
//   { id: 405, label: "Itala" },
//   { id: 406, label: "Junior" },
//   { id: 407, label: "Lambretta" },
//   { id: 408, label: "LMX" },
//   { id: 409, label: "Marca-Tre-Spade" },
//   { id: 410, label: "Moretti" },
//   { id: 411, label: "Nardi" },
//   { id: 412, label: "O.M." },
//   { id: 413, label: "O.S.C.A." },
//   { id: 414, label: "Qvale" },
//   { id: 415, label: "Scuderia Ferrari" },
//   { id: 416, label: "Rapid" },
//   { id: 417, label: "S.C.A.T." },
//   { id: 418, label: "Siata" },
//   { id: 419, label: "S.P.A." },
//   { id: 420, label: "Stanguellini" },
//   { id: 421, label: "Storero" },
//   { id: 422, label: "Vignale" },
//   { id: 423, label: "Volugrafo" },
//   { id: 424, label: "Zust" },
//   { id: 425, label: "Acura" },
//   { id: 426, label: "Aspark" },
//   { id: 427, label: "Autobacs Seven" },
//   { id: 428, label: "Daihatsu" },
//   { id: 429, label: "Dome" },
//   { id: 430, label: "GLM" },
//   { id: 431, label: "Hino" },
//   { id: 432, label: "Honda" },
//   { id: 433, label: "Infiniti" },
//   { id: 434, label: "Isuzu" },
//   { id: 435, label: "Kawasaki" },
//   { id: 436, label: "Lexus" },
//   { id: 437, label: "Mazda" },
//   { id: 438, label: "Mitsubishi" },
//   // { id: 439, label: "Mitsubishi Fuso" },
//   { id: 440, label: "Mitsuoka" },
//   { id: 441, label: "Nissan" },
//   { id: 442, label: "Subaru" },
//   { id: 443, label: "Suzuki" },
//   { id: 444, label: "Tommykaira" },
//   { id: 445, label: "Toyota" },
//   { id: 446, label: "UD Trucks" },
//   { id: 447, label: "Yamaha" },
//   { id: 448, label: "Asahi" },
//   { id: 449, label: "Autozam" },
//   { id: 450, label: "Datsun" },
//   { id: 451, label: "Eunos" },
//   { id: 452, label: "Ohta" },
//   { id: 453, label: "Otomo" },
//   { id: 454, label: "Prince" },
//   { id: 455, label: "Mobius Motors" },
//   { id: 456, label: "Dartz" },
//   { id: 457, label: "Ford-Vairogs" },
//   { id: 458, label: "RAF (Latvija)" },
//   { id: 459, label: "W Motors" },
//   { id: 460, label: "Orca Engineering" },
//   { id: 461, label: "Jehle" },
//   { id: 462, label: "MDI" },
//   { id: 463, label: "Bufori" },
//   { id: 464, label: "DefTech" },
//   { id: 465, label: "Inokom" },
//   { id: 466, label: "Modenas" },
//   { id: 467, label: "Naza" },
//   { id: 468, label: "Perodua" },
//   { id: 469, label: "Proton" },
//   { id: 470, label: "DINA" },
//   { id: 471, label: "Mastretta" },
//   { id: 472, label: "VUHL" },
//   { id: 473, label: "Ramirez" },
//   { id: 474, label: "VAM" },
//   { id: 475, label: "Laraki" },
//   { id: 476, label: "Renault Somaca" },
//   { id: 477, label: "Fiat Somaca" },
//   { id: 478, label: "Shan Star" },
//   { id: 479, label: "Burton" },
//   { id: 480, label: "Carver" },
//   { id: 481, label: "DAF" },
//   { id: 482, label: "Dakar" },
//   { id: 483, label: "Donkervoort" },
//   { id: 484, label: "Spijkstaal" },
//   { id: 485, label: "Spyker" },
//   { id: 486, label: "Stellantis" },
//   { id: 487, label: "Vandenbrink" },
//   { id: 488, label: "VDL Berkhof" },
//   { id: 489, label: "Vencer" },
//   { id: 490, label: "Anziel" },
//   { id: 491, label: "Saker Cars" },
//   { id: 492, label: "Trekka" },
//   { id: 493, label: "Innoson Motors" },
//   { id: 494, label: "DFM" },
//   { id: 495, label: "Pyeonghwa Motors" },
//   { id: 496, label: "Sungri Motors" },
//   { id: 497, label: "Samhung Motors" },
//   { id: 498, label: "Buddy" },
//   { id: 499, label: "Think" },
//   { id: 500, label: "Heavy Industries Taxila" },
//   { id: 501, label: "Sazgar" },
//   { id: 502, label: "United Auto Industries" },
//   { id: 503, label: "Adam Motor Company" },
//   { id: 504, label: "Dewan Farooque Motors" },
//   { id: 505, label: "Nexus Automotive" },
//   { id: 506, label: "Almazora Motors" },
//   { id: 507, label: "Del Monte Motors" },
//   { id: 508, label: "Sarao Motors" },
//   { id: 509, label: "Delta" },
//   { id: 510, label: "AMZ" },
//   { id: 511, label: "Arrinera" },
//   { id: 512, label: "Autosan" },
//   { id: 513, label: "Jelcz" },
//   { id: 514, label: "Solaris" },
//   { id: 515, label: "Ursus" },
//   { id: 516, label: "FSC Żuk" },
//   { id: 517, label: "FSM" },
//   { id: 518, label: "FSO" },
//   { id: 519, label: "FSR Tarpan" },
//   { id: 520, label: "Intrall" },
//   { id: 521, label: "Polski Fiat" },
//   { id: 522, label: "Star" },
//   { id: 523, label: "Syrena" },
//   { id: 524, label: "Warszawa" },
//   { id: 525, label: "ZSD Nysa" },
//   { id: 526, label: "Vinci" },
//   { id: 527, label: "Bravia" },
//   { id: 528, label: "Portaro" },
//   { id: 529, label: "UMM" },
//   { id: 530, label: "Edfor" },
//   { id: 531, label: "Dacia" },
//   { id: 532, label: "ROMAN" },
//   { id: 533, label: "ARO" },
//   { id: 534, label: "Oltcit" },
//   { id: 535, label: "Rocar" },
//   { id: 536, label: "Avtotor" },
//   { id: 537, label: "GAZ" },
//   { id: 538, label: "IZh" },
//   { id: 539, label: "Kamaz" },
//   { id: 540, label: "Lada" },
//   { id: 541, label: "LiAZ" },
//   { id: 542, label: "PAZ" },
//   { id: 543, label: "Sollers" },
//   { id: 544, label: "UAZ" },
//   { id: 545, label: "Amur" },
//   { id: 546, label: "Avtoframos" },
//   { id: 547, label: "Derways" },
//   { id: 548, label: "GAZ (Volga)" },
//   { id: 549, label: "Marussia" },
//   { id: 550, label: "Moskvitch" },
//   { id: 551, label: "Russo-Balt" },
//   { id: 552, label: "SeAZ" },
//   { id: 553, label: "SMZ" },
//   { id: 554, label: "TagAZ" },
//   { id: 555, label: "Yo-Mobile" },
//   { id: 556, label: "ZiL" },
//   { id: 557, label: "KSU Gazal-1" },
//   { id: 558, label: "FAP" },
//   { id: 559, label: "FAS" },
//   { id: 560, label: "Ikarbus" },
//   { id: 561, label: "IMT" },
//   { id: 562, label: "Zastava" },
//   { id: 563, label: "IDA" },
//   { id: 564, label: "Yugo" },
//   { id: 565, label: "Adria Mobil" },
//   { id: 566, label: "TAM" },
//   { id: 567, label: "Advanced Automotive Design" },
//   { id: 568, label: "Perana Performance Group" },
//   { id: 569, label: "AAD" },
//   { id: 570, label: "CT&amp;T United" },
//   { id: 571, label: "Daewoo Bus" },
//   { id: 572, label: "Genesis" },
//   { id: 573, label: "GM Korea" },
//   { id: 574, label: "Hyundai" },
//   { id: 575, label: "Kia" },
//   { id: 576, label: "Renault Samsung" },
//   { id: 577, label: "SsangYong" },
//   { id: 578, label: "Tata Daewoo" },
//   { id: 579, label: "Asia Motors" },
//   { id: 580, label: "Daewoo" },
//   { id: 581, label: "Keohwa" },
//   { id: 582, label: "Proto" },
//   { id: 583, label: "Saehan" },
//   { id: 584, label: "Samsung" },
//   { id: 585, label: "Shinjin" },
//   { id: 586, label: "Sibal" },
//   { id: 587, label: "Abadal" },
//   { id: 588, label: "Aspid" },
//   { id: 589, label: "Cupra" },
//   { id: 590, label: "GTA" },
//   { id: 591, label: "Irizar" },
//   { id: 592, label: "SEAT" },
//   { id: 593, label: "Spania" },
//   { id: 594, label: "Tauro" },
//   { id: 595, label: "Tramontana" },
//   { id: 596, label: "Uro" },
//   { id: 597, label: "Hispano Suiza" },
//   { id: 598, label: "Micro" },
//   { id: 599, label: "Vega EVX" },
//   { id: 600, label: "Koenigsegg" },
//   { id: 601, label: "NEVS" },
//   { id: 602, label: "Polestar" },
//   { id: 603, label: "Scania" },
//   { id: 604, label: "Uniti" },
//   { id: 605, label: "Volvo Buses" },
//   { id: 606, label: "Volvo Cars" },
//   { id: 607, label: "Volvo Trucks" },
//   { id: 608, label: "Jösse Car" },
//   { id: 609, label: "Saab" },
//   { id: 610, label: "Leblanc" },
//   { id: 611, label: "Rinspeed" },
//   { id: 612, label: "Sbarro" },
//   { id: 613, label: "Enzmann" },
//   { id: 614, label: "Martini" },
//   { id: 615, label: "Monteverdi" },
//   { id: 616, label: "Pic-Pic" },
//   { id: 617, label: "Soletta" },
//   { id: 618, label: "CMC" },
//   { id: 619, label: "Yulon" },
//   { id: 620, label: "Thai Rung" },
//   { id: 621, label: "Wallyscar" },
//   { id: 622, label: "BMC" },
//   { id: 623, label: "Diardi" },
//   { id: 624, label: "Erkunt" },
//   { id: 625, label: "Etox" },
//   { id: 626, label: "Fiat-Tofaş" },
//   { id: 627, label: "FNSS" },
//   { id: 628, label: "Guleryuz" },
//   { id: 629, label: "Karsan" },
//   { id: 630, label: "Otokar" },
//   { id: 631, label: "Özaltin" },
//   { id: 632, label: "Pancar Motor" },
//   { id: 633, label: "Temsa" },
//   { id: 634, label: "TOGG" },
//   { id: 635, label: "Anadol" },
//   { id: 636, label: "Devrim" },
//   { id: 637, label: "Bohdan" },
//   { id: 638, label: "Etalon" },
//   { id: 639, label: "KrAZ" },
//   { id: 640, label: "LAZ" },
//   { id: 641, label: "ZAZ" },
//   { id: 642, label: "LuAZ" },
//   { id: 643, label: "Ajlani Motors" },
//   { id: 644, label: "AC" },
//   { id: 645, label: "Alexander Dennis" },
//   { id: 646, label: "Ariel" },
//   { id: 647, label: "Aston Martin" },
//   { id: 648, label: "Atalanta Motors" },
//   { id: 649, label: "BAC" },
//   { id: 650, label: "Bentley" },
//   { id: 651, label: "Bowler" },
//   { id: 652, label: "Caterham" },
//   { id: 653, label: "David Brown Automotive" },
//   { id: 654, label: "Ginetta" },
//   { id: 655, label: "GKD" },
//   { id: 656, label: "Grinnall" },
//   { id: 657, label: "Healey" },
//   { id: 658, label: "Jaguar" },
//   { id: 659, label: "Lagonda" },
//   { id: 660, label: "Larmar" },
//   { id: 661, label: "Land Rover" },
//   { id: 662, label: "Lister" },
//   { id: 663, label: "Lotus" },
//   { id: 664, label: "McLaren" },
//   { id: 665, label: "MG" },
//   { id: 666, label: "Mini" },
//   { id: 667, label: "Modec" },
//   { id: 668, label: "Morgan" },
//   { id: 669, label: "Noble" },
//   { id: 670, label: "Radical Sportscars" },
//   { id: 671, label: "Rolls-Royce" },
//   { id: 672, label: "Ronart Cars" },
//   { id: 673, label: "TVR" },
//   { id: 674, label: "Ultima Sports" },
//   { id: 675, label: "Vauxhall" },
//   { id: 676, label: "Westfield" },
//   { id: 677, label: "Adam's Brothers" },
//   { id: 678, label: "AEC" },
//   { id: 679, label: "Allard" },
//   { id: 680, label: "Alvis" },
//   { id: 681, label: "Austin" },
//   { id: 682, label: "Austin-Healey" },
//   { id: 683, label: "British Leyland" },
//   { id: 684, label: "Bristol" },
//   { id: 685, label: "Daimler" },
//   { id: 686, label: "Donald Healey" },
//   { id: 687, label: "Elva" },
//   { id: 688, label: "Gordon Keeble" },
//   { id: 689, label: "Hillman" },
//   { id: 690, label: "Humber" },
//   { id: 691, label: "Jensen" },
//   { id: 692, label: "Jowett" },
//   { id: 693, label: "Lanchester" },
//   { id: 694, label: "Marcos" },
//   { id: 695, label: "Morris" },
//   { id: 696, label: "Panther Westwinds" },
//   { id: 697, label: "Reliant Motors" },
//   { id: 698, label: "Riley" },
//   { id: 699, label: "Rootes" },
//   { id: 700, label: "Rover" },
//   { id: 701, label: "Sharps Commercials Ltd" },
//   { id: 702, label: "Singer" },
//   { id: 703, label: "Sunbeam" },
//   { id: 704, label: "Trident Cars" },
//   { id: 705, label: "Triumph" },
//   { id: 706, label: "Trojan" },
//   { id: 707, label: "Wolseley" },
//   { id: 708, label: "All American Racers" },
//   { id: 709, label: "AM General" },
//   { id: 710, label: "Anteros Coachworks" },
//   { id: 711, label: "Aptera" },
//   { id: 712, label: "Arcimoto" },
//   { id: 713, label: "Autocar" },
//   { id: 714, label: "Buick" },
//   { id: 715, label: "Cadillac" },
//   { id: 716, label: "Callaway" },
//   { id: 717, label: "Canoo" },
//   { id: 718, label: "Chevrolet" },
//   { id: 719, label: "Chrysler" },
//   { id: 720, label: "Detroit Electric" },
//   { id: 721, label: "Dodge" },
//   { id: 722, label: "E-Z-GO" },
//   { id: 723, label: "ElDorado" },
//   { id: 724, label: "ENC" },
//   { id: 725, label: "Falcon Motorsports" },
//   { id: 726, label: "Faraday" },
//   { id: 727, label: "Fisker" },
//   { id: 728, label: "Ford" },
//   { id: 729, label: "Freightliner" },
//   { id: 730, label: "Gillig" },
//   { id: 731, label: "GM" },
//   { id: 732, label: "Google" },
//   { id: 733, label: "GEM" },
//   { id: 734, label: "GMC" },
//   { id: 735, label: "Hennessey" },
//   { id: 736, label: "Jeep" },
//   { id: 737, label: "Karma" },
//   { id: 738, label: "Kenworth" },
//   { id: 739, label: "Lincoln" },
//   { id: 740, label: "Lordstown Motors" },
//   { id: 741, label: "Lucid Motors" },
//   { id: 742, label: "Mack" },
//   { id: 743, label: "MCI" },
//   { id: 744, label: "Navistar" },
//   { id: 745, label: "Nikola Motor" },
//   { id: 746, label: "Oshkosh" },
//   { id: 747, label: "Panoz" },
//   { id: 748, label: "Peterbilt" },
//   { id: 749, label: "Pierce" },
//   { id: 750, label: "Polaris" },
//   { id: 751, label: "Proterra" },
//   { id: 752, label: "Ram" },
//   { id: 753, label: "RAESR" },
//   { id: 754, label: "Rezvani Motors" },
//   { id: 755, label: "Rivian" },
//   { id: 756, label: "Rossion" },
//   { id: 757, label: "Saleen" },
//   { id: 758, label: "SCG" },
//   { id: 759, label: "Shelby" },
//   { id: 760, label: "SRT" },
//   { id: 761, label: "SSC" },
//   { id: 762, label: "Tesla" },
//   { id: 763, label: "Thomas Built Buses" },
//   { id: 764, label: "Trion Supercars" },
//   { id: 765, label: "VLF Automotive" },
//   { id: 766, label: "Western Star" },
//   { id: 767, label: "Workhorse" },
//   { id: 768, label: "Adams-Farwell" },
//   { id: 769, label: "Alco" },
//   { id: 770, label: "AMC" },
//   { id: 771, label: "Amplex" },
//   { id: 772, label: "Apperson" },
//   { id: 773, label: "ArBenz" },
//   { id: 774, label: "Auburn" },
//   { id: 775, label: "Avanti Motor Company" },
//   { id: 776, label: "Baker Electric" },
//   { id: 777, label: "Bates" },
//   { id: 778, label: "Brush" },
//   { id: 779, label: "Cartercar" },
//   { id: 780, label: "Chalmers" },
//   { id: 781, label: "Chandler" },
//   { id: 782, label: "Chaparral Cars" },
//   { id: 783, label: "Checker" },
//   { id: 784, label: "Clénet" },
//   { id: 785, label: "Cole" },
//   { id: 786, label: "Columbia" },
//   { id: 787, label: "Continental" },
//   { id: 788, label: "Cord" },
//   { id: 789, label: "Crawford" },
//   { id: 790, label: "Crosley" },
//   { id: 791, label: "Cutting" },
//   { id: 792, label: "Daniels" },
//   { id: 793, label: "Davis" },
//   { id: 794, label: "Devon" },
//   { id: 795, label: "DeLorean" },
//   { id: 796, label: "DeSoto" },
//   { id: 797, label: "Diamond Reo Trucks" },
//   { id: 798, label: "Doble" },
//   { id: 799, label: "Dorris" },
//   { id: 800, label: "Dort" },
//   { id: 801, label: "Duesenberg" },
//   { id: 802, label: "Durant" },
//   { id: 803, label: "Dymaxion" },
//   { id: 804, label: "Eagle" },
//   { id: 805, label: "Edsel" },
//   { id: 806, label: "Elgin" },
//   { id: 807, label: "Essex" },
//   { id: 808, label: "Excalibur" },
//   { id: 809, label: "Flint" },
//   { id: 810, label: "Frazer" },
//   { id: 811, label: "Gardner" },
//   { id: 812, label: "Geo" },
//   { id: 813, label: "Graham-Paige" },
//   { id: 814, label: "Grumman" },
//   { id: 815, label: "Haynes" },
//   { id: 816, label: "Hudson" },
//   { id: 817, label: "Hummer" },
//   { id: 818, label: "Hupmobile" },
//   { id: 819, label: "Hupp-Yeats" },
//   { id: 820, label: "Imperial" },
//   { id: 821, label: "International Harvester" },
//   { id: 822, label: "Inter-State" },
//   { id: 823, label: "Jackson" },
//   { id: 824, label: "Jeffery" },
//   { id: 825, label: "Kaiser" },
//   { id: 826, label: "King" },
//   { id: 827, label: "Kissel" },
//   { id: 828, label: "Kline Kar" },
//   { id: 829, label: "Knox" },
//   { id: 830, label: "LaFayette" },
//   { id: 831, label: "Laforza" },
//   { id: 832, label: "Lambert" },
//   { id: 833, label: "LaSalle" },
//   { id: 834, label: "Lexington" },
//   { id: 835, label: "Local" },
//   { id: 836, label: "Locomobile" },
//   { id: 837, label: "Lozier" },
//   { id: 838, label: "Lyons-Knight" },
//   { id: 839, label: "Marmon" },
//   { id: 840, label: "Marmon-Herrington" },
//   { id: 841, label: "Maxwell" },
//   { id: 842, label: "McFarlan" },
//   { id: 843, label: "Mercury" },
//   { id: 844, label: "Mitchell" },
//   { id: 845, label: "Monroe" },
//   { id: 846, label: "Moon" },
//   { id: 847, label: "Mosler" },
//   { id: 848, label: "Nash" },
//   { id: 849, label: "National" },
//   { id: 850, label: "Nyberg" },
//   { id: 851, label: "Oakland" },
//   { id: 852, label: "Oldsmobile" },
//   { id: 853, label: "Overland" },
//   { id: 854, label: "Packard" },
//   { id: 855, label: "Peerless" },
//   { id: 856, label: "Pierce-Arrow" },
//   { id: 857, label: "Pilot" },
//   { id: 858, label: "Plymouth" },
//   { id: 859, label: "Pontiac" },
//   { id: 860, label: "Pope-Toledo" },
//   { id: 861, label: "Pope-Tribune" },
//   { id: 862, label: "Pungs Finch" },
//   { id: 863, label: "Rambler" },
//   { id: 864, label: "Rauch and Lang" },
//   { id: 865, label: "Regal" },
//   { id: 866, label: "REO" },
//   { id: 867, label: "Safari Coach" },
//   { id: 868, label: "Saturn" },
//   { id: 869, label: "Scion" },
//   { id: 870, label: "Scripps-Booth" },
//   { id: 871, label: "Simplex" },
//   { id: 872, label: "Speedwell" },
//   { id: 873, label: "Stanley" },
//   { id: 874, label: "Stearns-Knight" },
//   { id: 875, label: "Sterling" },
//   { id: 876, label: "Sterling Trucks" },
//   { id: 877, label: "Stevens-Duryea" },
//   { id: 878, label: "Stoddard-Dayton" },
//   { id: 879, label: "Stutz" },
//   { id: 880, label: "TH!NK" },
//   { id: 881, label: "Thomas" },
//   { id: 882, label: "Tucker" },
//   { id: 883, label: "Vector" },
//   { id: 884, label: "VPG" },
//   { id: 885, label: "Velie" },
//   { id: 886, label: "Westcott" },
//   { id: 887, label: "White" },
//   { id: 888, label: "Willys" },
//   { id: 889, label: "Zimmer" },
//   { id: 890, label: "Effa" },
//   { id: 891, label: "SAZ" },
//   { id: 892, label: "GM Uzbekistan" },
//   { id: 893, label: "Venirauto" },
//   { id: 894, label: "Thaco" },
//   { id: 895, label: "Mekong Auto" },
//   { id: 896, label: "VinFast" },
//   { id: 897, label: "World Auto" },
//   { id: 898, label: "Vinaxuki" },
//   { id: 898, label: "Yadea" },
// ];

// export const brandsWithAny: SelectOption[] = [  { id: -1, label: "Any" }, ...brands]
