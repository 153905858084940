import { Grid, IconButton, Tooltip, Typography } from "@mui/material"
import { FC } from "react"
import { GridItemShare } from "../share/gridItemShare";
import { Part } from "../../types/Part";
import { Vehicle } from "../../types/Vehicle";
import { StarBorder, StarRate } from "@mui/icons-material"
import { PriceView } from "./PriceView";
import { PhoneDial } from "./PhoneDial";
import { getFormattedDate } from "../../util/helper";
import { EntityType } from "../../types/EntityType";
import { Link } from "react-router-dom";

export const EntityViewHeader: FC<{
    entityType: EntityType;
    title: string;
    subTitle: string | undefined;
    showFavourite: boolean;
    loadingFavorite: boolean;
    isFavourite: boolean;
    entity: Vehicle | Part;
    onFavoriteChange: (added: boolean) => void;
}> = ({ title, subTitle, showFavourite, entityType, isFavourite, entity, onFavoriteChange, loadingFavorite }) => {

    const sellInfo = entity.price !== 0 && entity.phone !== '';
    const ownerPage = `/${entityType === EntityType.part ? 'sparepart' : 'vehicle'}/shop/${entity.User?.id}`;

    return <>
        <Grid container justifyContent="space-between">
            <Grid item>
                <Typography variant="h4">{title}</Typography>
                <Typography variant="h6" color="grey">{subTitle}</Typography>
                <Link to={ownerPage}><Typography variant="subtitle2">{entity.User?.name}</Typography></Link>
                <Typography variant="caption">{getFormattedDate(entity.createdAt)}</Typography>
            </Grid>
            <Grid item>
                <Grid container alignItems="center">
                    {loadingFavorite ? <IconButton><StarRate color="disabled" /></IconButton>
                        : showFavourite && (isFavourite ?
                            <Tooltip title="Remove from favorites">
                                <IconButton onClick={() => onFavoriteChange(false)}>
                                    <StarRate color="primary" />
                                </IconButton>
                            </Tooltip>
                            : <Tooltip title="Add to favourites">
                                <IconButton onClick={() => onFavoriteChange(true)}>
                                    <StarBorder color="primary" sx={{ cursor: 'pointer' }} />
                                </IconButton>
                            </Tooltip>)}
                    <GridItemShare item={entity} />
                </Grid>
            </Grid>
        </Grid>
        {sellInfo && <Grid container alignItems="center">
            <Grid item>
                <Grid container alignItems="center" spacing={1}>
                    <PriceView price={entity.price} />
                    <PhoneDial phone={entity.phone} />
                </Grid>
            </Grid>
        </Grid>}
    </>
}