import { FC } from 'react';
import { Node } from '../../../types/Node';
import { useForcedRender } from '../../../hooks/useForcedRender';
import { Select } from '../select/Select';

interface Props {
    node: Node;
    idx: number;
    allExpanded?: boolean;
    onPopReset: () => void;
    isSingleSelection: boolean;
    disableAutoSelectChildren: boolean;
    disabled: boolean;
    onChildrenSelectionChange: (clickedNode: Node, idx: number) => void;
}

export const LeafNode: FC<Props> = ({ node, allExpanded, disabled, disableAutoSelectChildren, idx, onChildrenSelectionChange, onPopReset, isSingleSelection }: Props) => {

    const reRender = useForcedRender();

    if (node.isHidden) {
        return <></>
    }

    // Show/Hide the children when click on the label
    const handleOnLabelClick = () => {
        // setShowChildren((prev) => !prev);
        node.isExpanded = !node.isExpanded;
        // onChildrenSelectionChange(node, idx);
        reRender();
    };

    // Select all the children nodes when a parent node is selected
    const handleSelectionChange = (clickedNode: Node) => {
        const isClickedNodeSelected = clickedNode.isSelected === true;

        if (isSingleSelection) {
            onPopReset();
            node.isSelected = isClickedNodeSelected;
            // Below line cannot be taken to top since order matters
            // node.setSelectedStatusOfThisAndAllChildren(isClickedNodeSelected); // investigate on this why ` node.isSelected = isClickedNodeSelected;` is similar to `node.setSelectedStatusOfThisAndAllChildren(isClickedNodeSelected);` here.
        } else {
            // Below line cannot be taken to top since order matters
            node.isSelected = isClickedNodeSelected;
            // node.setSelectedStatusOfThisAndAllChildren(isClickedNodeSelected);
            if (clickedNode.isSelected) {
                if (disableAutoSelectChildren) {
                    node.isSelected = isClickedNodeSelected;
                } else {
                    node.setSelectedStatusOfThisAndAllChildren(isClickedNodeSelected);
                }
                node.selectPathToRoot();
            } else {
                node.setSelectedStatusOfThisAndAllChildren(isClickedNodeSelected);

            }
        }
        onChildrenSelectionChange(clickedNode, idx);
        reRender();
    };

    // When a children's selected state is changed, its notified to parent via this
    const handleChildrenSelectionChange = (clickedNode: Node, idx: number) => {
        const newSelectedStateForThisNode = node.isAllChildrenSelected();

        // Propagate the event to parent level, only if the selected state of this node is changed

        // node.isSelected = newSelectedStateForThisNode;
        onChildrenSelectionChange(clickedNode, idx);

        if (newSelectedStateForThisNode !== node.isSelected) {
            reRender();
        }
    };

    if (allExpanded) {
        if (node.isSelected) {
            // OK
        } else {
            return <></>
        }
    }

    return (
        <div style={{ marginTop: '5px', cursor: 'pointer' }}>
            <Select node={node} disabled={disabled} allExpanded={allExpanded} onSelectionChange={handleSelectionChange} onLabelClick={handleOnLabelClick} />
            <div style={{ marginLeft: '20px' }}>
                {(allExpanded ?? node.isExpanded) && node.children?.map((child, i) => (
                    <div key={child.value}>
                        <LeafNode
                            node={child}
                            disabled={disabled}
                            disableAutoSelectChildren={disableAutoSelectChildren}
                            idx={i}
                            allExpanded={allExpanded}
                            isSingleSelection={isSingleSelection}
                            onChildrenSelectionChange={handleChildrenSelectionChange}
                            onPopReset={onPopReset}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};
