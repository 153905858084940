import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../../types/User";
import { PopupType } from "../../enum/popup.type";
import { LoginUIMode } from "../../enum/login.ui.mode";
import { ConfirmBoxResult, initialState } from "../appState";
import { BreadcrumbNode } from "../../types/BreadcrumbNode";

export const appSlice = createSlice({
  name: "appSlice",
  initialState,
  reducers: {
    toggleSidePanel: (state, action: PayloadAction<boolean>) => {
      state.showSidePanel = action.payload;
    },
    onNetworkError: (state, action: PayloadAction<boolean>) => {
      state.networkError = action.payload;
    },
    onAppReady: (state, action: PayloadAction<boolean>) => {
      state.ready = action.payload;
    },
    onLoginVisibilityChange: (state, action: PayloadAction<boolean>) => {
      state.login.isVisible = action.payload;
    },
    // TODO: keep 1 from below commands and remove others
    updateBreadcrumb: (state, action: PayloadAction<BreadcrumbNode[]>) => {
      state.breadcrumbs = action.payload;
    },
    addBreadcrumb: (state, action: PayloadAction<BreadcrumbNode>) => {
      const clickedNode = action.payload;
      console.log(clickedNode.path);
      const selectedIndex = state.breadcrumbs.findIndex(
        (node) => node.path === clickedNode.path
      );
      if (selectedIndex === -1) {
        state.breadcrumbs.push(action.payload);
      }
    },
    onLeaveVehicleView: (state, action: PayloadAction<string>) => {
      state.latestVehicleVieWuRL = action.payload;
    },
    removeBreadcrumb: (state, action: PayloadAction<BreadcrumbNode>) => {
      const clickedNode = action.payload;
      console.log(clickedNode.path);
      const selectedIndex = state.breadcrumbs.findIndex(
        (node) => node.path === clickedNode.path
      );
      if (selectedIndex > -1) {
        state.breadcrumbs = state.breadcrumbs.slice(0, selectedIndex);
      }
    },
    changeLoginMode: (state, action: PayloadAction<LoginUIMode>) => {
      state.login.mode = action.payload;
    },
    showPopup: (
      state,
      action: PayloadAction<{ type: PopupType; message: string }>
    ) => {
      state.popup.isOpen = true;
      state.popup.type = action.payload.type;
      state.popup.message = action.payload.message;
    },

    updateConfirmBox: (
      state,
      action: PayloadAction<{
        isOpen?: boolean;
        header?: string;
        text?: string;
        notes?: string;
        result?: ConfirmBoxResult;
        inputType?: 'string' | 'number' | 'boolean';}>
    ) => {
      state.confirmBox.isOpen = action.payload.isOpen ?? state.confirmBox.isOpen;
      state.confirmBox.header = action.payload.header ?? state.confirmBox.header;
      state.confirmBox.text = action.payload.text ?? state.confirmBox.text;
      state.confirmBox.notes = action.payload.notes ?? state.confirmBox.notes;
      state.confirmBox.inputType = action.payload.inputType ?? state.confirmBox.inputType;
      state.confirmBox.result = action.payload.result ?? state.confirmBox.result;
    },
    
    hidePopup: (state) => {
      state.popup.isOpen = false;
    },
  },

});

export const {
  updateConfirmBox,
  showPopup,
  hidePopup,
  changeLoginMode,
  updateBreadcrumb,
  addBreadcrumb,
  removeBreadcrumb,
  onLoginVisibilityChange,
  onAppReady,
  onNetworkError,
  onLeaveVehicleView,
  toggleSidePanel
} = appSlice.actions;
