/* eslint-disable @typescript-eslint/no-extraneous-class */
import { Vehicle } from "../types/Vehicle";
import { ServiceRecord } from "../types/ServiceRecord";
import { User } from "../types/User";
import { transmissionTypes } from "../selectOptions/transmissionTypes";
import { fuelTypeOptions } from "../selectOptions/fuelTypes";
import { vehicleTypes } from "../selectOptions/vehicleTypes";
import { ServiceType, serviceTypes } from "../selectOptions/serviceTypes";
import { countryList } from "./countries";
import { parseExtraProps } from "./helper";
import { config } from "../config";
import { Part } from "../types/Part";
import { phone } from 'phone';
import { ListingType } from "../enum/vehicleListing.enum";
import { SelectOption } from "../types/SelectOption";
import { WithArea } from "../types/WithArea";

export class Validators {
  public static code = (val: string) => {
    return val.length === 6 && !isNaN(Number(val));
  };

  public static text = (val: string) => {
    if (!val) {
      return false;
    }
    return val !== "";
  };

  public static userName = (val: string) => {
    if (!val) {
      return false;
    }
    return val !== "" && val.length > 3;
  };

  public static password = (val: string) => {
    if (!val) {
      return false;
    }
    return val !== "" && val.length > 2;
  };

  public static email = (val: string) => {
    return String(val)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  public static title = (val: string) => {
    if (!val) {
      return false;
    }
    return config.minTextLength < val.length && val.length < config.inputTextLimit;
  };

  public static description = (val: string) => {
    if (!val) {
      return false;
    }
    return val.length < config.multilineTextLimit;
  };

  public static numberPositive = (val: any) => {
    const n = Number(val);
    if (!isNaN(n)) {
      return n > 0 && n < 10000000;
    }
    return false;
  };

  public static numberPositiveOrZero = (val: any) => {
    const n = Number(val);
    if (!isNaN(n)) {
      return n >= 0 && n < 10000000;
    }
    return false;
  };

  public static volume = (val: any) => {
    const n = Number(val);
    if (!isNaN(n)) {
      return n > 0 && n < 1000;
    }
    return false;
  };

  public static cc = (val: any) => {
    const n = Number(val);
    if (!isNaN(n)) {
      return n > 40 && n < 20000;
    }
    return false;
  };

  public static odo = (val: any) => {
    const n = Number(val);
    if (!isNaN(n)) {
      return n > 0 && n <= 1000000;
    }
    return false;
  };

  public static numberAny = (val: any) => {
    const n = Number(val);
    if (!isNaN(n)) {
      return n >= 0;
    }
    return false;
  };

  public static regNo = (val: any): boolean => {
    if (typeof val === "string") {
      return val !== '' && val.length > 4 && val.length <= config.inputTextLimit;
    }
    return false;
  };

  public static chassis = (val: any) => {
    if (typeof val === "string") {
      // no minimum length is added since vin is considered as optional
      return !val || val.length <= config.inputTextLimit;
    }
    return false;
  };

  public static brand = (entity: Part | Vehicle, brands: SelectOption[]) => {
    if (entity.brand === -1) {
      const extra = parseExtraProps(entity.extraProps);
      if (extra.brand && extra.brand.length > 1) {
        return true;
      } else {
        return false;
      }
    } else {
      if (brands.findIndex(b => b.id === entity.brand) !== -1) {
        return true;
      } else {
        return false;
      }
    }
  };

  public static model = (entity: Part | Vehicle, models: SelectOption[]) => {
    if (entity.model === -1) {
      const extra = parseExtraProps(entity.extraProps);
      if (extra.model && extra.model.length > 1) {
        return true;
      } else {
        return false;
      }
    } else {
      if (models.findIndex(m => m.id === entity.model) !== -1) {
        return true;
      } else {
        return false;
      }
    }
  };

  public static gear = (val: any) => {
    const n = Number(val);
    if (!isNaN(n)) {
      return transmissionTypes.map((t) => t.id).includes(n);
    }
    return false;
  };

  public static fuelType = (val: any) => {
    const n = Number(val);
    if (!isNaN(n)) {
      return fuelTypeOptions.map((f) => f.id).includes(n);
    }
    return false;
  };

  public static area = (entity: Part | Vehicle) => {
    if(entity.province && entity.district ) {
      if(entity.province > 0 && entity.district > 0){
        if(entity.area > 0) {
          return true;
        } else {
          const newArea = parseExtraProps(entity.extraProps).area;
          return newArea?.length > 2;
        }
      }
      return false;
    }
    return false;
  };

  public static recordTypes = (categories: number[]) => {
    const validTypes = serviceTypes.filter(s => categories.includes(s.id));
    return validTypes.length === categories.length && categories.length > 0;
  };

  public static country = (val: any) => {
    if (typeof val === "string") {
      return countryList.map((c) => c.id).includes(val);
    }
    return false;
  };

  public static datePast = (val: any) => {
    const timestamp = Date.parse(val);
    if (!isNaN(timestamp)) {
      if (timestamp < new Date().getTime()) {
        return true;
      }
    }
    return false;
  };

  public static vehicleTypes = (vehicleTypes: any) => {
    let isValidVehicle = vehicleTypes.length > 0;

    for (const vehicleType of vehicleTypes) {
      if (!Validators.vehicleType(vehicleType)) {
        isValidVehicle = false;
        break;
      }
    };
    return isValidVehicle;
  }

  public static vehicleType = (val: any) => {
    const n = Number(val);
    if (!isNaN(n)) {
      return vehicleTypes.map((t) => t.id).includes(n);
    }
    return false;
  };

  public static validatePhone = (phoneNo: string, country: string) => {
    const result = phone(phoneNo, { country });
    return result.isValid;
  }

  public static year = (val: any) => {
    const n = Number(val);
    if (!isNaN(n)) {
      return n >= 1900 && n <= new Date().getFullYear();
    }
    return false;
  };

  public static fuelPrice = (val: any) => {
    const n = Number(val);
    if (!isNaN(n)) {
      return n > 0 && n <= 1000000;
    }
    return false;
  };

  public static validVehicleStep0 = (v: Vehicle, brands: SelectOption[], models: SelectOption[]) => {
    const customBrand = parseExtraProps(v.extraProps).brand;
    return Validators.vehicleType(v.type) &&
      Validators.brand(v, brands) &&
      (v.brand === -1 ? customBrand && customBrand.length > 1 : true) &&
      Validators.model(v, models) &&
      Validators.year(v.year);
  }

  public static validVehicleStep1 = (v: Vehicle) => {
    return Validators.gear(v.gear) &&
      Validators.cc(v.cc) &&
      Validators.odo(v.odo) &&
      Validators.fuelType(v.fuel)
  }

  public static validVehicleStep2 = (v: Vehicle) => {
    if (v.listing === ListingType.ListingForSale) {
      return Validators.numberPositiveOrZero(v.price) && Validators.validatePhone(v.phone, v.country);
    } else {
      return Validators.regNo(v.regNo) &&
        // Validators.chassis(v.chassis) && 
        Validators.datePast(v.date);
    }
  }

  public static validVehicleStep3 = (v: Vehicle) => {
    if (v.listing === ListingType.ListingForMyOwn) {
      return Validators.area(v);
      // return Validators.numberPositive(v.fuelPrice);
    } else {
      return Validators.area(v);
    }
  }


  public static validVehicle = (v: Vehicle, brands: SelectOption[], models: SelectOption[], step?: number): boolean => {
    if (step === 0) {
      return Validators.validVehicleStep0(v, brands, models);
    }
    if (step === 1) {
      return Validators.validVehicleStep1(v);
    }
    if (step === 2) {
      return Validators.validVehicleStep2(v);
    }
    if (step === 3) {
      return Validators.validVehicleStep3(v);
    }
    return Validators.validVehicleStep0(v, brands, models) &&
      Validators.validVehicleStep1(v) &&
      Validators.validVehicleStep2(v) &&
      Validators.validVehicleStep3(v)
  };

  public static validPart = (part: Part, brands: SelectOption[], models: SelectOption[]) => {
    return Validators.brand(part, brands) &&
      Validators.numberPositiveOrZero(part.price) &&
      Validators.model(part, models) &&
      Validators.vehicleTypes(part.vehicleTypes) &&
      Validators.title(part.title) &&
      Validators.validatePhone(part.phone, part.country) &&
      Validators.validPartCategory(part.categories) &&
      Validators.area(part);
  };

  public static validPartCategory = (categories: string[]) => {
    return categories.length > 0;
  }

  public static validRecord = (r: ServiceRecord) => {
    if (r.categories.includes(ServiceType.FuelTopUp)) {
      // if fuel Topup
      const cost = parseExtraProps(r.extraProps).cost;
      const vol = parseExtraProps(r.extraProps).volume;
      if (
        !isNaN(cost) && Number(cost) > 0 && !isNaN(vol) && Number(vol) > 0
      ) {
        // Do nothing
      } else {
        return false;
      }
    }
    return (
      Validators.datePast(r.date) &&
      Validators.recordTypes(r.categories) &&
      Validators.odo(r.odo) &&
      // Validators.description(r.desc) &&
      r.VehicleId > 0 &&
      r.UserId > 0
    );
  };

  public static validUser = (u: Omit<User, "id">, confirmPassword?: string) => {
    return Validators.userName(u.name) &&
      Validators.validatePhone(u.identifier, u.country) &&
      Validators.password(u.password) &&
      u.password === confirmPassword;
  };

  public static validLoginUser = (state: {
    identifier: string,
    password: string,
    country: string,
  }) => {
    return Validators.password(state.password) &&
      Validators.validatePhone(state.identifier, state.country)
  }
}
