import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { FC } from "react";
import { withScroller } from "../../../hoc/withScroller";
import { User } from "../../../types/User"
import { getFormattedDate } from "../../../util/helper";
import { LabelHelper } from "../../../util/labelHelper";

interface Props {

    hasMore: boolean;
    items: User[];
    loading: boolean;
    onLogin: (user: User) => void;
    onLoadMore: (nextLimit: number) => void;
}

const UsersAdminComp: FC<Props> = ({ items, onLogin }) => {
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Auth</TableCell>
                        <TableCell>Identifier</TableCell>
                        <TableCell>Country</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell>{row.id}</TableCell>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{LabelHelper.authProvider(row.authProvider)}</TableCell>
                            <TableCell>{row.identifier}</TableCell>
                            <TableCell>{row.country}</TableCell>
                            <TableCell>{getFormattedDate(row.createdAt)}</TableCell>
                            <TableCell sx={{cursor: 'pointer'}} onClick={() => onLogin(row)}>Login</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
export const UsersAdmin =  withScroller(UsersAdminComp);