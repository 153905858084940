import { FC, useState } from 'react';
import { Button, FormControl, IconButton, InputAdornment, OutlinedInput, Switch, TextField } from '@mui/material';
import { Clear, FilterAlt, Search, Tune } from '@mui/icons-material';

export const SearchBox: FC<{ placeHolder?: string, filterEnabled: boolean, value: string, onChange: (text: string) => void, onFilterOpen: () => void, onFilterEnable: (enabled: boolean) => void}> =
({ placeHolder, value, filterEnabled, onChange, onFilterOpen, onFilterEnable }) => {

  const [val, setValue] = useState(value);

  const handleChange = (value: string) => {
    setValue(value);
  }

  const handleKeyDown = (e: any) => {

    if (e.key === 'Enter') {
      onChange(e.target.value);
    }
  }

  const onClear = () => {
    setValue('');
    onChange('');
  }

  return (
    <FormControl variant="outlined">
      <OutlinedInput
        id="outlined-adornment-password"
        size='small'
        value={val}
        onChange={(e) => handleChange(e.target.value)}
        onBlur={(e) => onChange(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder={placeHolder ?? "Search"}
        endAdornment={
          <InputAdornment position="end">
            { value !== '' && <IconButton
              aria-label="toggle password visibility"
              onClick={onClear}
              onMouseDown={onClear}
              edge="end"
            >
              <Clear />
            </IconButton>}
            <Button disabled={!filterEnabled} onClick={onFilterOpen} variant="text" startIcon={<Tune />}>
              Filters
            </Button>
            <Switch checked={filterEnabled} onChange={() => onFilterEnable(!filterEnabled)} name="gilad" />
          </InputAdornment>
        }
      />
    </FormControl>
  );
}