export const homeIntroSteps = [
  {
    selector: ".speed-dial",
    content: "Add vehicles or Add maintance records",
  },
  {
    selector: ".search-box",
    content: "Search other vehicles by license plate or chassis number",
  },
  {
    selector: ".avatar",
    content: "Manage your account",
  },
];

export const recordViewIntroSteps = [
  {
    selector: ".chart-icon",
    content: "Show millage as a chart",
  },
  {
    selector: ".merge-icon",
    content: "Show all records from previous owners",
  },
  {
    selector: ".filter-icon",
    content: "Filter records by type",
  },
  {
    selector: ".timeline-icon",
    content: "Timeline view",
  },
  {
    selector: ".grid-icon",
    content: "Grid view",
  },
];
