import { INode } from "./INode";

export class Node {

    private readonly treeObj: INode;
    public level = 0;

    // static variables
    public label: string;
    public value: string;
    public parent?: Node;

    // dynamic variables
    public isSelected?: boolean;
    public isExpanded?: boolean;
    public isHidden?: boolean;
    public children?: Node[];

    // constructor(id: string, parentId: string, name: string, count: number, children: Record<string, Node>) {

    constructor(tree: INode, parent?: Node) {
        this.treeObj = tree;
        this.label = tree.label;
        this.value = tree.value;
        this.isSelected = tree.isSelected;
        this.isExpanded = tree.isExpanded;
        this.isHidden = tree.isHidden;
        this.parent = parent;
        this.level = parent ? parent.level + 1 : 0

        this.children = [];

        if (tree.children && tree.children.length > 0) {
            tree.children.forEach(c => {
                const child = new Node(c, this);
                if (!this.children) {
                    this.children = [];
                }
                this.children.push(child);
            })
        }
    }

    public getTreeObject(): INode {
        return this.treeObj;
    }

    public selectPathToRoot(): void {
        // console.log('selectPathToRoot ', this.label, this)
        this.isSelected = true;
        this.parent?.selectPathToRoot();
    }

    public resetChildren(): Node {
        // console.log('reset ', this.label)
        this.isSelected = false;
        this.children?.forEach(c => c.resetChildren());
        return this;
    }

    public getSelectedIds(): string[] {
        let arr = this.isSelected ? [this.value] : [];
        if (this.children) {
            this.children.forEach(c => {
                arr = [...arr, ...c.getSelectedIds()]
            })
        }
        return arr;
    }

    public getSelectedLabels(): string[] {
        let arr = this.isSelected ? [this.label] : [];
        if (this.children) {
            this.children.forEach(c => {
                arr = [...arr, ...c.getSelectedIds()]
            })
        }
        return arr;
    }

    public setSelectedStatusOfThisAndAllChildren(isSelected: boolean): void {
        this.isSelected = isSelected;
        this.children?.forEach((child) => {
            child.setSelectedStatusOfThisAndAllChildren(isSelected);
        });
    }

    public isAllChildrenSelected(): boolean {
        return this.children?.find((child) => !child.isAllChildrenSelectedIncludingItself()) === undefined;
    }

    public isAllChildrenSelectedIncludingItself(): boolean {
        return !!(this.isSelected && this.isAllChildrenSelected());
    }

    public convertToINode(): INode {
        this.treeObj.isSelected = this.isSelected;
        this.treeObj.isExpanded = this.isExpanded;
        this.treeObj.isHidden = this.isHidden;
        this.treeObj.children = [];

        this.children?.forEach(c => {
            this.treeObj.children?.push(c.convertToINode());
        });

        return this.treeObj;
    }
}
