import { useNavigate, useSearchParams } from "react-router-dom";
import { SearchParams } from "../enum/searchParams.enum";

export const useSearchParamsState = <T,>(key: SearchParams, defaultVal: T) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  
  const searchParamValue: T = searchParams.get(key) as T ?? defaultVal;

  const changeSearchParam = (val: string, searchParamsProvided?: any) => {
    const sParams = searchParamsProvided ?? searchParams;
    sParams.set(key, val);
    navigate({
      pathname: location.pathname,
      search: sParams.toString(),
    });
    return sParams;
  };

  return {searchParamValue, changeSearchParam};
};
