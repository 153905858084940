import { Autocomplete, Fab, TextField } from "@mui/material";
import { FC, FormEventHandler, useState } from "react";
import { config } from "../../config";
import { Add } from "@mui/icons-material";

interface Props {
  name: string;
  value: number | string | undefined;
  error?: boolean;
  label: string;
  disabled: boolean;
  options: Array<{ id: number | string; label: string }>;

  onInput?: (e: any) => void;
  onBlur?: (e: any) => void;
  onChange: (key: string, value: number | string) => void;
}
export const AutoInput: FC<Props> = ({
  name,
  value,
  error,
  label,
  options,
  disabled,

  onInput = () => {},
  onBlur = () => {},
  onChange,
}) => {


  // if (options.length === 0) {
  //   return <></>;
  // }
  const selectedValue = options.find((ele) => ele.id === value);

  return (
    <>
      <Autocomplete
        // noOptionsText="lkjlkjfsd"
        onInput={onInput}
        disabled={disabled}
        onBlur={onBlur}
        disablePortal
        // sx={{ marginTop: 4, height: config.inputHeight, minWidth: config.inputMinWidth}}
        value={selectedValue}
        options={options}
        fullWidth
        renderInput={(params) => (
          <TextField
            error={error}
            {...params}
            label={label}
            variant="standard"
            fullWidth
          />
        )}
        onChange={(_e, value) => (value != null) && onChange(name, value.id)}
      />
    </>
  );
};
