
import { FC, ReactNode } from "react";
import { LoginContainer } from "./components/user/login.container";
import { useUser } from "./hooks/useUser";

export const ProtectedRoute: FC<{ children: ReactNode }> = (props) => {
    const { user } = useUser();
    
    if (!user) {
        return <LoginContainer />
    }
    return <>{props.children}</>
}