import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { FC } from "react";
import { withScroller } from "../../../hoc/withScroller";
import { Vehicle } from "../../../types/Vehicle";
import { CustomButton } from "../../shared/CustomButton";
import { DeleteForever, Visibility } from '@mui/icons-material';
import { useBrands } from "../../../hooks/useBrands";
import { useModels } from "../../../hooks/useModels";
import { LabelHelper } from "../../../util/labelHelper";
import { ListingType } from "../../../enum/vehicleListing.enum";

interface Props {
    hasMore: boolean;
    items: Vehicle[];
    loading: boolean;
    onDelete: (v: Vehicle) => void;
    onLoadMore: (nextLimit: number) => void;
    onSelect: (v: Vehicle) => void;
}

const VehiclesAdminComp: FC<Props> = ({ items, onDelete, onSelect }) => {

    const brands = useBrands();
    const models = useModels();

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Reg No</TableCell>
                        <TableCell>Manufacture Year</TableCell>
                        <TableCell>Brand</TableCell>
                        <TableCell>Model</TableCell>
                        <TableCell>Chassis</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Fuel</TableCell>
                        <TableCell>CC</TableCell>
                        <TableCell>Transmission</TableCell>
                        <TableCell>Image Count</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell>{row.id}</TableCell>
                            <TableCell>{row.listing === ListingType.ListingForMyOwn ? row.regNo : row.price }</TableCell>
                            <TableCell>{row.year}</TableCell>
                            <TableCell>{LabelHelper.getBrandLabelEntity(row, brands)}</TableCell>
                            <TableCell>{LabelHelper.getModelLabelEntity(row, models)}</TableCell>
                            <TableCell>{row.chassis}</TableCell>
                            <TableCell>{LabelHelper.getVehicleTypeLabel(row.type)}</TableCell>
                            <TableCell>{LabelHelper.getFuelTypeLabel(row.fuel)}</TableCell>
                            <TableCell>{row.cc}</TableCell>
                            <TableCell>{LabelHelper.getTransmissionTypeLabel(row.gear)}</TableCell>
                            <TableCell>{row.images}</TableCell>
                            <TableCell>
                                <CustomButton EndIcon={Visibility} size="small" label="View" onClick={() => onSelect(row)} />
                                <CustomButton EndIcon={DeleteForever} size="small" label="Delete" onClick={() => onDelete(row)} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
export const VehiclesAdmin = withScroller(VehiclesAdminComp);