import { useState } from "react";
import { parseExtraProps } from "../util/helper";

export const useFormState = <T,>(obj: T) => {
  const [state, setState] = useState(obj);

  const changeProperty = (prop: string, value: string | string[] | number | boolean | number[] | undefined): T => {
    const next = { ...state, [prop]: value };
    setState(next);
    return next;
  };

  const changeBulk = (obj: any): T => {
    const next = { ...state, ...obj };
    setState(next);
    console.log('bulk', obj)
    return next;
  };

  const changeExtraProperty = (prop: string, value: any) => {
    const extraPropsObject = parseExtraProps((state as any).extraProps);
    if (value === null || value === undefined) {
      // eslint-disable-next-line
      delete extraPropsObject[prop];
    } else {
      extraPropsObject[prop] = value;
    }
    setState((prev: any) => ({
      ...prev,
      extraProps: JSON.stringify(extraPropsObject),
    }));
  };


  const changeBulkExtraProperty = (obj: any) => {
    const extraPropsObject = parseExtraProps((state as any).extraProps);
    const nextExtraProps = { ...extraPropsObject, ...obj };
    setState((prev: any) => ({
      ...prev,
      extraProps: JSON.stringify(nextExtraProps),
    }));
  };

  const changeWithExtraProperty = (key: string, value: string | number, fromSuggestions?: boolean) => {
    // Do not change the order below
    if(fromSuggestions) {
      changeProperty(key, value);
      changeExtraProperty(key, undefined);
    } else {
      changeProperty(key, -1);
      changeExtraProperty(key, value);
    }
  }

  // const ret: [typeof state, typeof changeProperty, typeof changeExtraProperty, typeof changeBulk, typeof changeBulkExtraProperty, typeof changeWithExtraProperty] =
  //   [state, changeProperty, changeExtraProperty, changeBulk, changeBulkExtraProperty, changeWithExtraProperty];

    return {
      state, changeProperty, changeExtraProperty, changeBulk, changeBulkExtraProperty, changeWithExtraProperty
    }
  // return ret;
};
