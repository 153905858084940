import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FC } from "react";
import { Validators } from "../../../util/validators";
import { CustomButton } from "../../shared/CustomButton";
import { useFormState } from "../../../hooks/useFormState";
import { TextInput } from "../../shared";
import { VpnKey } from "@mui/icons-material";
import { PhoneInput } from "../../shared/PhoneInput";

interface Props {
  loading: boolean;
  identifier: string;
  country: string;
  onChangePassword: (args: {
    identifier: string;
    password: string;
  }) => void;
}
export const ChangePassword: FC<Props> = ({
  loading,
  onChangePassword,
  country,
  identifier,
}) => {
  const {state, changeProperty} = useFormState({
    identifier,
    password: "",
  });

  const handleChangePW = () => {
    onChangePassword(state);
  };

  const isReadyToSubmit = () => {
    if (
      Validators.text(state.identifier) &&
      Validators.text(state.password)
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Typography  variant="h5">
        Enter new password
      </Typography>

      <Box component="form" noValidate sx={{ mt: 1 }}>
        <PhoneInput
            country={country}
            value={state.identifier}
            name="identifier"
            label="Phone Number"
            error={!Validators.validatePhone(state.identifier, country)}
            onChange={changeProperty}
            disabled
          />

        {/* <TextInput
          value={state.identifier}
          name="identifier"
          error={!Validators.identifier(state.identifier)}
          label="Email / Phone"
          onChange={changeProperty}
          disabled={true}
        /> */}

        <TextInput
          value={state.password}
          name="password"
          label="New Password"
          onChange={changeProperty}
          error={!Validators.text(state.password)}
          disabled={loading}
          onEnter={handleChangePW}
        />

        <CustomButton
          EndIcon={VpnKey}
          label="Save Password"
          loading={loading}
          onClick={handleChangePW}
          disabled={loading || !isReadyToSubmit()}
        />
      </Box>
    </>
  );
};
