import { Dialog, DialogContent, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PopupType } from "../../../enum/popup.type";
import { useUpdateUserMutation } from "../../../state/api/user.api";
import { showPopup } from "../../../state/slice/appSlice";
import { useAppDispatch } from "../../../state/store";
import { User } from "../../../types/User";
import { ErrorComponent } from "../../error/error";
import { UserUpdate } from "./userUpdate";
import { useUser } from "../../../hooks/useUser";
import { FC, useState } from "react";

export const UserUpdateContainer: FC<{
  onlyCountrySelectorPopup?: boolean;
}> = ({ onlyCountrySelectorPopup = false }) => {
  const { user } = useUser();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [updateUser, { isLoading }] = useUpdateUserMutation();
  const dispatch = useAppDispatch();

  const handleSave = async (user: Partial<User>, imgs: Record<number, Blob | null>) => {
    const result: any = await updateUser({ user, imgs });
    if (result.error) {
      console.log(result.error);
    } else {
      dispatch(
        showPopup({
          type: PopupType.info,
          message: "User changed successfully",
        })
      );
      if (!onlyCountrySelectorPopup) {
        navigate(`/`);
      }
    }
  };

  const handleClose = () => {
    setOpen(false)
    if (!onlyCountrySelectorPopup) {
      navigate(`/`);
    }
  };

  if (!user?.id) {
    return <ErrorComponent title="Error" text="User N/A" />;
  }

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': {} }}
      maxWidth="md"

      open={open}
    >
      <DialogContent dividers>
        <Grid container spacing={2} rowSpacing={4} marginTop={3}>
          <UserUpdate
            loading={isLoading}
            onSave={handleSave}
            onClose={handleClose}
            initialState={user}
            onlyCountrySelectorPopup={onlyCountrySelectorPopup}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  )
};
