import { countryList } from "./countries";

export const getountryCodeFromId = (id: string) => {
  const countryObj = countryList.find((c) => c.id === id);
  return countryObj ? countryObj.code : '-';
};

export const getountryLabelFromId = (id: string) => {
  const countryObj = countryList.find((c) => c.id === id);
  return countryObj ? countryObj.label : 'Invalid country';
};

export const countryListWithCodes = () => {
  return countryList.map(c => {
    const next = { ...c };
    next.label = `${c.label}(${c.code})`;
    return next;
  })
}

export const areasForCountry = () => {
  return countryList.map(c => {
    const next = { ...c };
    next.label = `${c.label}(${c.code})`;
    return next;
  })
}