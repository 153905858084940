import BreadcrumbNodes from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { FC } from "react";
import { BreadcrumbNode } from "../../types/BreadcrumbNode";

interface Props {
  onSelectNode: (node: BreadcrumbNode) => void;
  nodes: BreadcrumbNode[];
}

export const Breadcrumbs: FC<Props> = ({ onSelectNode, nodes }) => {
  return (
    <div role="presentation">
      <BreadcrumbNodes
        aria-label="breadcrumb"
        sx={{ marginTop: 1, marginLeft: 1, cursor: 'pointer' }}
      >
        {nodes.map((node) => (
          <Link
            key={node.path}
            underline="hover"
            color="inherit"
            onClick={() => onSelectNode(node)}
          >
            {node.label}
          </Link>
        ))}
      </BreadcrumbNodes>
    </div>
  );
};
