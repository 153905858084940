import { SelectOption } from "../types/SelectOption";

export enum ListingType {
    // XY : x: isMaintaing, y: other purpose
    ListingForMyOwn = 1,
    ListingForSale = 2,
    Rent = 3
}
export const vehicleListing: SelectOption[] = [
    { label: "Maintain Records", id: ListingType.ListingForMyOwn },
    { label: "Sale Advertisement", id: ListingType.ListingForSale },
];