import { FC, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { LoaderLinear } from "../components/loader/loaderLinear.component";
import { config } from "../config";

interface Props {
  hasMore: boolean;
  items: any[];
  loading: boolean;
  onLoadMore: (offset: number) => void;
}
export const withScroller = <T extends Props>(Component: FC<T>) => {
  return (props: T) => {
    const { hasMore } = props;
    const [offset, setOffset] = useState(0);

    const loadFunc = () => {
      if (!props.loading) {
        props.onLoadMore(offset);
        setOffset(offset + config.pageSize);
      }
    };

    return (
      <InfiniteScroll
        pageStart={0}
        loadMore={loadFunc}
        hasMore={hasMore}
        // key is mandatory here to fix an issue of InfiniteScroll
        loader={<LoaderLinear key={0} />}
      >
        <Component {...{ ...props }} />
      </InfiniteScroll>
    );
  };
};
