import { useNavigate } from "react-router-dom";
import { useUser } from "../../../hooks/useUser";
import { useCreatePartMutation } from "../../../state/api/parts.api";
import { Part } from "../../../types/Part";
import { PartCreate } from "./partCreate"
import { PageHeader } from "../../shared/PageHeader";

export const PartCreateContainer = () => {
    const { user } = useUser();
    const [createPart, { isLoading }] = useCreatePartMutation();
    const navigate = useNavigate();

    const handleOnSave = async (part: Part, imgs: Record<number, Blob | null>) => {
        const result: any = await createPart({ part, imgs });
        if (result.data) {
            navigate(`/sparepart/market`);
        } else {
            console.log('Part create failed');
        }
    }

    if (!user) {
        return <div>Invalid User</div>
    }

    return <>
        <PageHeader header={'Add Parts For Sale'} subHeader={''} />
        <PartCreate initialState={{}} loading={isLoading} user={user} onSave={handleOnSave} />
    </>
}

