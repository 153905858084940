import { FC } from "react";
import { EntityEditor } from "./entityEditor";
import { Button, Grid } from "@mui/material";
import { useUser } from "../../../hooks/useUser";
import { useFormState } from "../../../hooks/useFormState";
import { InputGrid } from "../../shared/InputGrid";
import { AutoInput, TextInput } from "../../shared";
import { useCreateAreaMutation, useReadAreasQuery, useUpdateAreaMutation } from "../../../state/api/area.api";
import { Area } from "../../../types/Area";
import { useAreas } from "../../../hooks/useArea";
import { countryList } from "../../../util/countries";
import { withNotSelectedOption } from "../../../util/helper";

export const AreasContainer: FC = () => {
    const { user } = useUser();
    const { data } = useReadAreasQuery({});

    const { allAreas, getCountry, getProvince, getAreas } = useAreas();

    const {state, changeProperty} = useFormState<Omit<Area, 'id'>>({
        country: user.country,
        province: -1,
        district: -1,
        label: '',
        UserId: user.id
    });

    const [createArea, { isLoading: isLoadingCreate }] = useCreateAreaMutation();
    const [updateArea, { isLoading: isLoadingUpdate }] = useUpdateAreaMutation();

    const handleUpdate = (obj: any) => {
        updateArea(obj)
    }

    const handleAddNew = () => {
        createArea(state);
    }

    return <table style={{ width: '100%' }}>
        <tbody>
            <tr>
                <td>
                    <Grid container>
                        <InputGrid minWidth={4}>
                            <AutoInput
                                name={'country'}
                                value={state.country}
                                label={'Select Country'}
                                disabled={false}
                                options={countryList}
                                onChange={changeProperty}
                            />
                        </InputGrid>
                        <InputGrid minWidth={4}>
                            <AutoInput
                                name={'province'}
                                value={state.province}
                                label={'Select Province'}
                                disabled={false}
                                options={withNotSelectedOption(getCountry(state)?.provinceList ?? [])}
                                onChange={changeProperty}
                            />
                        </InputGrid>
                        <InputGrid minWidth={4}>
                            <AutoInput
                                name={'district'}
                                value={state.district}
                                label={'Select District'}
                                disabled={false}
                                options={withNotSelectedOption(getProvince(state)?.districts ?? [])}
                                onChange={changeProperty}
                            />
                        </InputGrid>
                        <InputGrid minWidth={4}>
                            <TextInput
                                suggestions={{
                                    initDispVal: ``,
                                    options: withNotSelectedOption(getAreas(state) ?? [])
                                }}
                                value={state.label}
                                name="label"
                                label="Enter Area"
                                isRequired
                                onChange={changeProperty}
                            />
                        </InputGrid>
                        <InputGrid minWidth={4}>
                            <Button disabled={!isNaN(Number(state.label))} onClick={handleAddNew}>Add Area</Button>
                        </InputGrid>
                    </Grid >
                </td>
            </tr>
            {
                allAreas?.map(a => <tr key={a.id}>
                    <EntityEditor
                        editableProps={['label']}
                        nonEditableProps={['country', 'province', 'district']}
                        entity={a.meta}
                        onSave={handleUpdate}
                    ></EntityEditor>
                </tr>)
            }
        </tbody>
    </table>;
}