import { SelectOption } from "../types/SelectOption";
import {
  DonutSmall,
  LocalGasStation,
  SettingsApplications,
  Air,
  AllInclusive,
  Dangerous,
  Pentagon,
  Grading,
  Cable,
  LensBlur,
  Settings,
  EventSeat,
  Handyman}
 from "@mui/icons-material";

export enum ServiceType {
  FuelTopUp = 100
}

export const serviceTypes: SelectOption[] = [
  { label: "Fuel Topup", shortLabel: "Fuel", id: ServiceType.FuelTopUp, Icon: <LocalGasStation />, color: "success" }, // this id is used in RecordCreate component, validator

  { label: "Engine / Motor / Battery", shortLabel: "Engi. Bat", id: 150, Icon: <Handyman />, color: "primary"},
  { label: "Gearbox / Transmission / Drive Train", shortLabel: "Gear.", id: 200, Icon: <Settings />, color: "error" },
  { label: "Suspension / Steering", shortLabel: "Steer", id: 250, Icon: <AllInclusive />, color: "secondary" },

  { label: "Break System", shortLabel: "Break", id: 300, Icon: <Dangerous />, color: "primary" },
  { label: "Electrical / Lighting", shortLabel: "Elec.", id: 350, Icon: <Cable />, color: "success" },

  { label: "Tyres / Wheels", shortLabel: "Wheels", id: 400, Icon: <DonutSmall />, color: "error" },

  { label: "Air Conditioning / Heating", shortLabel: "Air", id: 450, Icon: <Air />, color: "success" },

  { label: "Frame / Body", shortLabel: "Body", id: 500, Icon: <Pentagon />, color: "primary" },
  { label: "Entertainment system", shortLabel: "Entertainment", id: 550, Icon: <EventSeat />, color: "primary" },
  { label: "Interior", shortLabel: "Interior", id: 600, Icon: <EventSeat />, color: "primary" },

  { label: "License / Insurance", shortLabel: "Reg", id: 650, Icon: <Grading />, color: "primary" },
  { label: "Other", shortLabel: "Other", id: 999, Icon: <LensBlur />, color: "success" }, 
];
