import { Chip } from "@mui/material";
import { FC } from "react";
import { Vehicle } from "../../types/Vehicle";
import { Share } from '@mui/icons-material';
import { copyToClipboard } from "../../util/helper";
import { Part } from "../../types/Part";
import { PopupManager } from "../../util/popupManager";
interface Props {
    item: Vehicle | Part
}
export const GridItemShare: FC<Props> = ({ item }) => {

    // @ts-expect-error
    const entity = item.vehicleTypes === undefined ? 'vehicle' : 'sparepart';

    const onShare = () => {
        const link = `${window.location.origin}/${entity}/${item.id}`;
        void copyToClipboard(link);
        PopupManager.showSuccessToast(`Link copied! ${link}`);
    }

    return <div onClick={(e) => { onShare(); e.stopPropagation() }}><Chip onDelete={onShare} color="secondary" label="Share" sx={{ cursor: 'pointer' }} deleteIcon={<Share />} /></div>
}