import { withScroller } from "../../../hoc/withScroller";
import { FC } from "react";
import { Vehicle } from "../../../types/Vehicle";
import { Grid } from "@mui/material";
import { GridItemAdd } from "../../shared/addNew/gridItemAdd";
import { VehicleGridCard } from "../view/vehicleGridCard";
import { AddCircle } from "@mui/icons-material";
import { VehicleGridTable } from "./vehicleGridTable";
import { ItemViewMode } from "../../../enum/itemViewMode.enum";
import { SelectOption } from "../../../types/SelectOption";

export interface Props {
  viewMode: ItemViewMode;
  onAdd?: () => void;
  onSelect: (vid: number) => void;
  models: SelectOption[];
  // for pagination
  hasMore: boolean;
  loading: boolean;
  items: Vehicle[];
  buttonSubText?: string;
  onLoadMore: (nextLimit: number) => void;
}

const VehicleGridComp: FC<Props> = ({ items, models, buttonSubText, viewMode, onAdd, onSelect }) => {

  return (
    <Grid container spacing={1}>
      {onAdd && <GridItemAdd Icon={AddCircle} text="Add Vehicle" subText={buttonSubText ?? ''} onAdd={onAdd} />}
      {items.map((v: Vehicle) => (
        viewMode === ItemViewMode.grid ?
          <VehicleGridCard
            models={models}
            vehicle={v} key={`${v.id}${v.createdAt.toString()}`}
            onSelect={() => onSelect(v.id)} />
          :
          <VehicleGridTable
            vehicle={v} key={`${v.id}${v.createdAt.toString()}`}
            onSelect={() => onSelect(v.id)} />
      ))}
    </Grid>
  );
};

export const VehicleGridView = withScroller(VehicleGridComp);
