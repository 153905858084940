import { FC, useEffect } from "react";
import { INode } from "../../types/INode";
import { Node } from "../../types/Node";
import { LeafNode } from "./leaf/LeafNode";
import { Grid } from "@mui/material";
import { useForcedRender } from "../../hooks/useForcedRender";

interface TreeSelectProps {
    error?: boolean;
    allExpanded?: boolean;
    disabled?: boolean;
    userNodeArr: INode[];
    isSingleSelection: boolean;
    disableAutoSelectChildren?: boolean;
    setNodesArr?: (nodeArr: INode[]) => void;
    onClick?: () => void;
    onSelectionChange?: (tree: Node[], selectedValues: string[]) => void;
}

let nodeObjectsArr: Node[] = [];

export const TreeSelect: FC<TreeSelectProps> = ({ error, allExpanded, userNodeArr, disabled = false, isSingleSelection, disableAutoSelectChildren = false, onSelectionChange, onClick, setNodesArr }) => {

    const reRender = useForcedRender();

    useEffect(() => {
        nodeObjectsArr = userNodeArr.map(n => new Node(n));
        reRender();
    }, [userNodeArr]);

    const handleNodeChanged = (clickedNode: Node, idx: number) => {

        if (onSelectionChange) {
            if (isSingleSelection) {
                const sArr = clickedNode.isSelected ? [clickedNode.value] : [''];
                onSelectionChange(nodeObjectsArr, sArr);
                console.log('selected Nodes', sArr);
            } else {
                let sArr: string[] = [];
                nodeObjectsArr.forEach(n => {
                    sArr = [...sArr, ...n.getSelectedIds()];
                });
                onSelectionChange(nodeObjectsArr, sArr);
                console.log('selected Nodes', sArr);
            }
           
        }
        setNodesArr?.(nodeObjectsArr);
      
    }

    const handlePopReset = () => {
        for (const node of nodeObjectsArr) {
            node.resetChildren();
        }
        reRender();
    }

    const styles = error ? {border: 'solid red 1px', borderRadius: '3px', padding: '10px'} : {};

    return <Grid sx={styles} container marginBottom={1} onClick={onClick}>
        {nodeObjectsArr.map((n, i) => <Grid item xs={12} sm={6} md={4} key={n.value}><LeafNode allExpanded={allExpanded} idx={i} node={n} disabled={disabled} disableAutoSelectChildren={disableAutoSelectChildren} isSingleSelection={isSingleSelection} onPopReset={handlePopReset} onChildrenSelectionChange={handleNodeChanged} />
        </Grid>)}
    </Grid>;
}