import { DEFAULT_SELECTED_MANUFACTURER } from "../selectOptions/manufacturers";
import { Part } from "../types/Part";
import { User } from "../types/User";

export const defaultPart = (user: User): Part => ({
    id: 0,

    title: '',
    model: -1,

    brand: DEFAULT_SELECTED_MANUFACTURER,
    yearMin: undefined,
    yearMax: undefined,

    vehicleTypes: [],
    extraProps: `{}`,

    keywords: [],

    categories: [],
    chassis: '',

    desc: '',
    images: '{}',

    // Area info
    lat: -1,
    lng: -1,
    country: user.country,
    province: -1,
    district: -1,
    area: -1,
    //

    price: undefined,
 
    phone: user.descPhone ?? user.phone ?? '',
    createdAt: new Date(),
    updatedAt: new Date(),
    UserId: user.id

  
});