import LoadingButton from '@mui/lab/LoadingButton';
import { FC } from "react";
import { MaterialColor } from '../../types/MaterialColor';

interface Props {
  EndIcon: FC
  float?: string;
  align?: "center" 
  label: string;
  size?: "small" | "large" | "medium";
  color?: MaterialColor;
  disabled?: boolean;
  loading?: boolean;
  margin?: number;
  fitToContent?: boolean;
  variant?: "text" | "contained" | "outlined";
  onClick: () => void;
}
export const CustomButton: FC<Props> = ({ size, label, onClick, disabled, fitToContent, loading, color, variant, EndIcon, float, margin }) => {
  return (
    <LoadingButton
      fullWidth = {!fitToContent }
      variant={variant ?? "contained"}
      size={size ?? "medium"}
      color={color ?? 'primary'}
      endIcon={<EndIcon />}
      sx={{
        // mr: 2,
        margin: margin ?? 1,
        float: float ?? 'center',
        maxWidth: '250px'
      }}
      onClick={onClick}
      disabled={disabled || loading} // eslint-disable-line
      loading={loading}
    >
      {label}
    </LoadingButton>
  );
};
