import { FC } from 'react';
import MinusIcon from '../icons/minus.svg';
import PlusIcon from '../icons/plus.svg';
import { Node } from '../../../types/Node';
import { Checkbox, Stack } from '@mui/material';

interface Props {
    node: Node;
    disabled: boolean;
    allExpanded?: boolean;
    onLabelClick: () => void;
    onSelectionChange: (clickedNode: Node) => void;
}
export const Select: FC<Props> = ({
    node,
    allExpanded,
    disabled,
    onLabelClick,
    onSelectionChange,
}: Props) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        node.isSelected = event.target.checked;
        onSelectionChange(node);
    };

    const handleLabelClick = () => {
        if (!disabled) {
            if (node.children && node.children.length > 0) {
                onLabelClick();
            } else {
                // onSelectionChange(!node?.isSelected);
            }
        }
    }

    return (
        <Stack direction="row" style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            <Checkbox sx={{height: '10px'}} disabled={disabled} checked={!!node?.isSelected} onChange={handleChange} />
            <Stack direction="row" onClick={handleLabelClick} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                {node.label}
                {node.children && node.children.length > 0 && (
                    <>{(node.isExpanded ?? allExpanded) ? <img style={{ marginLeft: '5px' }} width={12} src={MinusIcon} /> : <img style={{ marginLeft: '5px' }} width={12} src={PlusIcon} />}</>
                )}
            </Stack>
        </Stack>
    );
};


